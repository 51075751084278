import React from "react";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import styled from "styled-components";
import ConfirmModal from "../modal/ConfirmModal";
import LoadingModal from "../modal/LoadingModal";
import ApprovalModal from "../modal/ApprovalModal";
import CommentModal from "../modal/CommentModal";
import NilaiServices from "../../services/NilaiServices";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { UrlImg } from "../../api/apiFile";
import jwt from "jwt-decode";
import { Decrypt } from "../utils/UrlCrypt";

const GroupCol = styled.div`
  display: table;
  width: 100%;
`;

const Col = styled.div`
  display: table-cell;
`;

const FormGroupCol = styled.div`
  margin: 5px;
  display: flex;
  label {
    font-size: var(--font-size-text);
    font-weight: bold;
  }
  p {
    font-size: var(--font-size-text);
    color: black;
    margin-left: 5px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-betwen;
`;

const Card = styled.div`
  flex: 1;
  align-self: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--color-border);
  margin: 5px;
  h5 {
    background-color: var(--color-primary);
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 5px;
    font-size: var(--font-size-text);
  }
  p {
    width: 100%;
    color: black;
    padding: 5px;
    font-size: var(--font-size-text);
  }
  div {
    width: 100%;
    border-top: 1px solid var(--color-border);
    margin-top: auto;
    text-align: center;
    padding-top: 5px;
  }
  img {
    max-height: 100px;
    max-width: 150px;
  }
  // button {
  //   padding: 2px 15px;
  //   margin: 0px 5px;
  //   border-radius: 5px;
  //   outline: none;
  //   border: none;
  //   font-size: var(--font-size-text);
  //   cursor: pointer;
  //   background-color: #ffdb58;
  // }
`;

const CardStandar = styled.div`
  flex: 1;
  align-self: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--color-border);
  margin: 5px;
  h5 {
    background-color: var(--color-primary);
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 5px;
    font-size: var(--font-size-text);
  }
  p {
    width: 100%;
    color: black;
    padding: 5px;
    font-size: var(--font-size-text);
  }
  img {
    max-height: 100px;
    max-width: 150px;
  }
  button {
    padding: 2px 15px;
    margin: 0px 5px;
    border-radius: 5px;
    outline: none;
    border: none;
    font-size: var(--font-size-text);
    cursor: pointer;
    background-color: #ffdb58;
  }
`;

const GridContainer = styled.div`
  display: grid;
  // grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto;
  grid-template-columns: auto;
  gap: 1px;
  overflow-x: auto;
  border: 1px solid black;
  margin: 5px;
  background-color: black;
  div {
    padding: 2px;
    display: flex;
    align-items: center;
    text-align: center;
  }
`;

const ColKriteria = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  background-color: #eec4c9;
  color: black;
  font-size: var(--font-size-text);
  padding: 5px;
  justify-content: center;
  font-weight: bold;
`;

const ColHeader = styled.div`
  display: flex;
  background-color: #6fa8dc;
  color: black;
  font-size: var(--font-size-text);
  padding: 3px;
  justify-content: center;
  align-items: center;
  // font-weight: bold;
  text-align: center;
`;

const RowNilai = styled.div`
  background-color: #ffffe0;
  color: black;
  font-size: var(--font-size-text);
  padding: 3px;
  justify-content: center;
  font-weight: bold;
`;

const GroupInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #6fa8dc;
  i {
    color: red;
    font-size: 11px;
  }
`;

const Select = styled.select`
  display: inline;
  width: 90%;
  min-width: 70px;
  height: 80%;
  padding: 2px 5px;
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  font-size: 1rem;
  transition: 0.3s;
  outline: none;
  text-align: center;
  font-weight: bold;
  margin: 0px 5px;
`;

const Input = styled.textarea`
  display: inline;
  width: 90%;
  height: 90%;
  padding: 2px 5px;
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  font-size: 0.7rem;
  transition: 0.3s;
  outline: none;
`;

const ColStandard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  font-size: 0.7rem;
`;

const GridSumNilai = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto;
  gap: 1px;
  overflow-x: auto;
  border: 1px solid black;
  margin: 5px;
  background-color: black;
  div {
    font-size: var(--font-size-text);
    padding: 2px 5px;
    display: flex;
    align-items: center;
    text-align: center;
  }
`;

const CursorImg = styled.img`
  cursor: pointer;
`;

const Back = styled(FaArrowLeft)`
  color: #0066cc;
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
`;

const defaultImg = require("../../assets/addimage.png");
const defaultDoc = require("../../assets/adddoc.png");

const CheckIdea = () => {
  const token = localStorage.getItem("token");
  const user = jwt(token);
  const username = user.username;
  const level = parseInt(user.level);
  const dept = user.dept;
  const listNilai = [0, 1, 3, 5, 10];

  const { id } = useParams();
  const [approve, setApprove] = useState("");
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("");
  const [idea, setIdea] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [errors, setErrors] = useState(null);
  const [confMsg, setConfMsg] = useState("");
  const [response, setResponse] = useState(null);

  const toast = useRef(null);
  const toastval = useRef(null);
  const navigate = useNavigate();

  const initialValues = {
    idnilai: 0,
    nilaisaving: "",
    nilaitime: "",
    nilaikreativ: "",
    nilaiasal: "",
    nilai5s: "",
    nilaistandar: "",
    nilaipokayoke: "",
    nilaikualitas: "",
    nilaisafety: "",
    nilailingkungan: "",
    ketsaving: "",
    kettime: "",
    ketkreativ: "",
    ketasal: "",
    ket5s: "",
    ketstandar: "",
    ketpokayoke: "",
    ketkualitas: "",
    ketsafety: "",
    ketlingkungan: "",
    approve: "",
  };

  const [formIdea, setFormIdea] = useState({});
  const [formNilai, setFormNilai] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [grandTotal, setGrandTotal] = useState(null);
  const [beforeTotal, setBeforeTotal] = useState(0);
  const [kriteria, setKriteria] = useState("");
  const [reward, setReward] = useState("");
  const [nextApv, setNextApv] = useState(false);
  const [biayaDisable, setBiayaDisable] = useState(false);
  const [timeDisable, setTimeDisable] = useState(false);
  const [standarDisable, setStandarDisable] = useState(true);
  const [lihatDisable, setLihatDisable] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (level === 2) {
      setIsEdit(true);
    }
  }, [level]);

  useEffect(() => {
    if (id) {
      async function getIdeaById() {
        setLoadingModal(true);
        try {
          const response = await NilaiServices.getNilaiById(Decrypt(id));
          setIdea(response.data.data);
        } catch (err) {
          setErrors(err);
        }
        setLoadingModal(false);
      }
      getIdeaById();
    }
  }, [id]);

  useEffect(() => {
    if (idea) {
      setFormIdea({
        id: idea.id,
        noidea: idea.noIdea,
        nik: idea.nik,
        name: idea.name,
        title: idea.title,
        category: idea.categoryDesc,
        policy: idea.policyDesc,
        beforeidea: idea.beforeIdea,
        suggestion: idea.suggestion,
        location: idea.location,
        afteridea: idea.afterIdea,
        savemonth: idea.saveMonth || "",
        savetime: idea.saveTime || "",
        status: idea.status,
        approve1: idea.approve1,
        approve2: idea.approve2,
        beforeimgsrc: idea.beforeImgSrc
          ? UrlImg + idea.beforeImgSrc
          : defaultImg,
        afterimgsrc: idea.afterImgSrc ? UrlImg + idea.afterImgSrc : defaultImg,
        standarimgsrc: idea.standarImgSrc
          ? UrlImg + idea.standarImgSrc
          : defaultDoc,
      });

      // let listSaveMonth = [];
      if (!idea.saveMonth || idea.saveMonth === "") {
        setBiayaDisable(true);
      } else {
        // listSaveMonth = idea.saveMonth.split(" ");
        // if (listSaveMonth.length < 10) {
        //   setBiayaDisable(true);
        // }
        if (idea.saveMonth.length < 10) {
          setBiayaDisable(true);
        }
      }

      // let listSaveTime = [];
      if (!idea.saveTime || idea.saveTime === "") {
        setTimeDisable(true);
      } else {
        // listSaveTime = idea.saveTime.split(" ");
        // if (listSaveTime.length < 10) {
        //   setTimeDisable(true);
        // }
        if (idea.saveTime.length < 10) {
          setTimeDisable(true);
        }
      }

      if (!idea.standarImgSrc || idea.standarImgSrc === "") {
        setLihatDisable(true);
      }

      if (idea.idNilai !== 0) {
        setFormNilai({
          idnilai: idea.idNilai,
          nilaisaving: idea.nilaiSaving,
          nilaitime: idea.nilaiTime,
          nilaikreativ: idea.nilaiKreativ,
          nilaiasal: idea.nilaiAsal,
          nilai5s: idea.nilai5S,
          nilaistandar: idea.nilaiStandar,
          nilaipokayoke: idea.nilaiPokayoke,
          nilaikualitas: idea.nilaiKualitas,
          nilaisafety: idea.nilaiSafety,
          nilailingkungan: idea.nilaiLingkungan,
          ketsaving: idea.ketSaving || "",
          kettime: idea.ketTime || "",
          ketkreativ: idea.ketKreativ || "",
          ketasal: idea.ketAsal || "",
          ket5s: idea.ket5S || "",
          ketstandar: idea.ketStandar || "",
          ketpokayoke: idea.ketPokayoke || "",
          ketkualitas: idea.ketKualitas || "",
          ketsafety: idea.ketSafety || "",
          ketlingkungan: idea.ketLingkungan || "",
        });
        setBeforeTotal(idea.totalNilai);
      } else {
        setFormNilai({
          idnilai: 0,
          nilaisaving:
            !idea.saveMonth || (idea.saveMonth && idea.saveMonth.length) < 10
              ? 0
              : "",
          nilaitime:
            !idea.saveTime || (idea.saveTime && idea.saveTime.length) < 10
              ? 0
              : "",
          nilaikreativ: "",
          nilaiasal: "",
          nilai5s: "",
          nilaistandar: 0,
          nilaipokayoke: "",
          nilaikualitas: "",
          nilaisafety: "",
          nilailingkungan: "",
          ketsaving: "",
          kettime: "",
          ketkreativ: "",
          ketasal: "",
          ket5s: "",
          ketstandar: "",
          ketpokayoke: "",
          ketkualitas: "",
          ketsafety: "",
          ketlingkungan: "",
        });
      }
    }
  }, [idea]);

  useEffect(() => {
    const total =
      Number(formNilai.nilaisaving) +
      Number(formNilai.nilaitime) +
      Number(formNilai.nilaikreativ) +
      Number(formNilai.nilaiasal) +
      Number(formNilai.nilai5s) +
      Number(formNilai.nilaistandar) +
      Number(formNilai.nilaipokayoke) +
      Number(formNilai.nilaikualitas) +
      Number(formNilai.nilaisafety) +
      Number(formNilai.nilailingkungan);
    setGrandTotal(total);
    if (total >= 95) {
      setKriteria("S");
      setReward(53000);
      setNextApv(true);
    } else if (total > 79) {
      setKriteria("A");
      setReward(43000);
      setNextApv(true);
    } else if (total > 69) {
      setKriteria("B");
      setReward(33000);
      setNextApv(true);
    } else if (total > 49) {
      setKriteria("C");
      setReward(13000);
      setNextApv(false);
    } else if (total >= 0 && total <= 49) {
      setKriteria("D");
      setReward(8000);
      setNextApv(false);
    } else {
      setKriteria("-");
      setReward("-");
      setNextApv(false);
    }
  }, [formNilai]);

  useEffect(() => {
    setFormIdea({ ...formIdea, approve2: approve });
  }, [approve]);

  useEffect(() => {
    setFormIdea({ ...formIdea, alasanditolak: comment, status: status });
  }, [comment, status]);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        navigate("/login", { replace: true });
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0) {
      if (isNext) {
        setApprovalModal(true);
      } else if (isSubmit) {
        if (level === 3 && grandTotal < beforeTotal) {
          toastval.current.show({
            severity: "error",
            summary: "Validasi",
            detail: "Total nilai lebih kecil dari sebelumnya!",
            life: 3000,
          });
        } else {
          setConfirmModal(true);
        }
      } else if (isReject) {
        setConfirmModal(true);
      }
    }
  }, [formErrors, isSubmit, isNext, isReject]);

  useEffect(() => {
    if (response) {
      if (response.status) {
        toast.current.show({
          severity: "success",
          summary: "Berhasil",
          detail: response.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Gagal",
          detail: response.message,
          life: 3000,
        });
      }
    }
  }, [response]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormNilai({ ...formNilai, [name]: value });
  };

  const validate = (values) => {
    const regex = /[0-9]|\./;
    const errors = {};

    if (!biayaDisable) {
      if (values.nilaisaving === "") {
        errors.nilaisaving = "Harus diisi!";
      } else if (!regex.test(values.nilaisaving)) {
        errors.nilaisaving = "Tidak valid!";
      }
    }

    if (!timeDisable) {
      if (values.nilaitime === "") {
        errors.nilaitime = "Harus diisi!";
      } else if (!regex.test(values.nilaitime)) {
        errors.nilaitime = "Tidak valid!";
      }
    }

    if (values.nilaikreativ === "") {
      errors.nilaikreativ = "Harus diisi!";
    } else if (!regex.test(values.nilaikreativ)) {
      errors.nilaikreativ = "Tidak valid!";
    }

    if (values.nilaiasal === "") {
      errors.nilaiasal = "Harus diisi!";
    } else if (!regex.test(values.nilaiasal)) {
      errors.nilaiasal = "Tidak valid!";
    }

    if (values.nilai5s === "") {
      errors.nilai5s = "Harus diisi!";
    } else if (!regex.test(values.nilai5s)) {
      errors.nilai5s = "Tidak valid!";
    }

    if (values.nilaistandar === "") {
      errors.nilaistandar = "Harus diisi!";
    } else if (!regex.test(values.nilaistandar)) {
      errors.nilaistandar = "Tidak valid!";
    }

    if (values.nilaipokayoke === "") {
      errors.nilaipokayoke = "Harus diisi!";
    } else if (!regex.test(values.nilaipokayoke)) {
      errors.nilaipokayoke = "Tidak valid!";
    }

    if (values.nilaikualitas === "") {
      errors.nilaikualitas = "Harus diisi!";
    } else if (!regex.test(values.nilaikualitas)) {
      errors.nilaikualitas = "Tidak valid!";
    }

    if (values.nilaisafety === "") {
      errors.nilaisafety = "Harus diisi!";
    } else if (!regex.test(values.nilaisafety)) {
      errors.nilaisafety = "Tidak valid!";
    }

    if (values.nilailingkungan === "") {
      errors.nilailingkungan = "Harus diisi!";
    } else if (!regex.test(values.nilailingkungan)) {
      errors.nilailingkungan = "Tidak valid!";
    }
    return errors;
  };

  const validateReject = (values) => {
    const errors = {};
    if (
      !values.ketsaving &&
      !values.kettime &&
      !values.ketkreativ &&
      !values.ketasal &&
      !values.ket5s &&
      !values.ketstandar &&
      !values.ketpokayoke &&
      !values.ketkualitas &&
      !values.ketsafety &&
      !values.ketlingkungan
    ) {
      errors.ket = "Isi minimal 1 ket!";
    }
    return errors;
  };

  const handleClickOk = () => {
    setFormErrors(validate(formNilai));
    setIsSubmit(true);
    setIsNext(false);
    setIsReject(false);
    setConfMsg("Apakah yakin ingin terima ide?");
    setFormIdea({ ...formIdea, status: "Diterima" });
  };

  const handleClickLanjut = () => {
    setFormErrors(validate(formNilai));
    setIsNext(true);
    setIsReject(false);
    setIsSubmit(false);
    setConfMsg("Apakah yakin ingin lanjut level 3?");
    setFormIdea({ ...formIdea, status: "Level 3" });
  };

  const handleClickReject = () => {
    setIsReject(true);
    setIsSubmit(false);
    setIsNext(false);
    if (level === 2) {
      setConfMsg("Apakah yakin ingin tolak ide?");
      setConfirmModal(true);
    } else if (level === 3) {
      setConfMsg("Apakah yakin ingin tinjau ulang ide?");
      setFormErrors(validateReject(formNilai));
    }
  };

  const handleClickEdit = () => {
    setIsEdit(true);
  };

  const handleConfirm = () => {
    const values = {
      ...formIdea,
      level: level,
      ...formNilai,
      totalnilai: grandTotal,
      kriteria: kriteria,
      reward: reward,
      approve: username,
      isqcc: checked,
    };

    if (isReject) {
      if (level === 2) {
        setCommentModal(true);
      } else if (level === 3) {
        rejectNilaiLvl3();
      }
    } else {
      if (formNilai.idnilai === 0 || level === 3) {
        createNilai(values);
      } else {
        updateNilai(values);
      }
    }
  };

  const handleApprove = () => {
    setConfirmModal(true);
  };

  const handleComment = () => {
    rejectNilaiLvl2();
  };

  const createNilai = async (values) => {
    setLoadingModal(true);
    try {
      var res = await NilaiServices.createNilai(values);
      setResponse(res.data);
    } catch (err) {
      setErrors(err);
    } finally {
      setLoadingModal(false);
    }
  };

  const updateNilai = async (values) => {
    setLoadingModal(true);
    try {
      var res = await NilaiServices.updateNilai(values);
      setResponse(res.data);
    } catch (err) {
      setErrors(err);
    } finally {
      setLoadingModal(false);
    }
  };

  const rejectNilaiLvl2 = async () => {
    setLoadingModal(true);
    try {
      var res = await NilaiServices.rejectNilaiLvl2(formIdea);
      setResponse(res.data);
    } catch (err) {
      setErrors(err);
    } finally {
      setLoadingModal(false);
    }
  };

  const rejectNilaiLvl3 = async () => {
    const values = {
      id: formNilai.idnilai,
      noidea: formIdea.noidea,
      level: 2,
      ketsaving: formNilai.ketsaving,
      kettime: formNilai.kettime,
      ketkreativ: formNilai.ketkreativ,
      ketasal: formNilai.ketasal,
      ket5s: formNilai.ket5s,
      ketstandar: formNilai.ketstandar,
      ketpokayoke: formNilai.ketpokayoke,
      ketkualitas: formNilai.ketkualitas,
      ketsafety: formNilai.ketsafety,
      ketlingkungan: formNilai.ketlingkungan,
    };
    setLoadingModal(true);
    try {
      var res = await NilaiServices.rejectNilaiLvl3(values);
      setResponse(res.data);
    } catch (err) {
      setErrors(err);
    } finally {
      setLoadingModal(false);
    }
  };

  const handleChangeQCC = () => {
    setChecked(!checked);
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button label="OK" onClick={() => onHide()} autoFocus />
      </div>
    );
  };

  const onHide = () => {
    confirmDialog({
      message: "Apakah yakin menggunakan format Review Dokumen ?",
      header: "Konfirmasi dokument",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Yakin",
      rejectLabel: "Tidak",
      accept: () => {
        setStandarDisable(false);
        setFormNilai({ ...formNilai, nilaistandar: "" });
      },
    });
    setReviewModal(false);
  };

  return (
    <>
      <Toast ref={toastval} />
      <Toast
        ref={toast}
        onHide={() => navigate("/nilaiideas", { replace: true })}
      />
      <ConfirmDialog />
      {approvalModal && (
        <ApprovalModal
          dept={dept}
          level={3}
          setApprove={setApprove}
          setApprovalModal={setApprovalModal}
          handleConfirm={() => handleApprove()}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          title="Konfirmasi"
          message={confMsg}
          setConfirmModal={setConfirmModal}
          handleConfirm={() => handleConfirm()}
        />
      )}
      {commentModal && (
        <CommentModal
          setStatus={setStatus}
          setReason={setComment}
          setCommentModal={setCommentModal}
          handleComment={() => handleComment()}
        />
      )}
      {loadingModal && <LoadingModal></LoadingModal>}
      <Dialog
        header="Review Dokumen"
        visible={reviewModal}
        maximizable
        modal
        // style={{ height: "70vh" }}
        // breakpoints={{ "960px": "75vw" }}
        footer={renderFooter()}
        onHide={() => onHide()}
        contentStyle={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={formIdea.standarimgsrc}
          alt=""
          onError={(e) => (e.target.src = defaultImg)}
          style={{ height: "100%", width: "100%" }}
        ></img>
      </Dialog>
      <div className="main-container">
        <div className="card-header">
          <h3>Penilaian</h3>
          <Back
            onClick={() => navigate("/nilaiideas", { replace: true })}
          ></Back>
        </div>
        <div
          style={{
            borderBottom: "1px solid var(--color-border)",
            borderRadius: "0 0 5px 5px",
          }}
          className="card-body"
        >
          <GroupCol>
            <Col>
              <FormGroupCol>
                <label style={{ width: "50px" }}>No. Idea</label>
                <label>:</label>
                <p>{formIdea.noidea}</p>
              </FormGroupCol>
              <FormGroupCol>
                <label style={{ width: "50px" }}>Nama</label>
                <label>:</label>
                <p>{formIdea.name}</p>
              </FormGroupCol>
              <FormGroupCol>
                <label style={{ width: "50px" }}>NIK</label>
                <label>:</label>
                <p>{formIdea.nik}</p>
              </FormGroupCol>
            </Col>
            <Col>
              <FormGroupCol>
                <label style={{ width: "130px" }}>Judul</label>
                <label>:</label>
                <p>{formIdea.title}</p>
              </FormGroupCol>
              <FormGroupCol>
                <label style={{ width: "130px" }}>Kategori</label>
                <label>:</label>
                <p>{formIdea.category}</p>
              </FormGroupCol>
              <FormGroupCol>
                <label style={{ width: "130px" }}>Kebijakan Perusahaan</label>
                <label>:</label>
                <p>{formIdea.policy}</p>
              </FormGroupCol>
            </Col>
          </GroupCol>
          <Row>
            <Card>
              <h5>Sebelum Ide</h5>
              <p>{formIdea.beforeidea}</p>
              <div>
                {/* <img
                  style={{ width: "80px", height: "80px" }}
                  src={formIdea.beforeimgsrc}
                  alt=""
                  onError={(e) => (e.target.src = defaultImg)}
                ></img> */}
                <Image
                  src={formIdea.beforeimgsrc}
                  alt="Setelah Ide"
                  imageStyle={{
                    width: "80px",
                    height: "80px",
                  }}
                  preview={formIdea.beforeimgsrc ? true : false}
                  onError={(e) => (e.target.src = defaultImg)}
                />
              </div>
            </Card>
            <Card>
              <h5>Ide perbaikan (yang dilaksanakan)</h5>
              <p>{formIdea.suggestion}</p>
              <p>Lokasi Ide: {formIdea.location}</p>
            </Card>
            <Card>
              <h5>Setelah ide</h5>
              <p>{formIdea.afteridea}</p>
              <div>
                {/* <img
                  style={{ width: "80px", height: "80px" }}
                  src={formIdea.afterimgsrc}
                  alt=""
                  onError={(e) => (e.target.src = defaultImg)}
                ></img> */}
                <Image
                  src={formIdea.afterimgsrc}
                  alt="Setelah Ide"
                  imageStyle={{
                    width: "80px",
                    height: "80px",
                  }}
                  preview={formIdea.afterimgsrc ? true : false}
                  onError={(e) => (e.target.src = defaultImg)}
                />
              </div>
            </Card>
            <Card>
              <h5 style={{ backgroundColor: "#6495ED" }}>
                Efisiensi Biaya /Bulan
              </h5>
              <p>{formIdea.savemonth}</p>
              <h5 style={{ backgroundColor: "#6495ED" }}>
                Efisiensi Waktu /Hari
              </h5>
              <p>{formIdea.savetime}</p>
            </Card>
            <CardStandar>
              <h5>Standarisasi</h5>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "0px 5px",
                  }}
                >
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      marginBottom: "5px",
                    }}
                    src={formIdea.standarimgsrc}
                    alt=""
                    onError={(e) => (e.target.src = defaultImg)}
                  ></img>
                  <button
                    onClick={() => setReviewModal(true)}
                    disabled={lihatDisable}
                    style={lihatDisable ? { cursor: "not-allowed" } : null}
                  >
                    LIHAT
                  </button>
                </div>
                <p>Pastikan menggunakan format Review Dokumen</p>
              </div>
            </CardStandar>
          </Row>
          <GridContainer>
            <ColKriteria>Penilaian PIP (Permohonan Idea Perbaikan)</ColKriteria>
            <RowNilai>NILAI</RowNilai>
            <ColHeader>Penghematan Biaya /bulan</ColHeader>
            <ColHeader>Penghematan Waktu /Hari</ColHeader>
            <ColHeader>Kreativitas Ide</ColHeader>
            <ColHeader>Asal Ide</ColHeader>
            <ColHeader>5S/5R</ColHeader>
            <ColHeader>Standarisasi</ColHeader>
            <ColHeader>Alat Anti salah/Pokayoke</ColHeader>
            <ColHeader>Kualitas Produk/Layanan</ColHeader>
            <ColHeader>Safety</ColHeader>
            <ColHeader>Lingkungan</ColHeader>
            <RowNilai>0</RowNilai>
            <ColStandard>
              {"Tidak ada perhitungan penghematan biaya"}
            </ColStandard>
            <ColStandard>{"Tidak ada perhitungan efisiensi waktu"}</ColStandard>
            <ColStandard style={{ background: "grey" }}>{""}</ColStandard>
            <ColStandard style={{ background: "grey" }}>{""}</ColStandard>
            <ColStandard>{"Tidak berdampak terhadap 5S"}</ColStandard>
            <ColStandard>
              {"Tidak ada standarisasi (Dokumen sistem)"}
            </ColStandard>
            <ColStandard>{"Tidak ada alat anti salah / Pokayoke"}</ColStandard>
            <ColStandard>{"Tidak berdampak terhadap kualitas"}</ColStandard>
            <ColStandard>{"Tidak ada pengaruh terhadap safety"}</ColStandard>
            <ColStandard>
              {"Tidak ada pengaruh terhadap lingkungan"}
            </ColStandard>
            <RowNilai>1</RowNilai>
            <ColStandard>{"< 500 rb"}</ColStandard>
            <ColStandard>{"1 - 10 menit"}</ColStandard>
            <ColStandard>{"Ide sederhana tanpa persiapan khusus"}</ColStandard>
            <ColStandard>{"Arahan pimpinan kerja"}</ColStandard>
            <ColStandard>{"Meningkatkan kebersihan & kerapihan"}</ColStandard>
            <ColStandard>{"Standarisasi dalam proses review"}</ColStandard>
            <ColStandard>{"Digunakan diri sendiri"}</ColStandard>
            <ColStandard>{"Manfaat dirasakan diri sendiri"}</ColStandard>
            <ColStandard>
              {"Mencegah terjadi efek negative, kesehatan kerja"}
            </ColStandard>
            <ColStandard>{"Menjaga kerbersihan ditempat kerja"}</ColStandard>
            <RowNilai>3</RowNilai>
            <ColStandard>{"> 500 rb - 3 jt"}</ColStandard>
            <ColStandard>{"11 - 20 menit"}</ColStandard>
            <ColStandard>
              {"Ide dengan persiapan sederhana dan bisa dilakukan sendiri"}
            </ColStandard>
            <ColStandard>{"Duplikasi dari idea orang lain"}</ColStandard>
            <ColStandard>
              {"Mengurangi inventory level (nilai batas bawah dan batas atas)"}
            </ColStandard>
            <ColStandard style={{ background: "grey" }}>{""}</ColStandard>
            <ColStandard>{"Digunakan internal Departemen"}</ColStandard>
            <ColStandard>
              {"Manfaat dirasakan diinternal departemen"}
            </ColStandard>
            <ColStandard>
              {"Mengurangi potensi kecelakaan kerja ringan"}
            </ColStandard>
            <ColStandard>
              {"Mengurangi limbah  lingkungan non-B3 diinternal bagiannya "}
            </ColStandard>
            <RowNilai>5</RowNilai>
            <ColStandard>{"> 3 jt - 10 jt"}</ColStandard>
            <ColStandard>{"21 - 30 menit"}</ColStandard>
            <ColStandard>
              {
                "Ide dengan persiapan khusus, desain, ada time line & melibatkan pihak internal"
              }
            </ColStandard>
            <ColStandard>
              {"Duplikasi dari ide orang lain, dengan tambahan modifikasi"}
            </ColStandard>
            <ColStandard>
              {"Mengurangi inventory level, & meningkatkan produktivitas kerja"}
            </ColStandard>
            <ColStandard style={{ background: "grey" }}>{""}</ColStandard>
            <ColStandard>{"Digunakan Departemen lain"}</ColStandard>
            <ColStandard>{"Manfaat dirasakan departemen lain"}</ColStandard>
            <ColStandard>
              {"Mengurangi potensi kecelakaan kerja sedang"}
            </ColStandard>
            <ColStandard>
              {"Mengurangi limbah  lingkungan B3 diinternal bagiannya "}
            </ColStandard>
            <RowNilai>10</RowNilai>
            <ColStandard>{"> 10  jt"}</ColStandard>
            <ColStandard>{"> 30 menit"}</ColStandard>
            <ColStandard>
              {
                "Ide dengan persiapan khusus, desain, ada time line & melibatkan pihak External"
              }
            </ColStandard>
            <ColStandard>{"Murni Ide sendiri"}</ColStandard>
            <ColStandard>
              {
                "Mengurangi inventory level & meningkatkan produktivitas kerja serta mencegah bahaya ditempat kerja"
              }
            </ColStandard>
            <ColStandard>
              {"Standarisasi  (SOP/ WI / Standar lain ) terdaftar di QS Dept"}
            </ColStandard>
            <ColStandard>
              {
                "Digunakan internal  Departemen dan eksternal (Supplier / Customer)"
              }
            </ColStandard>
            <ColStandard>
              {"Manfaat dirasakan departemen lain bahkan customer"}
            </ColStandard>
            <ColStandard>
              {
                "Mengurangi potensi kecelakaan kerja fatal (Cacat , kematian, kebakaran)"
              }
            </ColStandard>
            <ColStandard>
              {"Mengurangi pembuangan limbah ke pihak external perusahaan"}
            </ColStandard>
            <RowNilai
              style={{
                backgroundColor: "#6fa8dc",
              }}
            >
              <div
                style={{
                  writingMode: "vertical-rl",
                  textOrientation: "upright",
                  fontSize: "0.6rem",
                }}
              >
                SUB
              </div>
              <div
                style={{
                  writingMode: "vertical-rl",
                  textOrientation: "upright",
                  fontSize: "0.5rem",
                }}
              >
                TOTAL
              </div>
            </RowNilai>
            <GroupInput>
              <Select
                name="nilaisaving"
                value={formNilai.nilaisaving}
                onChange={(e) => handleChange(e)}
                disabled={isEdit && !biayaDisable ? false : true}
              >
                <option value=""></option>
                {listNilai.map((nilai) => {
                  return (
                    <option key={nilai} value={nilai}>
                      {nilai}
                    </option>
                  );
                })}
              </Select>
              <i>{formErrors.nilaisaving}</i>
            </GroupInput>
            <GroupInput>
              <Select
                name="nilaitime"
                value={formNilai.nilaitime}
                onChange={(e) => handleChange(e)}
                disabled={isEdit && !timeDisable ? false : true}
              >
                <option value=""></option>
                {listNilai.map((nilai) => {
                  return (
                    <option key={nilai} value={nilai}>
                      {nilai}
                    </option>
                  );
                })}
              </Select>
              <i>{formErrors.nilaitime}</i>
            </GroupInput>
            <GroupInput>
              <Select
                name="nilaikreativ"
                value={formNilai.nilaikreativ}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              >
                <option value=""></option>
                <option value={1}>1</option>
                <option value={3}>3</option>
                <option value={5}>5</option>
                <option value={10}>10</option>
              </Select>
              <i>{formErrors.nilaikreativ}</i>
            </GroupInput>
            <GroupInput>
              <Select
                name="nilaiasal"
                value={formNilai.nilaiasal}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              >
                <option value=""></option>
                <option value={1}>1</option>
                <option value={3}>3</option>
                <option value={5}>5</option>
                <option value={10}>10</option>
              </Select>
              <i>{formErrors.nilaiasal}</i>
            </GroupInput>
            <GroupInput>
              <Select
                name="nilai5s"
                value={formNilai.nilai5s}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              >
                <option value=""></option>
                {listNilai.map((nilai) => {
                  return (
                    <option key={nilai} value={nilai}>
                      {nilai}
                    </option>
                  );
                })}
              </Select>
              <i>{formErrors.nilai5s}</i>
            </GroupInput>
            <GroupInput>
              <Select
                name="nilaistandar"
                value={formNilai.nilaistandar}
                onChange={(e) => handleChange(e)}
                disabled={isEdit && !standarDisable ? false : true}
                tooltip="A Disabled Button"
                tooltipOptions={{ showOnDisabled: true }}
              >
                <option value=""></option>
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={10}>10</option>
              </Select>
              <i>{formErrors.nilaistandar}</i>
            </GroupInput>
            <GroupInput>
              <Select
                name="nilaipokayoke"
                value={formNilai.nilaipokayoke}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              >
                <option value=""></option>
                {listNilai.map((nilai) => {
                  return (
                    <option key={nilai} value={nilai}>
                      {nilai}
                    </option>
                  );
                })}
              </Select>
              <i>{formErrors.nilaipokayoke}</i>
            </GroupInput>
            <GroupInput>
              <Select
                name="nilaikualitas"
                value={formNilai.nilaikualitas}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              >
                <option value=""></option>
                {listNilai.map((nilai) => {
                  return (
                    <option key={nilai} value={nilai}>
                      {nilai}
                    </option>
                  );
                })}
              </Select>
              <i>{formErrors.nilaikualitas}</i>
            </GroupInput>
            <GroupInput>
              <Select
                name="nilaisafety"
                value={formNilai.nilaisafety}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              >
                <option value=""></option>
                {listNilai.map((nilai) => {
                  return (
                    <option key={nilai} value={nilai}>
                      {nilai}
                    </option>
                  );
                })}
              </Select>
              <i>{formErrors.nilaisafety}</i>
            </GroupInput>
            <GroupInput>
              <Select
                name="nilailingkungan"
                value={formNilai.nilailingkungan}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              >
                <option value=""></option>
                {listNilai.map((nilai) => {
                  return (
                    <option key={nilai} value={nilai}>
                      {nilai}
                    </option>
                  );
                })}
              </Select>
              <i>{formErrors.nilailingkungan}</i>
            </GroupInput>
            <RowNilai
              style={{
                backgroundColor: "#6fa8dc",
              }}
            >
              <div
                style={{
                  writingMode: "vertical-rl",
                  textOrientation: "upright",
                  fontSize: "0.6rem",
                }}
              >
                KET
              </div>
            </RowNilai>
            <GroupInput>
              <Input
                name="ketsaving"
                value={formNilai.ketsaving}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              ></Input>
              <i>{formErrors.ket}</i>
            </GroupInput>
            <GroupInput>
              <Input
                name="kettime"
                value={formNilai.kettime}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              ></Input>
              <i>{formErrors.ket}</i>
            </GroupInput>
            <GroupInput>
              <Input
                name="ketkreativ"
                value={formNilai.ketkreativ}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              ></Input>
              <i>{formErrors.ket}</i>
            </GroupInput>
            <GroupInput>
              <Input
                name="ketasal"
                value={formNilai.ketasal}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              ></Input>
              <i>{formErrors.ket}</i>
            </GroupInput>
            <GroupInput>
              <Input
                name="ket5s"
                value={formNilai.ket5s}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              ></Input>
              <i>{formErrors.ket}</i>
            </GroupInput>
            <GroupInput>
              <Input
                name="ketstandar"
                value={formNilai.ketstandar}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              ></Input>
              <i>{formErrors.ket}</i>
            </GroupInput>
            <GroupInput>
              <Input
                name="ketpokayoke"
                value={formNilai.ketpokayoke}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              ></Input>
              <i>{formErrors.ket}</i>
            </GroupInput>
            <GroupInput>
              <Input
                name="ketkualitas"
                value={formNilai.ketkualitas}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              ></Input>
              <i>{formErrors.ket}</i>
            </GroupInput>
            <GroupInput>
              <Input
                name="ketsafety"
                value={formNilai.ketsafety}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              ></Input>
              <i>{formErrors.ket}</i>
            </GroupInput>
            <GroupInput>
              <Input
                name="ketlingkungan"
                value={formNilai.ketlingkungan}
                onChange={(e) => handleChange(e)}
                disabled={isEdit ? false : true}
              ></Input>
              <i>{formErrors.ket}</i>
            </GroupInput>
          </GridContainer>
          <GridSumNilai>
            <ColHeader>TOTAL</ColHeader>
            <ColHeader>GRADE</ColHeader>
            <ColHeader>REWARD (Rp)</ColHeader>
            <ColHeader
              style={{
                gridColumnStart: "4",
                gridColumnEnd: "10",
                backgroundColor: "#eec4c9",
              }}
            >
              APPROVE / MENYETUJUI
            </ColHeader>
            <ColStandard
              style={{
                gridRowStart: "2",
                gridRowEnd: "5",
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              {grandTotal}
            </ColStandard>
            <ColStandard
              style={{
                gridRowStart: "2",
                gridRowEnd: "5",
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              {kriteria}
            </ColStandard>
            <ColStandard
              style={{
                gridRowStart: "2",
                gridRowEnd: "5",
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              {reward}
            </ColStandard>
            <ColHeader
              style={{
                gridColumnStart: "4",
                gridColumnEnd: "7",
                backgroundColor: "#eec4c9",
              }}
            >
              {"LEVEL 2 (GROUP LEADER / SECTION HEAD)"}
            </ColHeader>
            <ColHeader
              style={{
                gridColumnStart: "7",
                gridColumnEnd: "10",
                backgroundColor: "#eec4c9",
              }}
            >
              {"LEVEL 3 (DEPT HEAD / DIVISI HEAD)"}
            </ColHeader>
            {!nextApv && <ColStandard>Ide diterima</ColStandard>}
            <ColStandard
              style={
                !nextApv
                  ? { gridColumnStart: "5", gridColumnEnd: "7" }
                  : { gridColumnStart: "4", gridColumnEnd: "6" }
              }
            >
              Ide ditolak
            </ColStandard>
            {nextApv && (
              <ColStandard>Butuh persetujuan Dept / Div Head</ColStandard>
            )}
            <ColStandard>Nilai ide diterima</ColStandard>
            <ColStandard>
              Nilai ide tidak diterima (Mohon tinjau ulang)
            </ColStandard>
            <ColStandard>
              Nilai ide ditambah (jika ide benar-benar bagus)
            </ColStandard>
            {!nextApv && (
              <ColStandard>
                {level === 2 ? (
                  <CursorImg
                    src={require("../../assets/ok.png")}
                    alt=""
                    onClick={() => handleClickOk()}
                  ></CursorImg>
                ) : (
                  <img
                    // style={{ cursor: "not-allowed" }}
                    src={require("../../assets/unavailable.png")}
                    alt=""
                  ></img>
                )}
              </ColStandard>
            )}
            <ColStandard
              style={
                !nextApv
                  ? { gridColumnStart: "5", gridColumnEnd: "7" }
                  : { gridColumnStart: "4", gridColumnEnd: "6" }
              }
            >
              {level === 2 ? (
                <CursorImg
                  src={require("../../assets/cancel.png")}
                  alt=""
                  onClick={() => handleClickReject()}
                ></CursorImg>
              ) : (
                <img
                  // style={{ cursor: "not-allowed" }}
                  src={require("../../assets/unavailable.png")}
                  alt=""
                ></img>
              )}
            </ColStandard>
            {nextApv && (
              <ColStandard>
                {level === 2 ? (
                  <CursorImg
                    src={require("../../assets/level2.png")}
                    alt=""
                    onClick={() => handleClickLanjut()}
                  ></CursorImg>
                ) : (
                  <img
                    // style={{ cursor: "not-allowed" }}
                    src={require("../../assets/unavailable.png")}
                    alt=""
                  ></img>
                )}
              </ColStandard>
            )}
            <ColStandard>
              {level === 3 ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <CursorImg
                    src={require("../../assets/ok.png")}
                    alt=""
                    onClick={() => handleClickOk()}
                  ></CursorImg>{" "}
                  <div>
                    <input
                      style={{ width: "auto" }}
                      type="checkbox"
                      name="qcc"
                      value={checked}
                      checked={checked}
                      onChange={(e) => handleChangeQCC(e)}
                    />
                    <label style={{ marginLeft: "5px" }}>
                      Bisa dikembangkan jadi tema QCC
                    </label>
                  </div>
                </div>
              ) : (
                <img
                  // style={{ cursor: "not-allowed" }}
                  src={require("../../assets/unavailable.png")}
                  alt=""
                ></img>
              )}
            </ColStandard>
            <ColStandard>
              {level === 3 ? (
                <CursorImg
                  src={require("../../assets/cancel.png")}
                  alt=""
                  onClick={() => handleClickReject()}
                ></CursorImg>
              ) : (
                <img
                  // style={{ cursor: "not-allowed" }}
                  src={require("../../assets/unavailable.png")}
                  alt=""
                ></img>
              )}
            </ColStandard>
            <ColStandard>
              {level === 3 ? (
                <button className="btn" onClick={(e) => handleClickEdit(e)}>
                  Edit
                </button>
              ) : (
                // <button
                //   className="btn"
                //   style={{ cursor: "not-allowed" }}
                //   disabled
                // >
                //   Edit
                // </button>
                <img
                  // style={{ cursor: "not-allowed" }}
                  src={require("../../assets/unavailable.png")}
                  alt=""
                ></img>
              )}
            </ColStandard>
          </GridSumNilai>
        </div>
        {/* <div className="card-footer align-right">
          <div className="btn-group">
            <NavLink to="/nilaiideas">
              <button type="button" className="btn btn-danger">
                Batal
              </button>
            </NavLink>
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default CheckIdea;
