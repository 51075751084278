import React from "react";
import PTableLapPayRewardHistoryId from "../table/PTableLapPayRewardHistoryId";

const LapPayRewardHistoryId = () => {
  return (
    <div className="main-container">
      <div className="card-header">
        <h4>Laporan History Payment Reward Detail</h4>
      </div>
      <div className="card-body without-footer">
        <PTableLapPayRewardHistoryId></PTableLapPayRewardHistoryId>
      </div>
    </div>
  );
};

export default LapPayRewardHistoryId;
