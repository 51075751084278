import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  username: null,
  nama: null,
  dept: null,
  site: null,
  level: null,
  status: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_ACTIVE_USER: (state, action) => {
      const { username, nama, dept, site, level, status } = action.payload;
      state.isLoggedIn = true;
      state.username = username;
      state.nama = nama;
      state.dept = dept;
      state.site = site;
      state.level = level;
      state.status = status;
    },
    REMOVE_ACTIVE_USER(state, action) {
      state.isLoggedIn = false;
      state.username = null;
      state.nama = null;
      state.dept = null;
      state.site = null;
      state.level = null;
      state.status = null;
    },
  },
});

export const { SET_ACTIVE_USER, REMOVE_ACTIVE_USER } = authSlice.actions;

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectUsername = (state) => state.auth.username;
export const selectNama = (state) => state.auth.nama;
export const selectDept = (state) => state.auth.dept;
export const selectSite = (state) => state.auth.site;
export const selectLevel = (state) => state.auth.level;
export const selectStatus = (state) => state.auth.status;

export default authSlice.reducer;
