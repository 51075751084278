import React from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const NotFound = () => {
  return (
    <MainContainer>
      <h1 style={{ margin: "10px", color: "crimson" }}>Error 404</h1>
      <h4>Halaman Tidak Ditemukan.</h4>
    </MainContainer>
  );
};

export default NotFound;
