import Api from "../api/api";

class SettingServices {
  getDept = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/setting", config);
  };

  getSect = (dept) => {
    return Api.get("/setting/sect/" + dept);
  };

  getRole = () => {
    return Api.get("/setting/role");
  };

  getCategory = () => {
    return Api.get("/setting/category");
  };

  getPolicy = () => {
    return Api.get("/setting/policy");
  };  
}

export default new SettingServices();
