import Api from "../api/api";

class UserServices {
  getAllUsers = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/user", config);
  };

  getUserById = (id) => {
    return Api.get("/user/getuserid/" + id);
  };

  createUser = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.post("/user", data, config);
  };

  updateUser = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.put("/user", data, config);
  };

  updateUserWithOutPass = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.put("/user/updateuserwithoutpass", data, config);
  };

  deleteUser = (id) => {
    return Api.delete("/user/" + id);
  };

  getUserDeptLevel = (level, dept) => {
    return Api.get("/user/getuserdeptlevel/" + level + "/" + dept);
  };

  getUserApproval = (username) => {
    return Api.get("/user/getuserapproval/" + username);
  };

  getUserFoto = (username) => {
    return Api.get("/user/getuserfoto/" + username);
  };

  updateUserFoto = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.put("/user/updateuserfoto", data, config);
  };

  getUserByUser = (username) => {
    return Api.get("/user/getuserbyuser/" + username);
  };
}

export default new UserServices();
