import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import IdeaServices from "../../services/IdeaServices";
import "./PTableIdea.css";
import { Label, GroupCol, Tahun } from "../element/GlobalStyle";
import jwt from "jwt-decode";

const PTableLapTotalIde = () => {
  const token = localStorage.getItem("token");
  const user = jwt(token);
  const nik = user.username;
  const status = user.status;

  const [ideas, setIdeas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  // const [years, setYears] = useState([]);

  const dt = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const tgl = new Date();
    // const currYear = tgl.getFullYear();

    // let tahun = [];
    // for (var i = 2021; i <= currYear; i++) {
    //   tahun.push(i);
    // }

    // setYears(tahun);
    setSelectedYear(tgl);
  }, []);

  useEffect(() => {
    if (selectedYear) {
      setLoading(true);
      async function getAllIdeas() {
        setLoading(true);
        try {
          if (status === "Admin") {
            const res = await IdeaServices.getIdeaTotalIde(
              selectedYear.getFullYear(),
              "All"
            );
            setIdeas(res.data.data);
          } else {
            const res = await IdeaServices.getIdeaTotalIde2(
              selectedYear.getFullYear(),
              nik
            );
            setIdeas(res.data.data);
          }
        } catch (err) {
          // console.log(err);
          setErrors(err);
        } finally {
          setLoading(false);
        }
      }
      getAllIdeas();
    }
  }, [selectedYear, nik, status]);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        navigate("/login");
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  const onYearChange = (e) => {
    setSelectedYear(e.value);
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      let dataExport = [];
      ideas.map((idea) => {
        const data = {
          Factory: idea.site,
          Dept: idea.dept,
          Jan: idea.jan,
          Feb: idea.feb,
          Mar: idea.mar,
          Apr: idea.apr,
          May: idea.may,
          Jun: idea.jun,
          Jul: idea.jul,
          Aug: idea.aug,
          Sep: idea.sep,
          Oct: idea.oct,
          Nov: idea.nov,
          Dec: idea.dec,
        };
        return dataExport.push(data);
      });
      const worksheet = xlsx.utils.json_to_sheet(dataExport);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Total Ide");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, ideas);
        doc.save("Total Ide.pdf");
      });
    });
  };

  const cols = [
    { field: "site", header: "Factory" },
    { field: "dept", header: "Dept" },
    { field: "jan", header: "Jan" },
    { field: "feb", header: "Feb" },
    { field: "mar", header: "Mar" },
    { field: "apr", header: "Apr" },
    { field: "may", header: "May" },
    { field: "jun", header: "Jun" },
    { field: "jul", header: "Jul" },
    { field: "aug", header: "Aug" },
    { field: "sep", header: "Sep" },
    { field: "oct", header: "Oct" },
    { field: "nov", header: "Nov" },
    { field: "dec", header: "Dec" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const factoryTemplate = (rowData) => {
    return (
      <Fragment>
        <span>{rowData.site}</span>
      </Fragment>
    );
  };

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={options.filterClearCallback}
        className="p-button-secondary"
      ></Button>
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={options.filterApplyCallback}
        className="p-button-success"
      ></Button>
    );
  };

  const factoryFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={["TNG", "CKR"]}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder="Pilih factory"
        panelStyle={{ fontSize: "0.8rem" }}
        showClear
      />
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "5px",
        }}
      >
        <GroupCol>
          <Label
            style={{
              marginRight: "10px",
            }}
          >
            Tahun
          </Label>
          <Tahun
            id="year"
            value={selectedYear}
            onChange={(e) => onYearChange(e)}
            view="year"
            dateFormat="yy"
            style={{
              marginRight: "10px",
            }}
          />
        </GroupCol>
        {status === "Admin" ? (
          <div style={{ display: "flex" }}>
            <Button
              style={{
                padding: "5px 15px",
                fontSize: "0.8rem",
                marginRight: "10px",
              }}
              type="button"
              icon="pi pi-file-excel"
              onClick={exportExcel}
              className="p-button-success mr-2"
              tooltip="XLS"
              tooltipOptions={{ position: "bottom" }}
            />
            <Button
              style={{
                padding: "5px 15px",
                fontSize: "0.8rem",
              }}
              type="button"
              icon="pi pi-file-pdf"
              onClick={exportPdf}
              className="p-button-warning mr-2"
              tooltip="PDF"
              tooltipOptions={{ position: "bottom" }}
            />
          </div>
        ) : null}
      </div>
      <DataTable
        value={ideas}
        loading={loading}
        rowGroupMode="rowspan"
        groupRowsBy="site"
        sortMode="single"
        sortField="site"
        sortOrder={-1}
        responsiveLayout="scroll"
        className="header-class-report"
        rowClassName="row-class-report"
        ref={dt}
        emptyMessage="Data tidak ditemukan"
        removableSort
      >
        <Column
          field="site"
          header="Factory"
          body={factoryTemplate}
          style={{ minWidth: "90px", maxWidth: "90px" }}
          sortable
          filter
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
          filterElement={factoryFilterTemplate}
        ></Column>
        <Column
          field="dept"
          header="Dept"
          style={{ minWidth: "80px", maxWidth: "80px" }}
          sortable
          filter
          filterPlaceholder="Cari dept"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="jan"
          header="Jan"
          // style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="feb"
          header="Feb"
          // style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="mar"
          header="Mar"
          // style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="apr"
          header="Apr"
          // style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="may"
          header="May"
          // style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="jun"
          header="Jun"
          // style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="jul"
          header="Jul"
          // style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="aug"
          header="Aug"
          // style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="sep"
          header="Sep"
          // style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="oct"
          header="Oct"
          // style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="nov"
          header="Nov"
          // style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="dec"
          header="Dec"
          // style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
      </DataTable>
    </div>
  );
};

export default PTableLapTotalIde;
