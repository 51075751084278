import React from "react";
import PTableLapPayReward from "../table/PTableLapPayReward";

const LapPayReward = () => {
  return (
    <div className="main-container">
      <div className="card-header">
        <h4>Laporan Payment Reward</h4>
      </div>
      <div className="card-body without-footer">
        <PTableLapPayReward></PTableLapPayReward>
      </div>
    </div>
  );
};

export default LapPayReward;
