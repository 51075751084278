import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Moment from "moment";
import PaymentServices from "../../services/PaymentServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

const PTableLapPayRewardHistoryId = () => {
  const { id } = useParams();
  const [payIdeas, setPayIdeas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const dt = useRef(null);
  // const [selectedIde, setSelectedIde] = useState(null);
  // const [selectedFactory, setSelectedFactory] = useState({
  //   name: "All",
  //   code: 0,
  // });

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      async function getPayIdea() {
        setLoading(true);
        try {
          const res = await PaymentServices.getPayIdeaById(id);
          setPayIdeas(res.data.data);
        } catch (err) {
          // console.log(err);
          setErrors(err);
        } finally {
          setLoading(false);
        }
      }
      getPayIdea();
    }
  }, [id]);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        navigate("/login");
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  // const exportExcel = () => {
  //   import("xlsx").then((xlsx) => {
  //     let dataExport = [];
  //     payIdeas.map((idea) => {
  //       const data = {
  //         NIK: idea.nik,
  //         Nama: idea.nama,
  //         Dept: idea.dept,
  //         Rupiah: idea.rupiah,
  //       };
  //       return dataExport.push(data);
  //     });
  //     const worksheet = xlsx.utils.json_to_sheet(dataExport);
  //     const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  //     const excelBuffer = xlsx.write(workbook, {
  //       bookType: "xlsx",
  //       type: "array",
  //     });
  //     saveAsExcelFile(excelBuffer, "Payment Reward");
  //   });
  // };

  // const saveAsExcelFile = (buffer, fileName) => {
  //   import("file-saver").then((module) => {
  //     if (module && module.default) {
  //       let EXCEL_TYPE =
  //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //       let EXCEL_EXTENSION = ".xlsx";
  //       const data = new Blob([buffer], {
  //         type: EXCEL_TYPE,
  //       });

  //       module.default.saveAs(
  //         data,
  //         fileName + "_" + new Date().getTime() + EXCEL_EXTENSION
  //       );
  //     }
  //   });
  // };

  // const exportPdf = () => {
  //   import("jspdf").then((jsPDF) => {
  //     import("jspdf-autotable").then(() => {
  //       const doc = new jsPDF.default(0, 0);
  //       doc.autoTable(exportColumns, payIdeas);
  //       doc.save("Payment Reward.pdf");
  //     });
  //   });
  // };

  // const cols = [
  //   { field: "nik", header: "NIK" },
  //   { field: "nama", header: "Nama" },
  //   { field: "dept", header: "Dept" },
  //   { field: "rupiah", header: "Rupiah" },
  // ];

  // const exportColumns = cols.map((col) => ({
  //   title: col.header,
  //   dataKey: col.field,
  // }));

  // const factories = [
  //   { name: "All", code: 0 },
  //   { name: "TNG", code: 1 },
  //   { name: "CKR", code: 3 },
  // ];

  // const onFactoryChange = (e) => {
  //   setSelectedFactory(e.value);
  // };

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={options.filterClearCallback}
        className="p-button-secondary"
      ></Button>
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={options.filterApplyCallback}
        className="p-button-success"
      ></Button>
    );
  };

  function formatDate(value) {
    return Moment(value).format("DD-MM-YYYY");
  }

  function formatPeriode(value) {
    return Moment(value).format("MM-YYYY");
  }

  const periodeTemplate = (rowData) => {
    return (
      <span style={{ wordBreak: "keep-all" }}>
        {formatPeriode(new Date(rowData.periode))}
      </span>
    );
  };

  const tglSampaiTemplate = (rowData) => {
    return (
      <span style={{ wordBreak: "keep-all" }}>
        {formatDate(new Date(rowData.updateDate))}
      </span>
    );
  };

  const rewardTemplate = (rowData) => {
    return <span>{rowData.reward.toLocaleString("en-US")}</span>;
  };

  const kriteriaFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={["S", "A", "B", "C", "D"]}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder="Pilih kriteria"
        panelStyle={{ fontSize: "0.8rem" }}
        showClear
      />
    );
  };

  return (
    <div>
      <DataTable
        value={payIdeas}
        scrollable
        loading={loading}
        scrollDirection="both"
        className="header-class"
        rowClassName="row-class-report"
        ref={dt}
        removableSort
        // selectionMode="single"
        // selection={selectedIde}
        // onSelectionChange={(e) => setSelectedIde(e.value)}
        emptyMessage="Data tidak ditemukan"
      >
        <Column
          header="No"
          headerStyle={{ width: "3em" }}
          style={{ width: "3em" }}
          body={(data, options) => options.rowIndex + 1}
        ></Column>
        <Column
          field="periode"
          header="Periode"
          body={periodeTemplate}
          style={{ flexGrow: 1, flexBasis: "120px" }}
          // sortable
          // filter
          // filterPlaceholder="Cari periode"
          // filterMatchMode="contains"
          // showFilterOperator={false}
          // showFilterMatchModes={false}
          // showAddButton={false}
          // filterClear={filterClearTemplate}
          // filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="updateDate"
          header="Tgl Diterima"
          body={tglSampaiTemplate}
          style={{ flexGrow: 1, flexBasis: "150px" }}
          sortable
          filter
          filterPlaceholder="Cari tgl diterima"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="noIdea"
          header="No Ide"
          style={{ flexGrow: 1, flexBasis: "120px" }}
          sortable
          filter
          filterPlaceholder="Cari no ide"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="nik"
          header="NIK"
          style={{ flexGrow: 1, flexBasis: "100px" }}
          sortable
          filter
          sortablefilter
          filterPlaceholder="Cari NIK"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="nama"
          header="Nama"
          style={{ flexGrow: 1, flexBasis: "200px" }}
          sortable
          filter
          sortablefilter
          filterPlaceholder="Cari nama"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="kriteria"
          header="Kriteria"
          style={{ flexGrow: 1, flexBasis: "100px" }}
          sortable
          filter
          filterPlaceholder="Cari kriteria"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
          filterElement={kriteriaFilterTemplate}
        ></Column>
        <Column
          field="reward"
          header="Reward"
          body={rewardTemplate}
          style={{ flexGrow: 1, flexBasis: "115px" }}
          align="right"
          sortable
        ></Column>
      </DataTable>
    </div>
  );
};

export default PTableLapPayRewardHistoryId;
