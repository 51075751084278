import Api from "../api/api";

class AuthServices {
  login = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    
    return Api.post("/auth", data, config);
  };

  changePassword = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.put("/auth", data, config);
  };
}

export default new AuthServices();
