import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ConfirmModal from "../modal/ConfirmModal";
import LoadingModal from "../modal/LoadingModal";
import NilaiServices from "../../services/NilaiServices";
import FormatDate from "../utils/FormatDate";
import { Decrypt } from "../utils/UrlCrypt";

const Back = styled(FaArrowLeft)`
  color: #0066cc;
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
`;

const CardIdea = styled.div`
  padding: 5px;
  margin: 5px;
  // border-radius: 5px;
  // margin-bottom: 5px;
  // background-color: #f0f8ff;
  // border: 1px solid #e5e5e5;
  // box-shadow: 0 1px 2px 1px rgb(0 0 0 / 6%);
  display: flex;
  align-items: center;
`;

const FormGroupCol = styled.div`
  display: flex;
  font-size: 16px;
  label {
    width: 70px;
    font-weight: 500;
  }
  p {
    margin-left: 5px;
  }
`;

const Nilai = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #696969;
  padding: 5px 10px;
  align-items: center;
  border-radius: 5px;
`;

const CircleIcon = styled(FaCircle)`
  pointer-events: none;
  font-size: 11px;
  margin-right: 5px;
`;

const SummaryIdea = () => {
  const { id } = useParams();
  const [nilai, setNilai] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [errors, setErrors] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      async function getIdeaById() {
        setLoadingModal(true);
        try {
          const response = await NilaiServices.getNilaiById(Decrypt(id));
          setNilai(response.data.data);
        } catch (err) {
          setErrors(err);
        }
        setLoadingModal(false);
      }
      getIdeaById();
    }
  }, [id]);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        navigate("/login");
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  const handleDelete = () => {
    deleteNilai();
  };

  const deleteNilai = async () => {
    setLoadingModal(true);
    try {
      await NilaiServices.deleteNilai(nilai.idNilai);
    } catch (err) {
      setErrors(err);
    } finally {
      setLoadingModal(false);
      navigate("/nilaiideas", { replace: true });
    }
  };

  return (
    <>
      {confirmModal && (
        <ConfirmModal
          title="Konfirmasi"
          message={"Apakah yakin ingin hapus nilai?"}
          setConfirmModal={setConfirmModal}
          handleConfirm={() => handleDelete()}
        />
      )}
      {loadingModal && <LoadingModal></LoadingModal>}
      <div className="main-container">
        <div className="card-header">
          <h3>Rangkuman Ide</h3>
          {/* <NavLink to={"/nilaiideas"}>
            <Back></Back>
          </NavLink> */}
          <Back
            onClick={() => navigate("/nilaiideas", { replace: true })}
          ></Back>
        </div>
        <div className="card-body">
          {nilai && (
            <CardIdea>
              <div className="idea-icon">
                <div>
                  <img
                    src={require("../../assets/" + nilai.categoryIcon)}
                    alt=""
                  />
                </div>
                <h1>{nilai.categoryDesc}</h1>
              </div>
              <div className="idea-content">
                <div>
                  <FormGroupCol>
                    <label>No. Idea</label>
                    <>:</>
                    <p>{nilai.noIdea}</p>
                  </FormGroupCol>
                  <FormGroupCol>
                    <label>Nama</label>
                    <>:</>
                    <p>{nilai.name}</p>
                  </FormGroupCol>
                  <FormGroupCol>
                    <label>NIK</label>
                    <>:</>
                    <p>{nilai.nik}</p>
                  </FormGroupCol>
                  <FormGroupCol>
                    <label>Judul</label>
                    <>:</>
                    <p>{nilai.title}</p>
                  </FormGroupCol>
                  <div className="idea-content-policy">
                    {/* <i className={"fa fa-circle " + nilai.policyIcon}></i> */}
                    <CircleIcon
                      style={{ color: nilai.policyIcon }}
                    ></CircleIcon>
                    <p>{nilai.policyDesc}</p>
                  </div>
                  <div className="idea-content-date">
                    <p>Last Update {FormatDate(new Date(nilai.updateDate))}</p>
                  </div>
                </div>
                <Nilai>
                  <p style={{ fontSize: "0.8rem" }}>Total Nilai</p>
                  <p>{nilai.totalNilai}</p>
                  <p style={{ fontSize: "0.8rem" }}>Grade</p>
                  <p>{nilai.kriteria}</p>
                  <p style={{ fontSize: "0.8rem" }}>Reward</p>
                  <p>Rp {nilai.reward}</p>
                </Nilai>
              </div>
            </CardIdea>
          )}
        </div>
        <div className="card-footer align-right">
          <div className="btn-group">
            {/* <button
              type="button"
              onClick={() => setConfirmModal(true)}
              className="btn btn-danger"
            >
              Hapus
            </button> */}
            <NavLink to={"/checkidea/" + encodeURIComponent(id)}>
              <button type="button" className="btn btn-primary">
                Ubah
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default SummaryIdea;
