import React from "react";
import PTableLapPayRewardHistory from "../table/PTableLapPayRewardHistory";

const LapPayRewardHistory = () => {
  return (
    <div className="main-container">
      <div className="card-header">
        <h4>Laporan History Payment Reward</h4>
      </div>
      <div className="card-body without-footer">
        <PTableLapPayRewardHistory></PTableLapPayRewardHistory>
      </div>
    </div>
  );
};

export default LapPayRewardHistory;
