import Api from "../api/api";

class PaymentServices {
  getPayIdea = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/payment", config);
  };

  getPayIdeaById = (id) => {
    return Api.get("/payment/getpayideabyid/" + id);
  };

  getPayIdeaPGA = (periode, site) => {
    return Api.get("/payment/getpayideapga/" + periode + "/" + site);
  };

  createPayIdea = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.post("/payment", data, config);
  };

  updatePayIdea = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.put("/payment", data, config);
  };

  updatePayIdeaProses = (periode, site) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.put(
      "/payment/updatepayideaproses/" + periode + "/" + site,
      config
    );
  };

  validasiPayIdea = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.post("/payment/validasipayidea", data, config);
  };

  sendEmail = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.post("/mail", data, config);
  };
}

export default new PaymentServices();
