import React from "react";
import styled from "styled-components";
import { FaQuestionCircle } from "react-icons/fa";
import Divider from "../element/Divider";

const Background = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 500px;
  height: auto;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
`;

const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 25px 10px 25px;
  color: var(--color-primary);
`;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 25px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 25px 10px 25px;
`;

const QuestionButton = styled(FaQuestionCircle)`
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 5px;
  color: var(--color-primary);
`;

const Button = styled.button`
  padding: 5px 15px;
  border-radius: 15px;
  outline: none;
  border: none;
  font-size: var(--font-size-text);
  color: #fff;
  cursor: pointer;
  background-color: var(--color-primary);
  margin: 5px;
`;

function ConfirmModal({ title, message, setConfirmModal, handleConfirm }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirm();
    setConfirmModal(false);
  };

  return (
    <Background>
      <ModalContainer>
        <ModalTitle>
          <h3>{title} </h3>
        </ModalTitle>
        <Divider></Divider>
        <ModalBody>
          <QuestionButton></QuestionButton>
          <p>{message}</p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={(e) => handleSubmit(e)}>Yakin</Button>
          <Button
            style={{ backgroundColor: "crimson" }}
            onClick={() => setConfirmModal(false)}
          >
            Tidak
          </Button>
        </ModalFooter>
      </ModalContainer>
    </Background>
  );
}

export default ConfirmModal;
