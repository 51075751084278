import React, { useRef } from "react";
import styled from "styled-components";

const StyleImageIdea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  border: 1px solid var(--color-border);
  padding-bottom: 5px;
  h3 {
    background-color: var(--color-primary);
    width: 100%;
    text-align: center;
    color: #fff;
  }
  img {
    width: 100px;
    height: 100px;
    margin: 5px;
  }
  input {
    margin: 5px 0;
    display: none;
  }
  button {
    padding: 2px 15px;
    margin: 0px 5px;
    border-radius: 5px;
    outline: none;
    border: none;
    // border: 1px solid black;
    font-size: var(--font-size-text);
    // color: #fff;
    cursor: pointer;
    background-color: #ffdb58;
  }
`;

const defaultImg = require("../../assets/addimage.png");

const ImageIdea = ({ title, img, handleRemove, ...rest }) => {
  const target = useRef(null);

  const reset = () => {
    target.current.value = null;
    handleRemove();
  };

  return (
    <StyleImageIdea>
      <h3>{title}</h3>
      {img ? <img src={img} alt=""></img> : <img src={defaultImg} alt=""></img>}
      <input ref={target} type="file" accept="image/*" {...rest} />
      <div>
        <button type="button" onClick={() => target.current.click()} {...rest}>
          Ambil
        </button>
        <button type="button" onClick={() => reset()} {...rest}>
          Hapus
        </button>
      </div>
    </StyleImageIdea>
  );
};

export default ImageIdea;
