import Api from "../api/api";

class BeritaServices {
  getBerita = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/berita", config);
  };

  getBeritaById = (id) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/berita/getberitabyid/" + id, config);
  };

  createBerita = (data) => {
    const token = localStorage.getItem("token");
    const configForm = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.post("/berita", data, configForm);
  };

  updateBerita = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.put("/berita", data, config);
  };

  deleteBerita = (id) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.delete("/berita/" + id, config);
  };
}

export default new BeritaServices();
