import Api from "../api/api";

class IdeaServices {
  getAllIdeas = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/idea", config);
  };

  getIdeaById = (id) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/idea/getideaid/" + id, config);
  };

  getIdeaByUser = (nik) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/idea/getideauser/" + nik, config);
  };

  getIdeaByStatus = (status) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/idea/getideastatus/" + status, config);
  };

  getIdeaByStatus2 = (nik, status) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/idea/getideastatus/" + nik + "/" + status, config);
  };

  getIdeaDiterima = (filter) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.post("/idea/getideaditerima", filter, config);
  };

  getIdeaOuts = (filter) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.post("/idea/getideaouts", filter, config);
  };

  createIdea = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.post("/idea", data, config);
  };

  updateIdea = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.put("/idea", data, config);
  };

  deleteIdea = (id) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.delete("/idea/" + id, config);
  };

  getIdeaTotalIde = (tahun, nik) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/idea/getideatotalide/" + tahun + "/" + nik, config);
  };

  getIdeaTotalIde2 = (tahun, nik) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/idea/getideatotalide2/" + tahun + "/" + nik, config);
  };

  getIdeaTotalDiterima = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/idea/getideatotalditerima", config);
  };

  getIdeaTotalIdeCategory = (tahun, nik) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get(
      "/idea/getideatotalidecategory/" + tahun + "/" + nik,
      config
    );
  };

  getIdeaTotalIdeCategory2 = (tahun, nik) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get(
      "/idea/getideatotalidecategory2/" + tahun + "/" + nik,
      config
    );
  };

  getIdeaTotalIdePolicy = (tahun, nik) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/idea/getideatotalidepolicy/" + tahun + "/" + nik, config);
  };

  getIdeaTotalIdePolicy2 = (tahun, nik) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/idea/getideatotalidepolicy2/" + tahun + "/" + nik, config);
  };

  getIdeaTotalIdeKriteria = (tahun, nik) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get(
      "/idea/getideatotalidekriteria/" + tahun + "/" + nik,
      config
    );
  };

  getIdeaTotalIdeKriteria2 = (tahun, nik) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get(
      "/idea/getideatotalidekriteria2/" + tahun + "/" + nik,
      config
    );
  };

  getIdeaTotalIdeRupiah = (tahun, nik) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/idea/getideatotaliderupiah/" + tahun + "/" + nik, config);
  };

  getIdeaTotalIdeRupiah2 = (tahun, nik) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/idea/getideatotaliderupiah2/" + tahun + "/" + nik, config);
  };

  getIdeaTotalIdeQCC = (tahun, nik) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/idea/getideatotalideqcc/" + tahun + "/" + nik, config);
  };

  getIdeaTotalIdeQCCNew = (filter) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.post("/idea/getideatotalideqcc", filter, config);
  };

  getIdeaTotalIdeQCC2 = (tahun, nik) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/idea/getideatotalideqcc2/" + tahun + "/" + nik, config);
  };

  getIdeaPaymentReward = (dari, sampai, site) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get(
      "/idea/getideapaymentreward/" + dari + "/" + sampai + "/" + site,
      config
    );
  };

  getIdeaPaymentRewardExist = (dari, sampai, site) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get(
      "/idea/getideapaymentrewardexist/" + dari + "/" + sampai + "/" + site,
      config
    );
  };

  getIdeaPaymentRewardPGA = (periode, site) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get(
      "/idea/getideapaymentrewardpga/" + periode + "/" + site,
      config
    );
  };

  getIdeaTotalIdeStandar = (filter) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.post("/idea/getideatotalidestandar", filter, config);
  };

  getIdeaByUserDept = (username) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/idea/ideauserdept/" + username, config);
  };

  updateIdeaImage = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.put("/idea/ideaimage", data, config);
  };

  getIdeaTotalIdeBatal = (filter) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.post("/idea/getideatotalidebatal", filter, config);
  };
}

export default new IdeaServices();
