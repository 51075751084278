import React, { useEffect, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import styled from "styled-components";
import Divider from "../element/Divider";
import Button from "../element/Button";

const Background = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 400px;
  height: auto;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
`;

const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 25px 10px 25px;
  color: var(--color-primary);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 25px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 25px 10px 25px;
`;

const TextAreaAutoSize = styled(InputTextarea)`
  resize: none;
  overflow: hidden;
  display: inline;
  width: 100%;
  padding: 5px 8px;
  border: 1px solid silver;
  border-radius: 5px;
  font-size: var(--font-size-text);
  transition: 0.3s;
  outline: none;
`;

const Select = styled.select`
  display: inline;
  width: 100%;
  padding: 5px 8px;
  border: 1px solid silver;
  border-radius: 5px;
  font-size: var(--font-size-text);
  transition: 0.3s;
  outline: none;
`;

const Label = styled.label`
  font-size: 0.9rem;
  font-weight: bold;
`;

const MessageReq = styled.span`
  font-size: 11px;
  color: red;
`;

function CommentModal({
  setStatus,
  setReason,
  setCommentModal,
  handleComment,
}) {
  const [jenis, setJenis] = useState("");
  const [alasan, setAlasan] = useState("");
  const [comment, setComment] = useState("");

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      handleComment();
      setCommentModal(false);
    }
  }, [formErrors, isSubmit]);

  const handleChange = (e) => {
    setReason(e.target.value);
    setComment(e.target.value);
  };

  const jenisHandleChange = (e) => {
    setStatus(e.target.value);
    setJenis(e.target.value);
    setAlasan("");
    setReason("");
    setComment("");
  };

  const alasanHandleChange = (e) => {
    setAlasan(e.target.value);
    if (e.target.value === "Lainnya") {
      setReason("");
      setComment("");
    } else {
      setReason(e.target.value);
      setComment(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate());
    setIsSubmit(true);
  };

  const validate = () => {
    const errors = {};
    if (jenis === "") {
      errors.jenis = "Pilih jenis ditolak!";
    } else {
      if (jenis === "Dibatalkan" && alasan === "") {
        errors.alasan = "Pilih alasan ditolak!";
      }
    }

    if (comment === "") {
      errors.approval = "Alasan harus diisi!";
    }
    return errors;
  };

  return (
    <Background>
      <ModalContainer>
        <ModalTitle>
          <h3>{"Alasan ditolak"} </h3>
        </ModalTitle>
        <Divider></Divider>
        <ModalBody>
          <div>
            <Label>Kategori ditolak</Label>
            <Select
              name="jenis"
              value={jenis}
              onChange={(e) => jenisHandleChange(e)}
            >
              <option value={""} disabled>
                -- Pilih --
              </option>
              <option value={"Ditolak"}>Revisi / bisa diperbaiki</option>
              <option value={"Dibatalkan"}>Batal / Cancel</option>
            </Select>
            <MessageReq>{formErrors.jenis}</MessageReq>
          </div>
          {jenis === "Dibatalkan" ? (
            <>
              <div style={{ marginTop: "5px" }}>
                <Label>Alasan</Label>
                <Select
                  name="alasan"
                  value={alasan}
                  onChange={(e) => alasanHandleChange(e)}
                >
                  <option value={""} disabled>
                    -- Pilih --
                  </option>
                  <option value={"Ide sama / berulang"}>
                    Ide sama / berulang
                  </option>
                  <option
                    value={
                      "Ide sudah masuk dalam standar kerja ( WI / standar lainnya)"
                    }
                  >
                    Ide sudah masuk dalam standar kerja ( WI / standar lainnya)
                  </option>
                  <option value={"Lainnya"}>Lainnya</option>
                </Select>
                <MessageReq>{formErrors.alasan}</MessageReq>
              </div>
              {alasan === "Lainnya" ? (
                <div style={{ marginTop: "5px" }}>
                  <TextAreaAutoSize
                    name="comment"
                    value={comment}
                    onChange={(e) => handleChange(e)}
                    rows={1}
                    autoResize
                  ></TextAreaAutoSize>
                  <MessageReq>{formErrors.approval}</MessageReq>
                </div>
              ) : null}
            </>
          ) : (
            <div style={{ marginTop: "5px" }}>
              <Label>Alasan</Label>
              <TextAreaAutoSize
                name="comment"
                value={comment}
                onChange={(e) => handleChange(e)}
                rows={1}
                autoResize
              ></TextAreaAutoSize>
              <MessageReq>{formErrors.approval}</MessageReq>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button type="primary" onClick={(e) => handleSubmit(e)}>
            Submit
          </Button>
          <Button type="danger" onClick={() => setCommentModal(false)}>
            Batal
          </Button>
        </ModalFooter>
      </ModalContainer>
    </Background>
  );
}

export default CommentModal;
