import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaSearch, FaCircle } from "react-icons/fa";
import { BsChevronRight } from "react-icons/bs";
import FormatData from "../utils/FormatDate";
import LoadingModal from "../modal/LoadingModal";
import MessageModal from "../modal/MessageModal";
import IdeaServices from "../../services/IdeaServices";
import "./MyIdeas.css";
import styled from "styled-components";
import jwt from "jwt-decode";
import { Encrypt } from "../utils/UrlCrypt";

const Status = styled.div`
  padding: 0px 10px;
  margin-right: 5px;
  border-radius: 5px;
`;

const ChevronRightIcon = styled(BsChevronRight)`
  color: #0066cc;
`;

const SearchIcon = styled(FaSearch)`
  margin: 5px 3px 5px 8px;
  pointer-events: none;
`;

const CircleIcon = styled(FaCircle)`
  pointer-events: none;
  font-size: 9px;
  margin-right: 5px;
`;

const MyIdeas = () => {
  const [ideas, setIdeas] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [errors, setErrors] = useState(null);
  const [query, setQuery] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = jwt(token);

    async function getIdeaByUser() {
      setLoadingModal(true);
      try {
        const res = await IdeaServices.getIdeaByUser(user.username);
        // console.log(res);
        setIdeas(res.data.data);
      } catch (err) {
        setErrors(err);
      } finally {
        setLoadingModal(false);
      }
    }
    getIdeaByUser();
  }, []);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        navigate("/login");
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  return (
    <>
      {loadingModal && <LoadingModal></LoadingModal>}
      {messageModal && (
        <MessageModal
          status={true}
          message="Berhasil dihapus"
          setShow={setMessageModal}
        ></MessageModal>
      )}
      <div className="main-container">
        <div className="card-header">
          <h3>Ide Saya</h3>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="left-inner-addon input-container">
              <SearchIcon></SearchIcon>
              {/* <i className="fa fa-search"></i> */}
              <input
                type="search"
                className="form-control"
                placeholder="Pencarian"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <NavLink to="/inputidea">
              <button className="btn btn-primary">+ Baru</button>
            </NavLink>
          </div>
        </div>
        <div className="card-body without-footer">
          <div
            style={{
              maxHeight: "calc(100vh - 142px)",
              overflowY: "auto",
            }}
          >
            {ideas && ideas.length > 0 ? (
              ideas
                .filter((idea) => {
                  if (query === "") {
                    return idea;
                  } else if (
                    idea.status.toLowerCase().includes(query.toLowerCase()) ||
                    idea.noIdea.toLowerCase().includes(query.toLowerCase()) ||
                    (idea.title &&
                      idea.title.toLowerCase().includes(query.toLowerCase())) ||
                    (idea.categoryDesc &&
                      idea.categoryDesc
                        .toLowerCase()
                        .includes(query.toLowerCase()))
                  ) {
                    return idea;
                  }
                })
                .map((idea) => (
                  <div className="idea" key={idea.id}>
                    <div className="idea-icon">
                      <div>
                        <img
                          src={require("../../assets/" + idea.categoryIcon)}
                          alt=""
                        />
                      </div>
                      <h1>{idea.categoryDesc}</h1>
                    </div>
                    <div className="idea-content">
                      <div>
                        <div className="idea-content-noIdea">
                          No. Idea : {idea.noIdea}
                        </div>
                        <div className="idea-content-title">{idea.title}</div>
                        <div className="idea-content-policy">
                          {/* <i className={"fa fa-circle " + idea.policyIcon}></i> */}
                          <div>
                            <CircleIcon
                              style={{ color: idea.policyIcon }}
                            ></CircleIcon>
                          </div>
                          <p>{idea.policyDesc}</p>
                        </div>
                        {idea.kriteria && idea.status === "Diterima" ? (
                          <div>
                            <p style={{ fontSize: "0.8rem", color: "black" }}>
                              {idea.isQCC
                                ? "NILAI " +
                                  idea.kriteria +
                                  " - Bisa dikembangkan jadi tema QCC"
                                : "NILAI " + idea.kriteria}
                            </p>
                          </div>
                        ) : null}

                        <div className="idea-content-date">
                          <Status
                            style={
                              idea.status === "Draft"
                                ? {
                                    color: "#707070",
                                    backgroundColor: "rgb(112, 112, 112, 0.2)",
                                  }
                                : idea.status === "Level 2"
                                ? {
                                    color: "#FFA500",
                                    backgroundColor: "rgb(255, 165, 0, 0.2)",
                                  }
                                : idea.status === "Level 3"
                                ? {
                                    color: "#0000FF",
                                    backgroundColor: "rgb(0, 0, 255, 0.2)",
                                  }
                                : idea.status === "Tinjau Ulang Level 2"
                                ? {
                                    color: "#FF00FF",
                                    backgroundColor: "rgb(255, 0, 255, 0.2)",
                                  }
                                : idea.status === "Diterima"
                                ? {
                                    color: "#008000",
                                    backgroundColor: "rgb(0, 128, 0, 0.2)",
                                  }
                                : idea.status === "Ditolak"
                                ? {
                                    color: "#A52A2A",
                                    backgroundColor: "rgb(165, 42, 42, 0.2)",
                                  }
                                : {
                                    color: "#FF0000",
                                    backgroundColor: "rgb(255, 0, 0, 0.2)",
                                  }
                            }
                          >
                            {idea.status === "Ditolak"
                              ? "Revisi / bisa diperbaiki"
                              : idea.status === "Dibatalkan"
                              ? "Batal / Cancel"
                              : idea.status}
                          </Status>
                          <p>
                            Terakhir Ubah{" "}
                            {FormatData(new Date(idea.updateDate))}{" "}
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              color: `${
                                idea.status === "Ditolak"
                                  ? "#A52A2A"
                                  : "#FF0000"
                              }`,
                              fontSize: "0.8rem",
                            }}
                          >
                            {idea.alasanDitolak}
                          </p>
                        </div>
                      </div>
                      <div>
                        <NavLink
                          to={`/viewidea/${Encrypt(idea.id.toString())}`}
                        >
                          <ChevronRightIcon></ChevronRightIcon>
                          {/* <i className="fas fa-angle-right"></i> */}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <div style={{ textAlign: "center" }}>Tidak ada data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyIdeas;
