import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import Divider from "../element/Divider";
import ConfirmModal from "../modal/ConfirmModal";
import LoadingModal from "../modal/LoadingModal";
import MessageModal from "./MessageModal";
import RoleServices from "../../services/RoleServices";
import SettingServices from "../../services/SettingServices";

const Background = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 300px;
  height: auto;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 394px) {
    width: 100vw;
  }
`;

const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 25px 10px 25px;
  color: var(--color-primary);
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: #474747;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px 25px;
`;

const Label = styled.label`
  font-size: var(--font-size-text);
  font-weight: bold;
  margin: 5px 0px 2px 0px;
`;

const Input = styled.input`
  display: inline;
  width: 100%;
  padding: 5px 8px;
  border: 1px solid silver;
  border-radius: 5px;
  font-size: var(--font-size-text);
  transition: 0.3s;
  outline: none;
  &:focus {
    outline: none;
    border: 1px solid var(--color-primary);
    background-color: #fff;
  }
`;

const Select = styled.select`
  display: inline;
  width: 100%;
  padding: 5px 8px;
  border: 1px solid silver;
  border-radius: 5px;
  font-size: var(--font-size-text);
  transition: 0.3s;
  outline: none;
  &:focus {
    outline: none;
    border: 1px solid var(--color-primary);
    background-color: #fff;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 25px 10px 25px;
`;

const Button = styled.button`
  padding: 5px 15px;
  border-radius: 15px;
  outline: none;
  border: none;
  font-size: var(--font-size-text);
  color: #fff;
  cursor: pointer;
  background-color: var(--color-primary);
  margin: 5px;
`;

const Message = styled.i`
  color: red;
  font-size: 11px;
`;

function UserRoleModal({ setUserModal, id, username, getAllUsers }) {
  const initialValues = {
    id: 0,
    username: "",
    nama: "",
    role: "",
  };

  const [response, setResponse] = useState(null);
  const [msg, setMsg] = useState("");
  const [msgConfirm, setMsgConfirm] = useState("");
  const [errors, setErrors] = useState(null);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [msgModal, setMsgModal] = useState("");
  const [userRole, setUserRole] = useState(null);
  const [listRole, setListRole] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function getRole() {
      try {
        const response = await SettingServices.getRole();
        setListRole(response.data.data);
      } catch (err) {}
    }
    getRole();
  }, []);

  useEffect(() => {
    if (username) {
      async function getUserRoleById() {
        setLoadingModal(true);
        try {
          const response = await RoleServices.getUserRoleByUser(username);
          setUserRole(response.data.data);
        } catch (err) {}
        setLoadingModal(false);
      }
      getUserRoleById();
      setMsgConfirm("Apakah yakin ingin simpan data?");
    }
  }, [username]);

  useEffect(() => {
    if (userRole) {
      setFormValues({
        id: userRole.id,
        username: userRole.username,
        nama: userRole.nama,
        role: userRole.role,
      });
    }
  }, [userRole]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setConfirmModal(true);
    }
  }, [formErrors, isSubmit]);

  useEffect(() => {
    if (errors) {
      navigate("/error");
      localStorage.setItem("path", "/error");
    }
  }, [errors, navigate]);

  useEffect(() => {
    if (response) {
      setMsgModal(true);
      setMsg(response.message);
    }
  }, [response]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const handleConfirm = () => {
    setLoadingModal(true);
    if (id) {
      updateUser();
    } else {
      createUser();
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "Username tidak boleh kosong !";
    }

    if (!values.nama) {
      errors.nama = "Nama tidak boleh kosong !";
    }

    if (!values.role) {
      errors.role = "Role harus dipilih !";
    }

    return errors;
  };

  const createUser = async () => {
    try {
      await RoleServices.createUserRole(formValues);
    } catch (err) {
      setErrors(err);
    } finally {
      setLoadingModal(false);
      setUserModal(false);
      getAllUsers();
    }
  };

  const updateUser = async () => {
    try {
      var res = await RoleServices.updateUserRole(formValues);
      setResponse(res.data);
    } catch (err) {
      setErrors(err);
    } finally {
      setLoadingModal(false);
      setUserModal(false);
      getAllUsers();
    }
  };

  return (
    <Background>
      {confirmModal && (
        <ConfirmModal
          title="Konfirmasi"
          message={msgConfirm}
          setConfirmModal={setConfirmModal}
          handleConfirm={() => handleConfirm()}
        />
      )}
      {loadingModal && <LoadingModal></LoadingModal>}
      {msgModal && (
        <MessageModal
          status={true}
          message={msg}
          setShow={setMsgModal}
        ></MessageModal>
      )}
      <form onSubmit={handleSubmit}>
        <ModalContainer>
          <ModalTitle>
            <h3>{!id ? "Buat User Role" : "Ubah User Role"} </h3>
            <CloseModalButton
              onClick={() => {
                setUserModal(false);
              }}
            ></CloseModalButton>
          </ModalTitle>
          <Divider></Divider>
          <ModalBody>
            <Label>
              Username
              <span style={{ color: "red" }}> *</span>
            </Label>
            <Input
              type="text"
              name="username"
              value={formValues.username}
              placeholder="Masukkan Username"
              onChange={(e) => handleChange(e)}
              readOnly
            ></Input>
            <Message>{formErrors.username}</Message>
            <Label>
              Nama
              <span style={{ color: "red" }}> *</span>
            </Label>
            <Input
              type="text"
              name="nama"
              value={formValues.nama}
              placeholder="Masukkan Nama"
              onChange={(e) => handleChange(e)}
              readOnly
            ></Input>
            <Message>{formErrors.nama}</Message>
            <Label>Role</Label>
            <Select
              name="role"
              value={formValues.role}
              onChange={(e) => handleChange(e)}
              placeholder="Pilih role"
            >
              <option value="">-- Pilih --</option>
              {listRole.map((item) => {
                return (
                  <option key={item.code} value={item.code}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
            <Message>{formErrors.role}</Message>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              style={{ backgroundColor: "crimson" }}
              onClick={() => {
                setUserModal(false);
              }}
            >
              Batal
            </Button>
            <Button type="submit">Simpan</Button>
          </ModalFooter>
        </ModalContainer>
      </form>
    </Background>
  );
}

export default UserRoleModal;
