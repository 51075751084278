import React, { useState } from "react";
import UserModal from "../modal/UserModal";
import PTableUser from "../table/PTableUser";

const UserList = () => {
  const [id, setId] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [isInsert, setIsInsert] = useState(false);

  return (
    <>
      {userModal && (
        <UserModal
          setUserModal={setUserModal}
          id={id}
          // getAllUsers={handleRefresh}
        />
      )}
      <div className="main-container">
        <div className="card-header">
          <h3>Data User</h3>
          <div style={{ display: "flex", alignItems: "center" }}>
            {isInsert && (
              <button
                className="btn btn-primary"
                onClick={() => {
                  setId(null);
                  setUserModal(true);
                }}
              >
                + Baru
              </button>
            )}
          </div>
        </div>
        <div className="card-body without-footer">
          <PTableUser
            setIsInsert={setIsInsert}
            isUserModal={userModal}
          ></PTableUser>
        </div>
      </div>
    </>
  );
};

export default UserList;
