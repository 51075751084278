import React from "react";
import styled from "styled-components";
import Oops from "../../assets/oops.png";

const MainContainer = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const Error = () => {
  return (
    <MainContainer>
      <div>
        <img
          src={Oops}
          alt="Opps"
          style={{ height: "300px", width: "auto", margin: "auto" }}
        ></img>
      </div>

      {/* <h1 style={{ margin: "10px", color: "crimson" }}>Oops!</h1> */}
      <h4>
        Maaf terjadi kesalahan, harap hubungi QS (ext:{" "}
        <span style={{ color: "crimson" }}>305 / 232</span>).
      </h4>
    </MainContainer>
  );
};

export default Error;
