import React from "react";

const HistoryLoginList = () => {
  return (
    <div className="main-container">
      <div className="card-header">
        <h3>List History Login</h3>
      </div>
      <div className="card-body without-footer"></div>
    </div>
  );
};

export default HistoryLoginList;
