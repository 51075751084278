import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { FaExclamationTriangle, FaUser, FaLock } from "react-icons/fa";
import { BsEye, BsEyeSlash, BsExclamationTriangle } from "react-icons/bs";
import LoadingModal from "../modal/LoadingModal";
import AuthService from "../../services/AuthServices";
import { useAuth } from "../auth";
import { useNavigate } from "react-router-dom";
import { Directions } from "@mui/icons-material";

const LoginContainer = styled.div`
  width: 100vw;
  max-width: 780px;
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 1px;
  border: 1px solid var(--color-border);
  border-radius: 10px;
  box-shadow: 1px 3px 10px var(--color-border);
  @media screen and (max-width: 780px) {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 35% 65%;
    border: none;
    box-shadow: none;
  }
`;

const ColImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--color-border);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  img {
    width: 60%;
    height: auto;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    margin: auto;
  }
  @media screen and (max-width: 780px) {
    display: flex;
    border: none;
    img {
      width: auto;
      height: 60%;
      margin: auto;
    }
  }
`;

const ColIinput = styled.div`
  margin: auto;
  padding: 5em 0 3em;
  background-color: #fff;
  h2 {
    color: var(--color-primary);
    margin: 0px 0px 40px;
    font-size: 48px;
  }
  button {
    padding: 5px 20px;
    border-radius: 15px;
    outline: none;
    border: none;
    font-size: 1rem;
    width: 100%;
    color: #fff;
    cursor: pointer;
    background-color: var(--color-primary);
  }
  @media screen and (max-width: 780px) {
    margin-top: 0px;
    padding: 0px;
    background-color: #fff;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  label {
    text-align: start;
    color: var(--color-primary);
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 5px;
  }
`;

const MessageReq = styled.span`
  text-align: start;
  font-size: 12px;
  padding: 3px 0px;
  color: red;
`;

const Exclamation = styled(FaExclamationTriangle)`
  width: 14px;
  height: 14px;
`;

const UserIcon = styled(FaUser)`
  // width: 14px;
  // height: 14px;
  position: absolute;
  margin: 8px;
  pointer-events: none;
  color: gray;
  font-size: 0.9rem;
`;

const LockIcon = styled(FaLock)`
  position: absolute;
  margin: 8px;
  pointer-events: none;
  color: gray;
  font-size: 0.9rem;
`;

const EyeIcon = styled(BsEye)`
  position: absolute;
  right: 0px;
  margin: 8px;
  color: gray;
  cursor: pointer;
`;

const EyeSlashIcon = styled(BsEyeSlash)`
  position: absolute;
  right: 0px;
  margin: 8px;
  color: gray;
  cursor: pointer;
`;

const WarningIcon = styled(BsExclamationTriangle)`
  margin-right: 5px;
  ont-weight: 600;
  color: #ffc300;
  font-size: 0.9rem;
`;

const NotValid = styled.div`
  display: flex;
  padding: 5px 8px;
  border: 1px solid #eb6868;
  border-radius: 5px;
  align-items: center;
  color: #eb6868;
  p {
    margin-left: 5px;
    font-size: 14px;
  }
`;

const LupaPassword = styled.p`
  margin-top: 15px;
  color: var(--color-primary);
  font-size: 0.8rem;
`;

const InputRightLeft = styled.div`
  position: relative;
  label {
    text-align: start;
    color: var(--color-primary);
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 5px;
  }
  input {
    display: inline;
    width: 100%;
    padding-right: 30px !important;
    padding-left: 30px !important;
    border: 1px solid silver;
    border-radius: 5px;
    font-size: 0.9rem;
    transition: 0.3s;
    outline: none;
    &:focus {
      border: 1px solid var(--color-primary);
    }
  }
  i.left {
    position: absolute;
    padding: 8px;
    pointer-events: none;
    color: gray;
    font-size: 0.9rem;
  }
  i.right {
    position: absolute;
    right: 0px;
    padding: 8px;
    color: gray;
    font-size: 0.9rem;
    cursor: pointer;
  }
`;

const Warning = styled.div`
  background-color: #faf5cc;
  border-radius: 10px;
  padding: 10px;
  p {
    text-align: start;
    font-weight: 600;
    color: #d28000;
    font-size: 0.8rem;
  }
`;

const Login = () => {
  const [valid, setValid] = useState(true);
  const [loadingModal, setLoadingModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [response, setResponse] = useState(null);
  const [lihat, setLihat] = useState(false);
  const { login } = useAuth();
  // const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (response) {
      if (response.data) {
        localStorage.setItem("token", response.data);
        login(response.data);
        // window.location.href = "/";
      } else {
        setErrorMessage(response.message);
        setValid(false);
      }
    }
  }, [response]);

  const onSubmit = (data) => {
    setLoadingModal(true);
    async function login() {
      try {
        const response = await AuthService.login(JSON.stringify(data));
        setResponse(response.data);
      } catch (err) {
        setErrorMessage("Oops... Terjadi kesalahan!");
        setValid(false);
      } finally {
        setLoadingModal(false);
      }
    }
    login();
  };

  const handleLihat = () => {
    setLihat(!lihat);
  };

  return (
    <div className="main-center-full">
      {loadingModal && <LoadingModal></LoadingModal>}
      <LoginContainer>
        <ColImg>
          <img src={require("../../assets/logo.png")} alt="" />
        </ColImg>
        <div
          style={{ padding: "2rem", display: "flex", flexDirection: "column" }}
        >
          <ColIinput>
            <h2>Login</h2>
            {!valid && (
              <NotValid>
                <Exclamation></Exclamation>
                <p>{errorMessage}</p>
              </NotValid>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <label>NIK / Username</label>
                <InputRightLeft>
                  {/* <i className="fa-solid fa-user left"></i> */}
                  <UserIcon></UserIcon>
                  <input
                    type="text"
                    {...register("username", { required: true })}
                    placeholder="Masukkan nik / username"
                  />
                </InputRightLeft>
                {errors.username && (
                  <MessageReq>NIK / username harus diisi!</MessageReq>
                )}
              </FormGroup>
              <FormGroup>
                <label>Password</label>
                <InputRightLeft>
                  {/* <i className="fa-solid fa-lock left"></i> */}
                  <LockIcon></LockIcon>
                  {lihat ? (
                    <EyeIcon onClick={handleLihat}></EyeIcon>
                  ) : (
                    <EyeSlashIcon onClick={handleLihat}></EyeSlashIcon>
                  )}
                  {/* <i
                  className={
                    lihat ? (
                      // ? "fa-regular fa-eye right"
                      // : "fa-regular fa-eye-slash right"
                      <EyeIcon></EyeIcon>
                    ) : (
                      <EyeIcon></EyeIcon>
                    )
                  }
                  onClick={handleLihat}
                ></i> */}
                  <input
                    type={lihat ? "text" : "password"}
                    {...register("password", { required: true })}
                    placeholder="Masukkan password"
                  />
                </InputRightLeft>
                {errors.password && (
                  <MessageReq>Password harus diisi!</MessageReq>
                )}
              </FormGroup>
              <button type="submit">Login</button>
              <LupaPassword>Lupa password hubungi Admin</LupaPassword>
            </form>
          </ColIinput>
          <Warning>
            <div style={{ display: "flex" }}>
              <div>
                <WarningIcon />
              </div>
              <p>
                Jika ada perubahan NIK / Departemen / Mutasi / Magang ke Kontrak
                / Kontrak ke Tetap, mohon segera informasi ke QS Dept, untuk
                pembaharuan data.
              </p>
            </div>
          </Warning>
        </div>
      </LoginContainer>
    </div>
  );
};

export default Login;
