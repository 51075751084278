import React, { useState } from "react";

const ModuleList = () => {
  const [display, setDisplay] = useState(false);
  return (
    <div className="main-container">
      <div className="card-header">
        <h3>List Module</h3>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            className="btn btn-primary"
            onClick={() => {
              setDisplay(true);
              // clearForm();
            }}
          >
            + Baru
          </button>
        </div>
      </div>
      <div className="card-body without-footer"></div>
    </div>
  );
};

export default ModuleList;
