import React from "react";
import PTableLapPayRewardPGA from "../table/PTableLapPayRewardPGA";

const LapPayRewardPGA = () => {
  return (
    <div className="main-container">
      <div className="card-header">
        <h4>Laporan Payment Reward PGA</h4>
      </div>
      <div className="card-body without-footer">
        <PTableLapPayRewardPGA></PTableLapPayRewardPGA>
      </div>
    </div>
  );
};

export default LapPayRewardPGA;
