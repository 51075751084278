import styled from "styled-components";
import { FaRegTrashAlt, FaEdit } from "react-icons/fa";
import { Calendar } from "primereact/calendar";

export const Label = styled.label`
  font-size: 0.8rem;
  font-weight: 600;
`;

export const Button = styled.button`
  //   width: ${(props) => (props.secondary ? "130px" : "80px")};
  padding: 5px 15px;
  border-radius: 15px;
  outline: none;
  border: none;
  font-size: var(--font-size-text);
  color: #fff;
  cursor: pointer;
  background-color: var(--color-primary);
  &:hover {
    background-color: transparent;
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
    transition: all 0.3s ease-out;
    padding: 3px 13px;
  }
`;

export const Input = styled.input`
  display: inline;
  width: 100%;
  padding: 5px 8px;
  margin-top: 2px;
  border: 1px solid silver;
  border-radius: 5px;
  font-size: var(--font-size-text);
  transition: 0.3s;
  outline: none;
  &:focus {
    border: 1px solid var(--color-primary);
  }
`;

export const GroupRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GroupCol = styled.div`
  display: flex;
  align-items: center;
`;

export const Tahun = styled(Calendar)`
  // width: auto;
  // padding: 2px 8px;

  &:focus {
    outline: none;
    border: 1px solid var(--color-primary);
    background-color: #fff;
  }

  input {
    font-size: 0.8rem;
    width: auto;
  }
`;

export const TStandarButton = styled.button`
  padding: 5px 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  font-size: var(--font-size-text);
  color: #fff;
  cursor: pointer;
  background-color: var(--color-primary);
  margin: 5px;
`;

export const TDeleteButton = styled(FaRegTrashAlt)`
  cursor: pointer;
  color: crimson;
  width: 15px;
  height: 15px;
  margin: 0 5px;
`;

export const TEditButton = styled(FaEdit)`
  cursor: pointer;
  color: var(--color-primary);
  width: 15px;
  height: 15px;
  margin: 0 5px;
`;
