import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FaRegTrashAlt, FaEdit } from "react-icons/fa";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import styled from "styled-components";
import { UrlImg } from "../../api/apiFile";
import BeritaServices from "../../services/BeritaServices";
import RoleServices from "../../services/RoleServices";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import jwt from "jwt-decode";
import { useNavigate } from "react-router-dom";

const DeleteModalButton = styled(FaRegTrashAlt)`
  cursor: pointer;
  color: crimson;
  width: 15px;
  height: 15px;
  margin: 0 5px;
`;

const EditModalButton = styled(FaEdit)`
  cursor: pointer;
  color: var(--color-primary);
  width: 15px;
  height: 15px;
  margin: 0 5px;
`;

const ButtonSimpan = styled.button`
  padding: 5px 15px;
  border-radius: 15px;
  outline: none;
  border: none;
  font-size: var(--font-size-text);
  color: #fff;
  cursor: pointer;
  background-color: var(--color-primary);
  margin: 5px;
  &:hover {
    background-color: transparent;
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
    transition: all 0.3s ease-out;
    padding: 3px 13px;
  }
`;

const ButtonBatal = styled.button`
  padding: 5px 15px;
  border-radius: 15px;
  outline: none;
  border: none;
  font-size: var(--font-size-text);
  color: #fff;
  cursor: pointer;
  background-color: crimson;
  margin: 5px;
  &:hover {
    background-color: transparent;
    color: crimson;
    border: 2px solid crimson;
    transition: all 0.3s ease-out;
    padding: 3px 13px;
  }
`;

const Label = styled.label`
  font-size: var(--font-size-text);
  font-weight: bold;
  margin: 5px 0px 2px 0px;
`;

const Input = styled.input`
  display: inline;
  width: 100%;
  padding: 5px 8px;
  border: 1px solid silver;
  border-radius: 5px;
  font-size: var(--font-size-text);
  transition: 0.3s;
  outline: none;
  &:focus {
    outline: none;
    border: 1px solid var(--color-primary);
    background-color: #fff;
  }
`;

const Select = styled.select`
  display: inline;
  width: 100%;
  padding: 5px 8px;
  border: 1px solid silver;
  border-radius: 5px;
  font-size: var(--font-size-text);
  transition: 0.3s;
  outline: none;
  &:focus {
    outline: none;
    border: 1px solid var(--color-primary);
    background-color: #fff;
  }
`;

const TextAreaAutoSize = styled(InputTextarea)`
  resize: none;
  overflow: hidden;
  display: inline;
  width: 100%;
  padding: 5px 8px;
  margin-top: 2px;
  border: 1px solid silver;
  border-radius: 5px;
  font-size: var(--font-size-text);
  transition: 0.3s;
  outline: none;
`;

const Message = styled.i`
  color: red;
  font-size: 11px;
`;

const StyleImageIdea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  border: 1px solid var(--color-border);
  padding-bottom: 5px;
  h3 {
    background-color: var(--color-primary);
    width: 100%;
    text-align: center;
    color: #fff;
  }
  img {
    width: auto;
    height: 200px;
    margin: 5px;
  }
  input {
    margin: 5px 0;
    display: none;
  }
  button {
    padding: 2px 15px;
    margin: 0px 5px;
    border-radius: 5px;
    outline: none;
    border: none;
    // border: 1px solid black;
    font-size: var(--font-size-text);
    // color: #fff;
    cursor: pointer;
    background-color: #ffdb58;
  }
`;

const menuCode = "berita";

const Berita = () => {
  const token = localStorage.getItem("token");
  const user = jwt(token);
  const username = user.username;

  const [id, setId] = useState(null);
  const [seq, setSeq] = useState("");
  const [tipe, setTipe] = useState("Teks");
  const [ket, setKet] = useState("");
  const [beritaSrc, setBeritaSrc] = useState(null);
  const [beritaFile, setBeritaFile] = useState(null);

  const [loading, setLoading] = useState(false);
  const [akses, setAkses] = useState(null);
  const [berita, setBerita] = useState(null);
  const [beritaId, setBeritaId] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [errors, setErrors] = useState(null);
  const [response, setResponse] = useState(null);

  const [display, setDisplay] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isInsert, setIsInsert] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const toast = useRef(null);
  const target = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    getRoleAkses();
    getBerita();
  }, []);

  useEffect(() => {
    if (akses) {
      setIsInsert(akses.isInsert);
      setIsUpdate(akses.isUpdate);
      setIsDelete(akses.isDelete);
    }
  }, [akses]);

  useEffect(() => {
    if (id) {
      // console.log(id);
      async function getBeritaById() {
        // setLoadingModal(true);
        try {
          const res = await BeritaServices.getBeritaById(id);
          setBeritaId(res.data.data);
        } catch (err) {
          setErrors(err);
        }
        // setLoadingModal(false);
      }
      getBeritaById();
    }
  }, [id]);

  useEffect(() => {
    if (beritaId) {
      setId(beritaId.id);
      setSeq(beritaId.seq);
      setTipe(beritaId.tipe);
      setKet(beritaId.ket);
      setBeritaSrc(UrlImg + beritaId.beritaSrc);
    }
  }, [beritaId]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (id) {
        confirmUpdate();
      } else {
        confirmNew();
      }
    }
  }, [formErrors, isSubmit]);

  useEffect(() => {
    if (response) {
      if (response.data.status) {
        toast.current.show({
          severity: "success",
          summary: "Berhasil",
          detail: response.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Gagal",
          detail: response.data.message,
          life: 3000,
        });
      }
    }
  }, [response]);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        navigate("/login");
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  const getRoleAkses = async () => {
    try {
      const res = await RoleServices.getUserRoleAkses(username, menuCode);
      setAkses(res.data.data);
    } catch (err) {
      // console.log(err);
      setErrors(err);
    } finally {
      // getAllUsers();
    }
  };

  const getBerita = async () => {
    setLoading(true);
    try {
      const res = await BeritaServices.getBerita();
      setBerita(res.data.data);
    } catch (err) {
      // console.log(err);
      setErrors(err);
    } finally {
      setLoading(false);
    }
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonBatal
          type="button"
          onClick={() => {
            setDisplay(false);
            setId(null);
            setIsSubmit(false);
            setFormErrors({});
          }}
        >
          Batal
        </ButtonBatal>
        <ButtonSimpan type="button" autoFocus onClick={(e) => handleSubmit(e)}>
          Simpan
        </ButtonSimpan>
      </div>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate());
    setIsSubmit(true);
  };

  const validate = () => {
    const regex = /[0-9]|\./;
    const errorsVal = {};
    if (seq === "") {
      errorsVal.seq = "Urutan tidak boleh kosong !";
    } else if (!regex.test(seq)) {
      errorsVal.seq = "Harus diisi dengan angka!";
    }

    if (tipe === "") {
      errorsVal.tipe = "Tipe tidak boleh kosong !";
    }

    if (tipe === "Teks" && ket === "") {
      errorsVal.ket = "Keterangan tidak boleh kosong !";
    }

    if (tipe === "Gambar") {
      if (!beritaSrc) {
        errorsVal.beritaSrc = "Gambar tidak boleh kosong !";
      } else {
        const fileType = beritaFile.type;
        if (!fileType.match(/image/)) {
          errorsVal.beritaSrc = "File harus format gambar!";
        }
      }
    }
    return errorsVal;
  };

  const onImgBerita = (e) => {
    if (e.target.files && e.target.files[0]) {
      let beritaFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setBeritaSrc(x.target.result);
        setBeritaFile(beritaFile);
      };
      reader.readAsDataURL(beritaFile);
    } else {
      setBeritaSrc(null);
      setBeritaFile(null);
    }
  };

  const reset = () => {
    target.current.value = null;
    setBeritaSrc(null);
    setBeritaFile(null);
  };

  const clearForm = () => {
    setId(null);
    setSeq("");
    setTipe("Teks");
    setKet("");
    setBeritaSrc(null);
    setBeritaFile(null);
  };

  const ketTemplate = (rowData) => {
    let ket = rowData.ket;
    if (rowData.tipe === "Gambar") {
      ket = (
        <img
          src={`${UrlImg}${rowData.ket}`}
          onError={(e) => (e.target.src = require("../../assets/addimage.png"))}
          alt={rowData.image}
          className="berita-image"
        />
      );
    }
    return ket;
  };

  const pilihanTemplate = (rowData) => {
    return (
      <div>
        {isUpdate && (
          <EditModalButton
            onClick={(e) => {
              setId(rowData.id);
              setDisplay(true);
            }}
          ></EditModalButton>
        )}
        {isDelete && (
          <DeleteModalButton
            onClick={(e) => {
              confirmDelete(rowData.id);
            }}
          ></DeleteModalButton>
        )}
      </div>
    );
  };

  const confirmDelete = (id) => {
    confirmDialog({
      message: "Apakah yakin ingin delete data?",
      header: "Konfirmasi delete",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Yakin",
      rejectLabel: "Tidak",
      accept: () => deleteBerita(id),
    });
  };

  const deleteBerita = async (id) => {
    try {
      const res = await BeritaServices.deleteBerita(id);
      setResponse(res);
    } catch (err) {
      setErrors(err);
    } finally {
      getBerita();
    }
  };

  const confirmNew = () => {
    confirmDialog({
      message: "Apakah yakin ingin simpan data?",
      header: "Konfirmasi simpan",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Yakin",
      rejectLabel: "Tidak",
      accept: () => createBerita(),
    });
  };

  const createBerita = async () => {
    const formData = new FormData();
    formData.append("id", 0);
    formData.append("seq", seq);
    formData.append("tipe", tipe);
    formData.append("ket", ket);
    formData.append("beritasrc", beritaSrc);
    formData.append("beritafile", beritaFile);
    try {
      const res = await BeritaServices.createBerita(formData);
      setResponse(res);
    } catch (err) {
      setErrors(err);
    } finally {
      setDisplay(false);
      getBerita();
    }
  };

  const confirmUpdate = (id) => {
    confirmDialog({
      message: "Apakah yakin ingin ubah data?",
      header: "Konfirmasi update",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Yakin",
      rejectLabel: "Tidak",
      accept: () => updateBerita(),
    });
  };

  const updateBerita = async () => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("seq", seq);
    formData.append("tipe", tipe);
    formData.append("ket", ket);
    formData.append("beritasrc", beritaSrc);
    formData.append("beritafile", beritaFile);
    try {
      const res = await BeritaServices.updateBerita(formData);
      setResponse(res);
    } catch (err) {
      // console.log(err);
      setErrors(err);
    } finally {
      setDisplay(false);
      getBerita();
    }
  };

  return (
    <div className="main-container">
      <Toast ref={toast} />
      <ConfirmDialog />
      <Dialog
        header="Berita"
        visible={display}
        onHide={() => setDisplay(false)}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "30vw" }}
        footer={renderFooter()}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Label>
            Urutan
            <span style={{ color: "red" }}> *</span>
          </Label>
          <Input
            type="text"
            name="seq"
            value={seq}
            placeholder="Masukkan urutan berita"
            onChange={(e) => setSeq(e.target.value)}
          ></Input>
          <Message>{formErrors.seq}</Message>
          <Label>Tipe</Label>
          <Select
            name="tipe"
            value={tipe}
            onChange={(e) => setTipe(e.target.value)}
            placeholder="Pilih tipe"
          >
            <option value="Teks">Teks</option>
            <option value="Gambar">Gambar</option>
          </Select>
          <Message>{formErrors.tipe}</Message>
          {tipe === "Teks" ? (
            <>
              <Label>
                Keterangan
                <span style={{ color: "red" }}> *</span>
              </Label>
              <TextAreaAutoSize
                name="ket"
                value={ket}
                onChange={(e) => setKet(e.target.value)}
                placeholder="Masukkan keterangan berita"
                rows={2}
                autoResize
              />
              <Message>{formErrors.ket}</Message>
            </>
          ) : (
            <>
              <StyleImageIdea>
                <img
                  src={
                    beritaSrc ? beritaSrc : require("../../assets/addimage.png")
                  }
                  alt=""
                  onError={(e) =>
                    (e.target.src = require("../../assets/addimage.png"))
                  }
                ></img>
                <input
                  ref={target}
                  type="file"
                  accept="image/*"
                  onChange={(e) => onImgBerita(e)}
                  hidden
                />
                <div>
                  <button type="button" onClick={() => target.current.click()}>
                    Ambil
                  </button>
                  <button type="button" onClick={() => reset()}>
                    Hapus
                  </button>
                </div>
              </StyleImageIdea>
              <Message>{formErrors.beritaSrc}</Message>
            </>
          )}
        </div>
      </Dialog>
      <div className="card-header">
        <h3>List Berita</h3>
        {isInsert && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              className="btn btn-primary"
              onClick={() => {
                setDisplay(true);
                clearForm();
              }}
            >
              + Baru
            </button>
          </div>
        )}
      </div>
      <div className="card-body without-footer">
        <div
          style={{
            maxHeight: "calc(100vh - 142px)",
            overflowY: "auto",
          }}
        >
          <DataTable
            value={berita}
            scrollable
            loading={loading}
            responsiveLayout="scroll"
            removableSort
            className="header-class-report"
            rowClassName="row-class-report"
            emptyMessage="Data tidak ditemukan"
          >
            <Column
              field="seq"
              header="Urutan"
              style={{ minWidth: "90px", maxWidth: "90px" }}
              sortable
            ></Column>
            <Column
              field="tipe"
              header="Tipe"
              style={{ minWidth: "110px", maxWidth: "110px" }}
              sortable
              filter
              filterPlaceholder="Cari by tipe"
              filterMatchMode="contains"
              showFilterOperator={false}
            ></Column>
            <Column
              field="ket"
              header="Keterangan"
              body={ketTemplate}
              style={{ minWidth: "300px" }}
              sortable
            ></Column>
            <Column
              header="Pilihan"
              style={{ minWidth: "90px", maxWidth: "100px" }}
              body={pilihanTemplate}
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default Berita;
