import Api from "../api/api";

class RoleServices {
  getUserRole = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/role", config);
  };

  getUserRoleById = (id) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/role/getroleuserroleid/" + id, config);
  };

  getUserRoleByUser = (username) => {
    return Api.get("/role/getroleuserroleuser/" + username);
  };

  createUserRole = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.post("/role", data, config);
  };

  updateUserRole = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.put("/role", data, config);
  };

  deleteUserRole = (id) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.delete("/role/" + id, config);
  };

  getUserRoleAkses = (username, menucode) => {
    return Api.get("/role/getroleuserroleakses/" + username + "/" + menucode);
  };
}

export default new RoleServices();
