import React, { useEffect, useState, useCallback } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { FaCaretDown, FaTimes, FaBars } from "react-icons/fa";
import "./Navbar.css";
// import Dropdown from "../element/DropdownMenu";
import styled from "styled-components";
// import ModuleServices from "../../services/ModuleServices";
import { useAuth } from "../auth";

const SignOut = styled.button`
  padding: 6px 18px;
  background-color: transparent;
  color: #fff;
  border-radius: 5px;
  border: 2px solid #1888ff;
  font-weight: bold;
  font-size: 20px;
  &:hover {
    padding: 8px 20px;
    border-radius: 5px;
    outline: none;
    border: none;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    background-color: #1888ff;
    transition: all 0.3s ease-out;
  }
  @media screen and (max-width: 780px) {
    display: none;
  }
`;

const TimesIcon = styled(FaTimes)`
  color: #fff;
`;

const BarsIcon = styled(FaBars)`
  color: #fff;
`;

function Navbar() {
  const navigate = useNavigate();
  const [click, setClick] = useState(false);
  // const [dropdown, setdropdown] = useState(false);
  // const [menuItems, setMenuItems] = useState(null);
  const { logout } = useAuth();

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  useEffect(() => {
    // if (!auth.user) {
    //   const user = JSON.parse(localStorage.getItem("user"));
    //   async function getModuleUser() {
    //     try {
    //       const res = await ModuleServices.getModuleByUser(user.username);
    //       setMenuItems(res.data.data);
    //     } catch (err) {
    //       console.log(err);
    //     } finally {
    //     }
    //   }
    //   getModuleUser();
    // }
  }, []);

  const handleLogout = () => {
    logout();
    // localStorage.clear();
    // navigate("/login");
  };

  // const handleLogout = useCallback(
  //   (e) => {
  //     // e.preventDefault();
  //     // setUser(null);
  //     // navigate("/");
  //     auth.logout();
  //     localStorage.clear();
  //     navigate("/login");
  //   },
  //   [auth]
  // );

  // const onMouseEnter = () => {
  //   if (window.innerWidth < 780) {
  //     setdropdown(false);
  //   } else {
  //     setdropdown(true);
  //   }
  // };

  // const onMouseLeave = () => {
  //   setdropdown(false);
  // };

  return (
    <>
      <nav className="navbar">
        <NavLink to="/" className="navbar-logo">
          QCC - PIP
        </NavLink>
        <div className="menu-icon" onClick={handleClick}>
          {click ? <TimesIcon></TimesIcon> : <BarsIcon></BarsIcon>}
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <NavLink to="/" className="nav-links" onClick={closeMobileMenu}>
              Beranda
            </NavLink>
          </li>
          <li
            className="nav-item"
            // onMouseEnter={onMouseEnter}
            // onMouseLeave={onMouseLeave}
          >
            <NavLink
              to={"/menu"}
              className="nav-links"
              onClick={closeMobileMenu}
            >
              {/* Menu <i className="fas fa-caret-down" /> */}
              Menu <FaCaretDown></FaCaretDown>
            </NavLink>
            {/* {dropdown && <Dropdown menuItems={menuItems}></Dropdown>} */}
          </li>
          <li className="nav-item">
            <NavLink
              to="profile"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Akun
            </NavLink>
          </li>
          <li className="nav-item">
            <div className="nav-links-mobile" onClick={handleLogout}>
              Keluar
            </div>
          </li>
        </ul>
        <SignOut onClick={handleLogout}>Keluar</SignOut>
      </nav>
      <Outlet />
    </>
  );
}

export default Navbar;
