import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Divider from "../element/Divider";
import UserServices from "../../services/UserServices";
import { useNavigate } from "react-router-dom";
import jwt from "jwt-decode";

const Background = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 500px;
  height: auto;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
`;

const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 25px 10px 25px;
  color: var(--color-primary);
`;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 25px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 25px 10px 25px;
`;

const Button = styled.button`
  padding: 5px 15px;
  border-radius: 15px;
  outline: none;
  border: none;
  font-size: var(--font-size-text);
  color: #fff;
  cursor: pointer;
  background-color: var(--color-primary);
  margin: 5px;
`;

const Select = styled.select`
  display: inline;
  width: 100%;
  padding: 5px 8px;
  margin-top: 5px;
  border: 1px solid silver;
  border-radius: 5px;
  font-size: var(--font-size-text);
  transition: 0.3s;
  outline: none;
`;

const Label = styled.label`
  font-size: 0.9rem;
  font-weight: bold;
  margin: 5px 10px 0px 10px;
`;

const MessageReq = styled.span`
  font-size: 11px;
  color: red;
`;

function ApprovalModal({
  dept,
  level,
  setApprove,
  setApprovalModal,
  handleConfirm,
}) {
  const [approval, setApproval] = useState("");
  const [listUsers, setListUsers] = useState(null);
  const [errors, setErrors] = useState(null);

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const user = jwt(token);
  const username = user.username;

  useEffect(() => {
    async function getAllUsers() {
      try {
        const response = await UserServices.getUserApproval(username);
        setListUsers(response.data.data);
      } catch (err) {
        setErrors(err);
      } finally {
      }
    }
    getAllUsers();
  }, [username]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      handleConfirm();
      setApprovalModal(false);
    }
  }, [formErrors, isSubmit]);

  useEffect(() => {
    if (errors) {
      navigate("/error");
    }
  }, [errors, navigate]);

  const handleChange = (e) => {
    setApproval(e.target.value);
    setApprove(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate());
    setIsSubmit(true);
  };

  const validate = () => {
    const errors = {};
    if (approval === "") {
      errors.approval = "Nama harus dipilih!";
    }
    return errors;
  };

  return (
    <Background>
      <ModalContainer>
        <ModalTitle>
          <h3>{"Approve / Menyetujui"} </h3>
        </ModalTitle>
        <Divider></Divider>
        <ModalBody>
          <Label>Nama</Label>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Select
              name="approval"
              value={approval}
              onChange={(e) => handleChange(e)}
            >
              <option value={""}>-- Pilih --</option>
              {listUsers &&
                listUsers.map((user) => {
                  return (
                    <option key={user.id} value={user.username}>
                      {user.username} - {user.nama}
                    </option>
                  );
                })}
            </Select>
            <MessageReq>{formErrors.approval}</MessageReq>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={(e) => handleSubmit(e)}>Submit</Button>
          <Button
            style={{ backgroundColor: "crimson" }}
            onClick={() => setApprovalModal(false)}
          >
            Batal
          </Button>
        </ModalFooter>
      </ModalContainer>
    </Background>
  );
}

export default ApprovalModal;
