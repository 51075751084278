import React, { useEffect, useState, useRef } from "react";
import configData from "../../config/config.json";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FaChevronCircleRight, FaChevronCircleDown } from "react-icons/fa";
import { MdCameraAlt } from "react-icons/md";
import styledC from "styled-components";
import AuthServices from "../../services/AuthServices";
import UserServices from "../../services/UserServices";
import LoadingModal from "../modal/LoadingModal";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Button from "../element/Button";
import { UrlFoto } from "../../api/apiFile";
import jwt from "jwt-decode";

const GridContainer = styledC.div`
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 20px;
  padding: 0px 50px;
  @media screen and (max-width: 780px) {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
`;

const ColContent = styledC.div`
  display: flex;
  border: 1px solid var(--color-border);
  box-shadow: 1px 3px 10px var(--color-border);
  border-radius: 10px;
  background-color: white;
  flex-direction: column;
`;

const CardHeader = styledC.div`
  display: flex;
  align-items: center;
  height: 50px;
  background-color: var(--color-primary);
  color: #ffff;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0px 20px;
`;

const CardHeader2 = styledC.div`
  display: flex;
  align-items: center;
  height: 50px;
  color: var(--color-primary);
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  padding: 0px 20px;
  font-weight: bold;
`;

const CardBody = styledC.div`
  display: flex;
  padding: 15px 20px;
  justify-content: space-betweenter;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  cursor: pointer;
  color: #454545;
`;

const ChevronRight = styledC(FaChevronCircleRight)`
  width: 20px;
  height: 20px;
  color: var(--color-primary);
  margin-right: 10px;
`;

const ChevronDown = styledC(FaChevronCircleDown)`
  width: 20px;
  height: 20px;
  color: var(--color-primary);
  margin-right: 10px;
`;

const CCamera = styledC(MdCameraAlt)`
border: 1px Solid #fff;
border-radius: 50%;
width: 25px;
height: 25px;
padding: 2px;
background-color: #fff;
cursor: pointer;
`;

const FormGroup = styledC.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  padding: 5px;
  label {
    text-align: start;
    color: var(--color-primary);
    font-size: var(--font-size-text);
    font-weight: 600;
  }
  input {
    display: block;
    width: 100%;
    padding: 5px 0px;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid silver;
    background-color: #fff;
    font-size: var(--font-size-text);
    transition: border-color 0.15s;
    &:focus {
      outline: none;
      border-bottom: 1px solid var(--color-primary);
      background-color: #fff;
    }
  }
`;

const MessageReq = styledC.span`
  text-align: start;
  font-size: 12px;
  padding: 3px 0px;
  color: red;
`;

const GroupControl = styledC.div`
display: flex;
`;

const defaultImg = require("../../assets/avatar.png");

const Profile = () => {
  const token = localStorage.getItem("token");
  const user = jwt(token);

  const navigate = useNavigate();

  const [fotoUser, setFotoUser] = useState(null);
  const [fotoName, setFotoName] = useState("");
  const [fotoSrc, setFotoSrc] = useState("");
  const [fotoFile, setFotoFile] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [isAkun, setIsAkun] = useState(true);
  const [isGantiPassword, setIsGantiPassword] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [response, setResponse] = useState(null);
  const [errorsApi, setErrorsApi] = useState(null);
  const [makFoto, setMakFoto] = useState(null);

  const target = useRef();
  const toast = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const newpassword = useRef({});
  newpassword.current = watch("newpassword", "");

  useEffect(() => {
    async function getUserFoto() {
      try {
        const res = await UserServices.getUserFoto(user.username);
        setFotoUser(res.data.data);
      } catch (err) {
        // console.log(err);
        setErrorsApi(err);
      } finally {
      }
    }
    getUserFoto();
  }, []);

  useEffect(() => {
    if (fotoUser) {
      setFotoName(fotoUser.fotoName);
      setFotoSrc(fotoUser.fotoSrc && UrlFoto + fotoUser.fotoSrc);
    }
  }, [fotoUser]);

  useEffect(() => {
    if (response) {
      if (response.status) {
        toast.current.show({
          severity: "success",
          summary: "Berhasil",
          detail: response.message,
          life: 3000,
        });
        reset({ password: "", newpassword: "", repassword: "" });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Gagal",
          detail: response.message,
          life: 3000,
        });
      }
    }
  }, [response]);

  useEffect(() => {
    if (errorsApi) {
      console.log(errorsApi.response);
      if (errors.response !== undefined && errorsApi.response.status === 401) {
        navigate("/login");
      } else {
        navigate("/error");
      }
    }
  }, [errorsApi]);

  const handleMenuAkun = () => {
    setIsAkun(true);
    setIsGantiPassword(false);
  };

  const handleMenuGanti = () => {
    setIsGantiPassword(true);
    setIsAkun(false);
    setIsEdit(false);
    reset({});
  };

  const onSubmit = (data) => {
    const values = { username: user.username, ...data };
    setLoadingModal(true);
    async function login() {
      try {
        const response = await AuthServices.changePassword(
          JSON.stringify(values)
        );
        setResponse(response.data);
      } catch (err) {
        // console.log(err);
        setErrorsApi(err);
      } finally {
        setLoadingModal(false);
      }
    }
    login();
  };

  const handelChangeProfile = (e) => {
    if (e.target.files && e.target.files[0]) {
      let fotoFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setFotoFile(fotoFile);
        setFotoSrc(x.target.result);
      };
      reader.readAsDataURL(fotoFile);
      setIsEdit(true);
    }
  };

  const handelUbahFoto = () => {
    setMakFoto(null);
    if (fotoSrc) {
      const file = Math.round(fotoFile.size / 1024);
      const fileType = fotoFile.type;
      if (!fileType.match(/image/)) {
        setMakFoto("File harus format gambar!");
      } else if (file > configData.MaxImgFile) {
        setMakFoto("Foto maksimal 3MB!");
      } else {
        const formData = new FormData();
        formData.append("username", user.username);
        formData.append("fotoName", fotoName);
        formData.append("fotoSrc", fotoSrc);
        formData.append("fotoFile", fotoFile);
        updateUserFoto(formData);
      }
    }
  };

  const handelBatalUbah = () => {
    setIsEdit(false);
    if (fotoName) {
      setFotoSrc(UrlFoto + fotoName);
    } else setFotoSrc(defaultImg);
    target.current.value = null;
    setMakFoto(null);
  };

  const updateUserFoto = async (formData) => {
    setLoadingModal(true);
    try {
      const res = await UserServices.updateUserFoto(formData);
      setResponse(res.data);
    } catch (err) {
      setErrorsApi(err);
    } finally {
      setLoadingModal(false);
      setIsEdit(false);
    }
  };

  return (
    <div className="main-container">
      {loadingModal && <LoadingModal></LoadingModal>}
      <Toast ref={toast} />
      <GridContainer>
        <div>
          <ColContent>
            <CardHeader>PENGATURAN</CardHeader>
            <CardBody onClick={(e) => handleMenuAkun()}>
              {isAkun ? (
                <ChevronDown></ChevronDown>
              ) : (
                <ChevronRight></ChevronRight>
              )}
              Akun
            </CardBody>
            <CardBody onClick={(e) => handleMenuGanti()}>
              {isGantiPassword ? (
                <ChevronDown></ChevronDown>
              ) : (
                <ChevronRight></ChevronRight>
              )}
              Ganti Password
            </CardBody>
          </ColContent>
        </div>
        {isAkun && (
          <ColContent>
            <CardHeader2>Akun</CardHeader2>
            <div style={{ margin: "20px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                  color: "var(--color-primary)",
                }}
              >
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <CCamera onClick={() => target.current.click()}></CCamera>
                  }
                >
                  <Avatar
                    style={{
                      border: "1px solid rgb(0, 102, 153, 0.5)",
                    }}
                    sx={{ height: "100px", width: "100px" }}
                    shape="circle"
                  >
                    <Image
                      src={fotoSrc ? fotoSrc : defaultImg}
                      alt="Foto"
                      preview={fotoSrc ? true : false}
                      onError={(e) => (e.target.src = defaultImg)}
                      width="100"
                      // imageStyle={{
                      //   objectFit: "cover",
                      //   height: "auto",
                      //   maxWidth: "100%",
                      // }}
                    />
                  </Avatar>
                </Badge>
                <MessageReq>{makFoto}</MessageReq>
                {isEdit && (
                  <GroupControl>
                    <Button type="primary" onClick={() => handelUbahFoto()}>
                      Ubah
                    </Button>
                    <Button type="danger" onClick={() => handelBatalUbah()}>
                      Batal
                    </Button>
                  </GroupControl>
                )}

                <input
                  ref={target}
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => handelChangeProfile(e)}
                />
                <h2>{user.nama.toUpperCase()}</h2>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  justifyContent: "start",
                  columnGap: "20px",
                  rowGap: "5px",
                }}
              >
                {user.status === "Admin" ? <p>Username</p> : <p>NIK</p>}
                <p>: {user.username}</p>
                <p>Dept</p>
                <p>
                  :
                  {" " +
                    user.dept +
                    (user.site === "TSC3A"
                      ? "3A"
                      : user.site === "TSC3B"
                      ? "3B"
                      : "")}
                </p>
                <p>Factory</p>
                <p>: {user.site === "TSC1" ? "TNG" : "CKR"}</p>
                <p>Level</p>
                <p>: {user.level}</p>
              </div>
            </div>
          </ColContent>
        )}
        {isGantiPassword && (
          <ColContent>
            <CardHeader2>Ganti Password</CardHeader2>
            <div style={{ margin: "0px 25px 25px 25px" }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <label>Password Lama</label>
                  <input
                    type="password"
                    {...register("password", {
                      required: "Password lama harus diisi!",
                    })}
                    placeholder="Masukkan password lama"
                  ></input>
                  {errors.password && (
                    <MessageReq>{errors.password.message}</MessageReq>
                  )}
                </FormGroup>
                <FormGroup>
                  <label>Password Baru</label>
                  <input
                    type="password"
                    {...register("newpassword", {
                      required: "Password baru harus diisi!",
                      minLength: {
                        value: 8,
                        message: "Password minimal 8 karakter!",
                      },
                      validate: (value) => {
                        return (
                          [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/].every(
                            (pattern) => pattern.test(value)
                          ) ||
                          "Password kombinasi angka, karakter, huruf besar & kecil!"
                        );
                      },
                    })}
                    placeholder="Masukkan password baru"
                  ></input>
                  {errors.newpassword && (
                    <MessageReq>{errors.newpassword.message}</MessageReq>
                  )}
                </FormGroup>
                <FormGroup className="form-group">
                  <label>Ulangi Password Baru</label>
                  <input
                    type="password"
                    {...register("repassword", {
                      required: true,
                      validate: (value) =>
                        value === newpassword.current ||
                        "Ulangi password baru harus sama dengan password baru!",
                    })}
                    placeholder="Ulangi password baru"
                  ></input>
                  {errors.repassword &&
                    errors.repassword.type === "required" && (
                      <MessageReq>Ulangi password baru harus diisi!</MessageReq>
                    )}
                  {errors.repassword && (
                    <MessageReq>{errors.repassword.message}</MessageReq>
                  )}
                </FormGroup>
                <button type="submit" className="btn">
                  Submit
                </button>
              </form>
            </div>
          </ColContent>
        )}
      </GridContainer>
    </div>
  );
};

export default Profile;
