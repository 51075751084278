import styled from "styled-components";

export default styled.button`
  padding: 5px 15px;
  border-radius: 15px;
  outline: none;
  border: none;
  font-size: var(--font-size-text);
  color: #fff;
  cursor: pointer;
  margin: 5px;
  background-color: ${(props) => {
    switch (props.type) {
      case "primary":
        return "var(--color-primary)";
      case "secondary":
        return "grey";
      case "danger":
        return "var(--color-danger)";
      default:
        return "#FFF";
    }
  }};
  &:hover {
    background-color: ${(props) => {
      switch (props.type) {
        case "primary":
          return "rgb(0, 102, 153, 0.5)";
        case "secondary":
          return "rgb(128,128,128, 0.5)";
        case "danger":
          return "rgb(255, 0, 0, 0.5)";
        default:
          return "#FFF";
      }
    }};
    transition: all 0.3s ease-out;
  }
`;
