import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import PTableIdeaDiterima from "../table/PTableIdeaDiterima";
import PTableIdeaOuts from "../table/PTableIdeaOuts";

const IdeaList = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="main-container">
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Ide Diterima">
          <PTableIdeaDiterima></PTableIdeaDiterima>
        </TabPanel>
        <TabPanel header="Ide Outstanding">
          <PTableIdeaOuts></PTableIdeaOuts>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default IdeaList;
