import React from "react";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import configData from "../../config/config.json";
import ImageIdea from "../element/ImageIdea";
import DocumentIdea from "../element/DocumentIdea";
import FormatDate from "../utils/FormatDate";
// import { PolicyList } from "../parameter/PolicyList";
// import { CategoryList } from "../parameter/CategoryList";
import styled from "styled-components";
import ConfirmModal from "../modal/ConfirmModal";
import LoadingModal from "../modal/LoadingModal";
import ApprovalModal from "../modal/ApprovalModal";
import IdeaServices from "../../services/IdeaServices";
import SettingServices from "../../services/SettingServices";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { UrlImg } from "../../api/apiFile";
import jwt from "jwt-decode";
import { Decrypt } from "../utils/UrlCrypt";

const MessageReq = styled.span`
  font-size: 11px;
  color: red;
`;

const TextArea = styled.textarea`
  resize: none;
  overflow: hidden;
  display: inline;
  width: 100%;
  padding: 5px 8px;
  margin-top: 2px;
  border: 1px solid silver;
  border-radius: 5px;
  font-size: var(--font-size-text);
  transition: 0.3s;
  outline: none;
`;

const TextAreaAutoSize = styled(InputTextarea)`
  resize: none;
  overflow: hidden;
  display: inline;
  width: 100%;
  padding: 5px 8px;
  margin-top: 2px;
  border: 1px solid silver;
  border-radius: 5px;
  font-size: var(--font-size-text);
  transition: 0.3s;
  outline: none;
`;

const Group = styled.div`
  display: flex;
  justify-content: space-between;
  // align-items: stretch;
`;

const FormgGroup = styled.div`
  display: flex;
  div {
    flex: 50%;
  }
  @media screen and (max-width: 970px) {
    flex-direction: column;
    gap: 5px;
  }
`;

const MaxImg = configData.MaxImgFile;
const defaultConf = require("../../assets/confidential.png");

const InputIdea = () => {
  const token = localStorage.getItem("token");
  const user = jwt(token);
  const dept =
    user.dept +
    (user.site === "TSC3A" ? "3A" : user.site === "TSC3B" ? "3B" : "");

  const { id } = useParams();
  const [idea, setIdea] = useState(null);
  const [approve, setApprove] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [response, setResponse] = useState(null);
  const [errors, setErrors] = useState(null);

  const navigate = useNavigate();

  const initialValues = {
    id: 0,
    date: new Date(),
    noidea: "",
    nik: user.username,
    name: user.nama,
    site: user.site,
    dept: dept,
    category: "",
    categorydesc: "",
    categoryicon: "",
    policy: "",
    policydesc: "",
    policyicon: "",
    title: "",
    beforeidea: "",
    suggestion: "",
    location: "",
    afteridea: "",
    savemonth: "",
    savetime: "",
    status: "",
    approve1: "",
    beforeimgname: "",
    afterimgname: "",
    standarimgname: "",
    beforeimgsrc: "",
    afterimgsrc: "",
    standarimgsrc: "",
    beforeisconf: false,
    afterisconf: false,
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [category, setCategory] = useState([]);
  const [policy, setPolicy] = useState([]);

  const toast = useRef(null);

  const getCategory = async () => {
    try {
      const res = await SettingServices.getCategory();
      setCategory(res.data.data);
    } catch (err) {
      setErrors(err);
    }
  };

  const getPolicy = async () => {
    try {
      const res = await SettingServices.getPolicy();
      setPolicy(res.data.data);
    } catch (err) {
      setErrors(err);
    }
  };

  useEffect(() => {
    getCategory();
    getPolicy();
  }, []);

  useEffect(() => {
    if (id) {
      async function getIdeaById() {
        setLoadingModal(true);
        try {
          const response = await IdeaServices.getIdeaById(Decrypt(id));
          setIdea(response.data.data);
        } catch (err) {
          setErrors(err);
        }
        setLoadingModal(false);
      }
      getIdeaById();
    }
  }, [id]);

  useEffect(() => {
    if (idea) {
      setFormValues({
        id: idea.id,
        date: idea.date,
        noidea: idea.noIdea,
        nik: idea.nik,
        name: idea.name,
        site: idea.site,
        dept: idea.dept,
        title: idea.title ? idea.title : "",
        category: idea.category ? idea.category : "",
        categorydesc: idea.categoryDesc ? idea.categoryDesc : "",
        categoryicon: idea.categoryIcon ? idea.categoryIcon : "",
        policy: idea.policy ? idea.policy : "",
        policydesc: idea.policyDesc ? idea.policyDesc : "",
        policyicon: idea.policyIcon ? idea.policyIcon : "",
        beforeidea: idea.beforeIdea ? idea.beforeIdea : "",
        suggestion: idea.suggestion ? idea.suggestion : "",
        location: idea.location ? idea.location : "",
        afteridea: idea.afterIdea ? idea.afterIdea : "",
        savemonth: idea.saveMonth ? idea.saveMonth : "",
        savetime: idea.saveTime ? idea.saveTime : "",
        approve1: idea.approve1 ? idea.approve1 : "",
        beforeimgname: idea.beforeImgName ? idea.beforeImgName : "",
        afterimgname: idea.afterImgName ? idea.afterImgName : "",
        standarimgname: idea.standarImgName ? idea.standarImgName : "",
        beforeimgsrc: idea.beforeImgSrc && UrlImg + idea.beforeImgSrc,
        afterimgsrc: idea.afterImgSrc && UrlImg + idea.afterImgSrc,
        standarimgsrc: idea.standarImgSrc && UrlImg + idea.standarImgSrc,
        beforeisconf: idea.beforeIsConf,
        afterisconf: idea.afterIsConf,
      });
    }
  }, [idea]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0) {
      if (isSubmit) {
        setApprovalModal(true);
      } else if (isDraft) {
        setConfirmModal(true);
      }
    }
  }, [formErrors, isSubmit, isDraft]);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        navigate("/login");
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  useEffect(() => {
    setFormValues({ ...formValues, approve1: approve });
  }, [approve]);

  useEffect(() => {
    if (response) {
      if (response.status) {
        toast.current.show({
          severity: "success",
          summary: "Berhasil",
          detail: response.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Gagal",
          detail: response.message,
          life: 3000,
        });
      }
    }
  }, [response]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    handleAutoHeight(e);
  };

  const handleChangeCategory = (e) => {
    const { name, value } = e.target;
    const listCategory = category.filter(function (category) {
      return category.code === value;
    })[0];
    listCategory
      ? setFormValues({
          ...formValues,
          [name]: value,
          categorydesc: listCategory.desc,
          categoryicon: listCategory.icon,
        })
      : setFormValues({
          ...formValues,
          [name]: value,
          categorydesc: "",
          categoryicon: "",
        });
  };

  const handleChangePolicy = (e) => {
    const { name, value } = e.target;
    const listPolicy = policy.filter(function (policy) {
      return policy.code === value;
    })[0];
    setFormValues({
      ...formValues,
      [name]: value,
      policydesc: listPolicy.desc,
      policyicon: listPolicy.icon,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    setIsDraft(false);
    setFormValues({
      ...formValues,
      status: "Level 2",
    });
  };

  const handleDraft = (e) => {
    e.preventDefault();
    setFormErrors(validateDraft(formValues));
    setIsDraft(true);
    setIsSubmit(false);
    setFormValues({
      ...formValues,
      status: "Draft",
    });
  };

  const handleApprove = () => {
    setConfirmModal(true);
  };

  const handleConfirm = () => {
    const formData = new FormData();
    formData.append("id", formValues.id);
    formData.append("noidea", formValues.noidea);
    formData.append("nik", formValues.nik);
    formData.append("name", formValues.name);
    formData.append("site", formValues.site);
    formData.append("dept", formValues.dept);
    formData.append("title", formValues.title);
    formData.append("category", formValues.category);
    formData.append("categorydesc", formValues.categorydesc);
    formData.append("categoryicon", formValues.categoryicon);
    formData.append("policy", formValues.policy);
    formData.append("policydesc", formValues.policydesc);
    formData.append("policyicon", formValues.policyicon);
    formData.append("beforeidea", formValues.beforeidea);
    formData.append("suggestion", formValues.suggestion);
    formData.append("location", formValues.location);
    formData.append("afteridea", formValues.afteridea);
    formData.append("savemonth", formValues.savemonth);
    formData.append("savetime", formValues.savetime);
    formData.append("status", formValues.status);
    formData.append("approve1", formValues.approve1);
    formData.append("beforeimgfile", formValues.beforeimgfile);
    formData.append("afterimgfile", formValues.afterimgfile);
    formData.append("standarimgfile", formValues.standarimgfile);
    formData.append("beforeimgname", formValues.beforeimgname);
    formData.append("afterimgname", formValues.afterimgname);
    formData.append("standarimgname", formValues.standarimgname);
    formData.append("beforeimgsrc", formValues.beforeimgsrc);
    formData.append("afterimgsrc", formValues.afterimgsrc);
    formData.append("standarimgsrc", formValues.standarimgsrc);
    formData.append("beforeisconf", formValues.beforeisconf);
    formData.append("afterisconf", formValues.afterisconf);
    if (id) {
      updateIdea(formData);
    } else {
      formData.append("date", formValues.date.toUTCString());
      createIdea(formData);
    }
  };

  const updateIdea = async (formData) => {
    setLoadingModal(true);
    try {
      const res = await IdeaServices.updateIdea(formData);
      setResponse(res.data);
    } catch (err) {
      setErrors(err);
      // console.log(err);
    } finally {
      setLoadingModal(false);
    }
  };

  const createIdea = async (formData) => {
    setLoadingModal(true);
    try {
      const res = await IdeaServices.createIdea(formData);
      setResponse(res.data);
    } catch (err) {
      setErrors(err);
      // console.log(err);
    } finally {
      setLoadingModal(false);
    }
  };

  const handleAutoHeight = (e) => {
    const height = e.target.scrollHeight;
    e.target.style.height = "auto";
    e.target.style.height = `${height}px`;
  };

  const validate = (values) => {
    const errors = {};
    if (!values.date) {
      errors.date = "Date harus diisi!";
    }

    if (!values.category) {
      errors.category = "Kategori harus dipilih!";
    }

    if (!values.policy) {
      errors.policy = "Kebijakan harus dipilih!";
    }

    if (!values.title) {
      errors.title = "Judul harus diisi!";
    } else {
      const listTitle = values.title.split(" ");
      if (listTitle.length - 1 > 15) {
        errors.title = "Judul ide maksimal 15 kata!";
      }
    }

    if (!values.beforeidea) {
      errors.beforeidea = "Sebelum ide harus diisi!";
    } else {
      const listBeforeIdea = values.beforeidea.split(" ");
      if (listBeforeIdea.length - 1 > 50) {
        errors.beforeidea = "Sebelum ide maksimal 50 kata!";
      }
    }

    if (!values.suggestion) {
      errors.suggestion = "Saran ide harus diisi!";
    } else {
      const listSuggestion = values.suggestion.split(" ");
      if (listSuggestion.length - 1 > 50) {
        errors.suggestion = "Saran ide maksimal 50 kata!";
      }
    }

    if (!values.location) {
      errors.location = "Lokasi ide harus diisi!";
    }

    if (!values.afteridea) {
      errors.afteridea = "Setelah ide harus diisi!";
    } else {
      const listAfterIdea = values.afteridea.split(" ");
      if (listAfterIdea.length - 1 > 50) {
        errors.afteridea = "Setelah ide maksimal 50 kata!";
      }
    }

    // if (values.savemonth) {
    //   const listSaveMonth = values.savemonth.split(" ");
    //   if (listSaveMonth.length < 10) {
    //     errors.savemonth = "Efisiensi biaya minimal 10 kata!";
    //   }
    // }

    // if (values.savetime) {
    //   const listSaveTime = values.savetime.split(" ");
    //   if (listSaveTime.length < 10) {
    //     errors.savetime = "Efisiensi waktu minimal 10 kata!";
    //   }
    // }

    if (!values.beforeimgsrc) {
      errors.beforeimgsrc = "Gambar sebelum tidak boleh kosong!";
    } else if (formValues.beforeimgfile) {
      const file = Math.round(formValues.beforeimgfile.size / 1024);
      const fileType = formValues.beforeimgfile.type;
      if (!fileType.match(/image/)) {
        errors.beforeimgsrc = "File harus format gambar!";
      } else if (file > MaxImg) {
        errors.beforeimgsrc = "Gambar maksimal 2MB!";
      }
    }

    if (!values.afterimgsrc) {
      errors.afterimgsrc = "Gambar sesudah tidak boleh kosong!";
    } else if (formValues.afterimgfile) {
      const file = Math.round(formValues.afterimgfile.size / 1024);
      const fileType = formValues.afterimgfile.type;
      if (!fileType.match(/image/)) {
        errors.afterimgsrc = "File harus format gambar!";
      } else if (file > MaxImg) {
        errors.afterimgsrc = "Gambar maksimal 2MB!";
      }
    }

    if (formValues.standarimgfile) {
      const file = Math.round(formValues.standarimgfile.size / 1024);
      const fileType = formValues.standarimgfile.type;
      if (!fileType.match(/image/)) {
        errors.standarimgsrc = "File harus format gambar!";
      } else if (file > MaxImg) {
        errors.standarimgsrc = "Gambar maksimal 2MB!";
      }
    }

    return errors;
  };

  const validateDraft = (values) => {
    const errors = {};
    if (!values.date) {
      errors.date = "Date harus diisi!";
    }

    if (!values.category) {
      errors.category = "Kategori harus dipilih!";
    }

    if (!values.policy) {
      errors.policy = "Kebijakan harus dipilih!";
    }

    if (!values.title) {
      errors.title = "Judul harus diisi!";
    } else {
      const listTitle = values.title.split(" ");
      if (listTitle.length - 1 > 15) {
        errors.title = "Judul ide maksimal 15 kata!";
      }
    }

    if (formValues.beforeimgfile) {
      const file = Math.round(formValues.beforeimgfile.size / 1024);
      const fileType = formValues.beforeimgfile.type;
      if (!fileType.match(/image/)) {
        errors.beforeimgsrc = "File harus format gambar!";
      } else if (file > MaxImg) {
        errors.beforeimgsrc = "Gambar maksimal 2MB!";
      }
    }

    if (formValues.afterimgfile) {
      const file = Math.round(formValues.afterimgfile.size / 1024);
      const fileType = formValues.afterimgfile.type;
      if (!fileType.match(/image/)) {
        errors.afterimgsrc = "File harus format gambar!";
      } else if (file > MaxImg) {
        errors.afterimgsrc = "Gambar maksimal 2MB!";
      }
    }

    if (formValues.standarimgfile) {
      const file = Math.round(formValues.standarimgfile.size / 1024);
      const fileType = formValues.standarimgfile.type;
      if (!fileType.match(/image/)) {
        errors.standarimgsrc = "File harus format gambar!";
      } else if (file > MaxImg) {
        errors.standarimgsrc = "Gambar maksimal 2MB!";
      }
    }

    return errors;
  };

  // function bytesToSize(bytes) {
  //   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  //   if (bytes === 0) return 'n/a'
  //   const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  //   if (i === 0) return `${bytes} ${sizes[i]})`
  //   return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
  // }

  const onImgUploadBefore = (e) => {
    if (e.target.files && e.target.files[0]) {
      let beforeimgfile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setFormValues({
          ...formValues,
          beforeimgfile,
          beforeimgsrc: x.target.result,
          beforeisconf: false,
        });
        console.log(beforeimgfile);
      };
      reader.readAsDataURL(beforeimgfile);
    } else {
      setFormValues({ ...formValues, beforeimgfile: null, beforeimgsrc: null });
    }
  };

  const removeImgBefore = () => {
    setFormValues({
      ...formValues,
      beforeimgfile: null,
      beforeimgsrc: null,
      beforeisconf: false,
    });
  };

  const onImgUploadAfter = (e) => {
    if (e.target.files && e.target.files[0]) {
      let afterimgfile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setFormValues({
          ...formValues,
          afterimgfile,
          afterimgsrc: x.target.result,
          afterisconf: false,
        });
      };
      reader.readAsDataURL(afterimgfile);
    } else {
      setFormValues({ ...formValues, afterimgfile: null, afterimgsrc: null });
    }
  };

  const removeImgAfter = () => {
    setFormValues({
      ...formValues,
      afterimgfile: null,
      afterimgsrc: null,
      afterisconf: false,
    });
  };

  const onImgUploadStandar = (e) => {
    if (e.target.files && e.target.files[0]) {
      let standarimgfile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setFormValues({
          ...formValues,
          standarimgfile,
          standarimgsrc: x.target.result,
        });
      };
      reader.readAsDataURL(standarimgfile);
    } else {
      setFormValues({
        ...formValues,
        beforeimgfile: null,
        standarimgsrc: null,
      });
    }
  };

  const removeImgStandar = () => {
    setFormValues({
      ...formValues,
      standarimgfile: null,
      standarimgsrc: null,
    });
  };

  const onHandleCancel = (e) => {
    e.preventDefault();
    navigate(id ? "/viewidea/" + encodeURIComponent(id) : "/myideas", {
      replace: true,
    });
  };

  return (
    <>
      <Toast
        ref={toast}
        onHide={() => navigate("/myideas", { replace: true })}
      />
      {approvalModal && (
        <ApprovalModal
          dept={user.dept}
          level={2}
          setApprove={setApprove}
          setApprovalModal={setApprovalModal}
          handleConfirm={() => handleApprove()}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          title="Konfirmasi"
          message="Apakah yakin ingin simpan ide?"
          setConfirmModal={setConfirmModal}
          handleConfirm={() => handleConfirm()}
        />
      )}
      {loadingModal && <LoadingModal></LoadingModal>}
      <Dialog
        header="Format Review Dokumen"
        visible={reviewModal}
        maximizable
        modal
        style={{ width: "80vw" }}
        onHide={() => setReviewModal(false)}
      >
        <img
          src={require("../../assets/reviewdoc.jpg")}
          alt=""
          style={{ height: "auto", maxWidth: "100%" }}
        ></img>
      </Dialog>
      <div className="main-container">
        <div className="card-header">
          <h3>{id ? "Edit Ide" : "Ide Baru"}</h3>
        </div>
        <div className="card-body">
          <Group>
            <div style={{ marginRight: "5px" }}>
              <label className="control-label">Tanggal</label>
              <TextArea
                type="text"
                name="date"
                value={FormatDate(new Date(formValues.date))}
                onChange={(e) => handleChange(e)}
                placeholder="Otomatis"
                rows={1}
                readOnly
              />
              <MessageReq>{formErrors.date}</MessageReq>
            </div>
            <div style={{ marginRight: "5px" }}>
              <label className="control-label">No. Idea</label>
              <TextArea
                type="text"
                name="noidea"
                value={formValues.noidea}
                onChange={(e) => handleChange(e)}
                placeholder="Otomatis"
                rows={1}
                readOnly
              />
            </div>
            <div style={{ width: "100%" }}>
              <label className="control-label">
                Kategori<span style={{ color: "red" }}> *</span>
              </label>
              <select
                className="form-control"
                name="category"
                value={formValues.category}
                onChange={(e) => handleChangeCategory(e)}
              >
                <option value="">-- Pilih --</option>
                {category.map((category, index) => {
                  return (
                    <option key={index} value={category.code}>
                      {category.desc}
                    </option>
                  );
                })}
              </select>
              <MessageReq>{formErrors.category}</MessageReq>
            </div>
          </Group>
          <div className="form-group">
            <div>
              <label className="control-label">
                Terkait Kebijakan Perusahaan
                <span style={{ color: "red" }}> *</span>
              </label>
              <select
                className="form-control"
                name="policy"
                value={formValues.policy}
                onChange={(e) => handleChangePolicy(e)}
              >
                <option value="">-- Pilih --</option>
                {policy.map((policy, index) => {
                  return (
                    <option key={index} value={policy.code}>
                      {policy.desc}
                    </option>
                  );
                })}
              </select>
              <MessageReq>{formErrors.policy}</MessageReq>
            </div>
          </div>
          <div className="form-group">
            <div>
              <label className="control-label">
                Judul Ide<span style={{ color: "red" }}> *</span>
              </label>
              <TextAreaAutoSize
                name="title"
                value={formValues.title}
                onChange={(e) => handleChange(e)}
                placeholder="Berikan judul idea (Maksimal 15 kata)"
                rows={1}
                autoResize
              />
              <MessageReq>{formErrors.title}</MessageReq>
            </div>
          </div>
          <div className="form-group">
            <div>
              <label className="control-label">
                Sebelum Ide<span style={{ color: "red" }}> *</span>
              </label>
              <TextAreaAutoSize
                name="beforeidea"
                value={formValues.beforeidea}
                onChange={(e) => handleChange(e)}
                placeholder="Jelaskan sebelum idea (Maksimal 50 kata)"
                rows={1}
                autoResize
              />
              <MessageReq>{formErrors.beforeidea}</MessageReq>
            </div>
          </div>
          <div className="form-group">
            <div>
              <label className="control-label">
                Ide Perbaikan (yang dilaksanakan)
                <span style={{ color: "red" }}> *</span>
              </label>
              <TextAreaAutoSize
                name="suggestion"
                value={formValues.suggestion}
                onChange={(e) => handleChange(e)}
                placeholder="Jelaskan idea yang dibuat (Maksimal 50 kata)"
                rows={1}
                autoResize
              />
              <MessageReq>{formErrors.suggestion}</MessageReq>
            </div>
          </div>
          <div className="form-group">
            <div>
              <label className="control-label">
                Lokasi Ide<span style={{ color: "red" }}> *</span>
              </label>
              <TextAreaAutoSize
                name="location"
                value={formValues.location}
                onChange={(e) => handleChange(e)}
                placeholder="Jelaskan lokasi/area mana idea itu dibuat"
                rows={1}
                autoResize
              />
              <MessageReq>{formErrors.location}</MessageReq>
            </div>
          </div>
          <div className="form-group">
            <div>
              <label className="control-label">
                Setelah Ide<span style={{ color: "red" }}> *</span>
              </label>
              <TextAreaAutoSize
                name="afteridea"
                value={formValues.afteridea}
                onChange={(e) => handleChange(e)}
                placeholder="Jelaskan setelah ide (Maksimal 50 kata)"
                rows={1}
                autoResize
              />
              <MessageReq>{formErrors.afteridea}</MessageReq>
            </div>
          </div>
          <div className="form-group">
            <div style={{ marginRight: "5px" }}>
              <label className="control-label">Efisiensi Biaya /Bulan</label>
              <TextAreaAutoSize
                name="savemonth"
                value={formValues.savemonth}
                onChange={(e) => handleChange(e)}
                placeholder="Harus diisi dengan penjelasan perhitungan biaya"
                rows={2}
                autoResize
              />
              <MessageReq>{formErrors.savemonth}</MessageReq>
            </div>
            <div>
              <label className="control-label">Efisiensi Waktu /Hari</label>
              <TextAreaAutoSize
                name="savetime"
                value={formValues.savetime}
                onChange={(e) => handleChange(e)}
                placeholder="Harus diisi dengan penjelasan perhitungan waktu"
                rows={2}
                autoResize
              />
              <MessageReq>{formErrors.savetime}</MessageReq>
            </div>
          </div>
          <FormgGroup>
            <div>
              <label className="control-label">
                Kata-kata Tidak Cukup, Silahkan Unduh Foto/Gambar ! (mak 2MB)
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="form-group">
                <div>
                  <ImageIdea
                    title="Sebelum"
                    img={
                      !formValues.beforeisconf
                        ? formValues.beforeimgsrc
                        : defaultConf
                    }
                    handleRemove={() => removeImgBefore()}
                    onChange={(e) => onImgUploadBefore(e)}
                  />
                  <MessageReq>{formErrors.beforeimgsrc}</MessageReq>
                </div>
                <div>
                  <ImageIdea
                    title="Sesudah"
                    img={
                      !formValues.afterisconf
                        ? formValues.afterimgsrc
                        : defaultConf
                    }
                    handleRemove={() => removeImgAfter()}
                    onChange={(e) => onImgUploadAfter(e)}
                  />
                  <MessageReq>{formErrors.afterimgsrc}</MessageReq>
                </div>
              </div>
            </div>
            <div>
              <label className="control-label">
                Standarisasi (Silahkan unduh mak. 2MB)
              </label>
              <DocumentIdea
                title="Standarisasi (Review Document yang telah dicatat / diisi)"
                img={formValues.standarimgsrc}
                handleRemove={() => removeImgStandar()}
                handleReview={() => setReviewModal(true)}
                onChange={(e) => onImgUploadStandar(e)}
              />
              <MessageReq>{formErrors.standarimgsrc}</MessageReq>
            </div>
          </FormgGroup>
        </div>
        <div className="card-footer align-right">
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-danger"
              onClick={(e) => onHandleCancel(e)}
            >
              Batal
            </button>
            <button
              type="button"
              className="btn btn-draft"
              onClick={(e) => handleDraft(e)}
            >
              Draft
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InputIdea;
