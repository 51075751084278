import React, { useState, useEffect, Fragment, useRef } from "react";
import Moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import IdeaServices from "../../services/IdeaServices";
import "./PTableIdea.css";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { GroupRow } from "../element/GlobalStyle";
import jwt from "jwt-decode";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { UrlImg } from "../../api/apiFile";
import { Encrypt } from "../utils/UrlCrypt";

const Status = styled.div`
  padding: 0px 10px;
  margin-right: 5px;
  border-radius: 5px;
`;

const Label = styled.label`
  font-size: 0.8rem;
  font-weight: 600;
`;

const LihatButton = styled.button`
  padding: 5px 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  font-size: var(--font-size-text);
  color: #fff;
  cursor: pointer;
  background-color: var(--color-primary);
  margin: 5px;
`;

const ColGroup = styled.div`
  display: flex;
  @media screen and (max-width: ${(props) => props.maxWidth}) {
    flex-direction: column;
  }
`;

const PTableIdeaOuts = () => {
  const token = localStorage.getItem("token");
  const user = jwt(token);
  const nik = user.username;
  const status = user.status;
  const level = user.level;
  const dept = user.dept;

  const today = new Date();
  var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const [ideaOuts, setIdeaOuts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [errors, setErrors] = useState(null);
  const dt = useRef(null);
  const [dateDari, setDateDari] = useState(firstDay);
  const [dateSampai, setDateSampai] = useState(lastDay);
  const [selectedFactory, setSelectedFactory] = useState({
    name: "All",
    code: 0,
  });

  const [filter, setFilter] = useState(null);
  const [reviewModal, setReviewModal] = useState(false);
  const [docImg, setDocImg] = useState(require("../../assets/adddoc.png"));
  const [noIdea, setNoIdea] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (dateDari && dateSampai) {
      setFilter({
        dateFrom: dateDari.toDateString(),
        dateTo: dateSampai.toDateString(),
        nik: nik,
        site: selectedFactory.code,
        status: status,
        level: level,
        dept: dept,
      });
    }
  }, [dateDari, dateSampai, nik, selectedFactory, status, level, dept]);

  useEffect(() => {
    if (filter) {
      setLoading(true);
      async function getAllIdeas() {
        setLoading(true);
        try {
          const res = await IdeaServices.getIdeaOuts(filter);
          setIdeaOuts(res.data.data);
        } catch (err) {
          // console.log(err);
          setErrors(err);
        } finally {
          setLoading(false);
        }
      }
      getAllIdeas();
    }
  }, [filter]);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        navigate("/login");
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  const statusTemplate = (rowData) => {
    return (
      <Status
        style={
          rowData.status === "Level 2"
            ? {
                color: "#FFA500",
                backgroundColor: "rgb(255,165,0, 0.2)",
              }
            : rowData.status === "Level 3"
            ? {
                color: "#0000FF",
                backgroundColor: "rgb(0,0,255, 0.2)",
              }
            : rowData.status === "Tinjau Ulang Level 2"
            ? {
                color: "#FF00FF",
                backgroundColor: "rgb(255,0,255, 0.2)",
              }
            : {
                color: "#FF0000",
                backgroundColor: "rgb(255,0,0, 0.2)",
              }
        }
      >
        {rowData.status}
      </Status>
    );
  };

  const namaTemplate = (rowData) => {
    return <span style={{ wordBreak: "keep-all" }}>{rowData.name}</span>;
  };

  function formatDate(value) {
    return Moment(value).format("DD MMM YYYY");
  }

  const tanggalTemplate = (rowData) => {
    return (
      <span style={{ wordBreak: "keep-all" }}>
        {formatDate(new Date(rowData.updateDate))}
      </span>
    );
  };

  const noideaTemplate = (rowData) => {
    return (
      <NavLink to={"/viewidea/" + Encrypt(rowData.id.toString())}>
        {rowData.noIdea}
      </NavLink>
    );
  };

  const policyTemplate = (rowData) => {
    return <span style={{ wordBreak: "keep-all" }}>{rowData.policyDesc}</span>;
  };

  const standarTemplate = (rowData) => {
    return (
      <div>
        {rowData.standarImgSrc && (
          <LihatButton
            onClick={(e) => {
              setReviewModal(true);
              setDocImg(UrlImg + rowData.standarImgSrc);
              setNoIdea(rowData.noIdea);
            }}
          >
            Lihat
          </LihatButton>
        )}
      </div>
    );
  };

  const approvalTemplate = (rowData) => {
    return (
      <span style={{ wordBreak: "keep-all" }}>
        {rowData.approve2 ? rowData.approve2 : rowData.approve1}
      </span>
    );
  };

  const template = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
      ];

      return (
        <Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Tampil:{" "}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
            panelStyle={{ fontSize: "0.8rem" }}
          />
        </Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            textAlign: "center",
            padding: "0px 10px",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  const onCustomPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const dataExport = [];
      ideaOuts.map((idea) => {
        const data = {
          Status: idea.status,
          Approval: idea.approve1 ? idea.approve1 : idea.approve2,
          NIK: idea.nik,
          Nama: idea.name,
          Tanggal: formatDate(new Date(idea.date)),
          NoIde: idea.noIdea,
          Kategori: idea.categoryDesc,
          Kebijakan: idea.policyDesc,
          JudulIde: idea.title,
          SebelumIde: idea.beforeIdea,
          IdePerbaikan: idea.suggestion,
          LokasiIde: idea.location,
        };
        return dataExport.push(data);
      });
      const worksheet = xlsx.utils.json_to_sheet(dataExport);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Ide Outstanding");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default("l", "mm", [297, 210]);
        doc.autoTable(exportColumns, ideaOuts);
        doc.save("Ide Outstanding.pdf");
      });
    });
  };

  const cols = [
    { field: "status", header: "Status" },
    { field: "nik", header: "NIK" },
    { field: "name", header: "Nama" },
    { field: "updateDate", header: "Tanggal" },
    { field: "noIdea", header: "No Ide" },
    { field: "categoryDesc", header: "Kategori" },
    { field: "policyDesc", header: "Kebijakan Perusahaan" },
    { field: "title", header: "Judul Ide" },
    { field: "beforeIdea", header: "Sebelum Ide" },
    { field: "suggestion", header: "Ide Perbaikan" },
    { field: "location", header: "Lokasi Ide" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const factories = [
    { name: "All", code: 0 },
    { name: "TNG", code: 1 },
    { name: "CKR", code: 3 },
  ];

  const onFactoryChange = (e) => {
    setSelectedFactory(e.value);
  };

  const category = [
    "Quality",
    "Cost",
    "Delivery",
    "Safety",
    "Morale",
    "Productivity",
    "Productivity",
    "Environment",
  ];

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={options.filterClearCallback}
        className="p-button-secondary"
      ></Button>
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={options.filterApplyCallback}
        className="p-button-success"
      ></Button>
    );
  };

  const categoryFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={category}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder="Pilih kategori"
        panelStyle={{ fontSize: "0.8rem" }}
        showClear
      />
    );
  };

  return (
    <div>
      <Dialog
        header={"Review Dokumen " + noIdea}
        visible={reviewModal}
        maximizable
        modal
        style={{ width: "80vw" }}
        onHide={() => setReviewModal(false)}
      >
        <img
          src={docImg}
          alt=""
          style={{ height: "auto", maxWidth: "100%" }}
        ></img>
      </Dialog>
      <ColGroup maxWidth={status === "Admin" ? "410px" : "315px"}>
        <div
          style={{
            display: "flex",
            marginBottom: "5px",
          }}
        >
          <GroupRow>
            <Label>Dari tgl</Label>
            <Calendar
              id="dtdari"
              value={dateDari}
              onChange={(e) => setDateDari(e.value)}
              dateFormat="dd-mm-yy"
              maxDate={dateSampai}
              readOnlyInput
              inputStyle={{
                fontSize: "0.8rem",
              }}
              style={{
                minWidth: "90px",
                marginTop: "2px",
                marginRight: "10px",
              }}
            />
          </GroupRow>
          <GroupRow>
            <Label>s/d</Label>
            <Calendar
              id="dtsampai"
              value={dateSampai}
              onChange={(e) => setDateSampai(e.value)}
              dateFormat="dd-mm-yy"
              minDate={dateDari}
              readOnlyInput
              inputStyle={{
                fontSize: "0.8rem",
              }}
              style={{
                minWidth: "90px",
                marginTop: "2px",
                marginRight: "10px",
              }}
            />
          </GroupRow>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <GroupRow>
            <Label>Factory</Label>
            <Dropdown
              panelStyle={{ fontSize: "0.8rem" }}
              optionLabel="name"
              value={selectedFactory}
              options={factories}
              onChange={onFactoryChange}
              placeholder="Pilih factory"
              style={{
                marginTop: "2px",
                marginRight: "10px",
              }}
            />
          </GroupRow>
          {status === "Admin" ? (
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Button
                style={{
                  marginRight: "10px",
                }}
                type="button"
                icon="pi pi-file-excel"
                onClick={exportExcel}
                className="p-button-success mr-2"
                tooltip="XLS"
                tooltipOptions={{ position: "bottom" }}
              />
              <Button
                type="button"
                icon="pi pi-file-pdf"
                onClick={exportPdf}
                className="p-button-warning mr-2"
                tooltip="PDF"
                tooltipOptions={{ position: "bottom" }}
              />
            </div>
          ) : null}
        </div>
      </ColGroup>
      <DataTable
        value={ideaOuts}
        ref={dt}
        scrollable
        loading={loading}
        scrollDirection="both"
        paginator
        paginatorTemplate={template}
        first={first}
        rows={rows}
        onPage={onCustomPage}
        paginatorClassName="justify-content-end"
        responsiveLayout="scroll"
        removableSort
        className="header-class"
        rowClassName={"row-class"}
        emptyMessage="Data tidak ditemukan"
        // scrollHeight="calc(100vh - 235px)"
      >
        <Column
          field="status"
          header="Status"
          style={{ flexGrow: 1, flexBasis: "80px" }}
          body={statusTemplate}
          // frozen
          sortable
          filter
          filterPlaceholder="Cari status"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="approve1"
          header="Approval"
          body={approvalTemplate}
          style={{ flexGrow: 1, flexBasis: "150px" }}
          sortable
          filter
          filterPlaceholder="Cari approval"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="nik"
          header="NIK"
          style={{ flexGrow: 1, flexBasis: "70px" }}
          // frozen
          sortable
          filter
          filterPlaceholder="Cari nik"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="name"
          header="Nama"
          body={namaTemplate}
          style={{ flexGrow: 1, flexBasis: "150px" }}
          sortable
          filter
          filterPlaceholder="Cari nama"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="updateDate"
          header="Tanggal"
          body={tanggalTemplate}
          style={{ flexGrow: 1, flexBasis: "110px" }}
          sortable
          filter
          filterPlaceholder="Cari tanggal"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="noIdea"
          header="No Ide"
          body={noideaTemplate}
          style={{ flexGrow: 1, flexBasis: "120px" }}
          sortable
          filter
          filterPlaceholder="Cari no ide"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="categoryDesc"
          header="Kategori"
          style={{ flexGrow: 1, flexBasis: "100px" }}
          sortable
          filter
          filterPlaceholder="Cari kategori"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
          filterElement={categoryFilterTemplate}
        ></Column>
        <Column
          field="policyDesc"
          header="Kebijakan Perusahaan"
          body={policyTemplate}
          style={{ flexGrow: 1, flexBasis: "200px" }}
          sortable
          filter
          filterPlaceholder="Cari kebijakan"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="title"
          header="Judul Ide"
          style={{ flexGrow: 1, flexBasis: "200px" }}
          filter
          filterPlaceholder="Cari judul"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="beforeIdea"
          header="Sebelum Ide"
          style={{ flexGrow: 1, flexBasis: "200px" }}
        ></Column>
        <Column
          field="suggestion"
          header="Ide Perbaikan"
          style={{ flexGrow: 1, flexBasis: "200px" }}
        ></Column>
        <Column
          field="location"
          header="Lokasi Ide"
          style={{ flexGrow: 1, flexBasis: "200px" }}
        ></Column>
        <Column
          field="standarImgSrc"
          header="Standarisasi"
          style={{ flexGrow: 1, flexBasis: "80px" }}
          body={standarTemplate}
        ></Column>
      </DataTable>
    </div>
  );
};

export default PTableIdeaOuts;
