import React from "react";
import { Navigate } from "react-router-dom";
// import { useAuth } from "./auth";

const RequireAuth = ({ children }) => {
  const token = localStorage.getItem("token");
  // const { user } = useAuth();
  // console.log("user", user);
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default RequireAuth;
