import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Border = styled.div`
  border-bottom: 1px solid var(--color-border);
  width: 100%;
`;

const Divider = () => {
  return (
    <Container>
      <Border></Border>
    </Container>
  );
};

export default Divider;
