import React from "react";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Image } from "primereact/image";
import { Toast } from "primereact/toast";
import styled from "styled-components";
import FormatDate from "../utils/FormatDate";
import ConfirmModal from "../modal/ConfirmModal";
import LoadingModal from "../modal/LoadingModal";
import IdeaServices from "../../services/IdeaServices";
import { UrlImg } from "../../api/apiFile";
import jwt from "jwt-decode";
import { Decrypt } from "../utils/UrlCrypt";

const FormGroupRow = styled.div`
  margin: 5px 5px 10px 5px;
  label {
    font-size: var(--font-size-text);
    font-weight: bold;
  }
  p {
    font-size: var(--font-size-text);
    color: black;
  }
`;

const FormGroupCol = styled.div`
  margin: 5px;
  display: flex;
  label {
    font-size: var(--font-size-text);
    font-weight: bold;
  }
  p {
    font-size: var(--font-size-text);
    color: black;
    margin-left: 5px;
  }
`;

const Title = styled.p`
  font-size: 0.9rem;
  font-weight: bold;
  margin: 5px;
`;

const DateIdea = styled.p`
  font-size: var(--font-size-text);
  margin: 5px;
  color: grey;
  font-weight: 500;
`;

const GroupCol = styled.div`
  display: flex;
  // justify-content: space-around;
`;

const Col1 = styled.div`
  flex: 30%;
  @media (max-width: 780px) {
    flex: 40%;
  }
`;

const Col2 = styled.div`
  flex: 70%;
  @media (max-width: 780px) {
    flex: 60%;
  }
`;

const Back = styled(FaArrowLeft)`
  color: #0066cc;
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
`;

const StyleImageIdea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  border: 1px solid var(--color-border);
  padding-bottom: 5px;
  h3 {
    background-color: var(--color-primary);
    width: 100%;
    text-align: center;
    color: #fff;
  }
  img {
    width: 100px;
    height: 100px;
    margin: 5px;
  }
`;

const defaultImg = require("../../assets/addimage.png");
const defaultDoc = require("../../assets/adddoc.png");
const defaultConf = require("../../assets/confidential.png");

const ViewIdea = () => {
  const token = localStorage.getItem("token");
  const user = jwt(token);

  const { id } = useParams();
  const [name, setName] = useState("");
  const [noidea, setNoIdea] = useState("");
  const [category, setCategory] = useState("");
  const [policy, setPolicy] = useState("");
  const [title, setTitle] = useState("");
  const [beforeIdea, setBeforeIdea] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [location, setLocation] = useState("");
  const [afterIdea, setAfterIdea] = useState("");
  const [saveMonth, setSaveMonth] = useState("");
  const [saveTime, setSaveTime] = useState("");
  const [status, setStatus] = useState("");
  const [imgBeforePrev, setImgBeforePrev] = useState(null);
  const [imgAfterPrev, setImgAfterPrev] = useState(null);
  const [imgStandarPrev, setImgStandarPrev] = useState(null);
  const [imgBeforeConf, setImgBeforeConf] = useState(false);
  const [imgAfterConf, setImgAfterConf] = useState(false);
  const [updateDate, setUpdateDate] = useState(null);

  const [idea, setIdea] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [errors, setErrors] = useState(null);
  const [response, setResponse] = useState(null);

  const navigate = useNavigate();
  const toast = useRef(null);

  useEffect(() => {
    if (id) {
      async function getIdeaById() {
        setLoadingModal(true);
        try {
          const response = await IdeaServices.getIdeaById(Decrypt(id));
          setIdea(response.data.data);
        } catch (err) {
          setErrors(err);
        }
        setLoadingModal(false);
      }
      getIdeaById();
    }
  }, [id]);

  useEffect(() => {
    if (idea) {
      setName(idea.name);
      // setDate(idea.date);
      setNoIdea(idea.noIdea);
      setTitle(idea.title);
      setCategory(idea.categoryDesc);
      setPolicy(idea.policyDesc);
      setBeforeIdea(idea.beforeIdea);
      setSuggestion(idea.suggestion);
      setLocation(idea.location);
      setAfterIdea(idea.afterIdea);
      setSaveMonth(idea.saveMonth);
      setSaveTime(idea.saveTime);
      setStatus(idea.status);
      setImgBeforePrev(idea.beforeImgSrc && UrlImg + idea.beforeImgSrc);
      setImgAfterPrev(idea.afterImgSrc && UrlImg + idea.afterImgSrc);
      setImgStandarPrev(idea.standarImgSrc && UrlImg + idea.standarImgSrc);
      setUpdateDate(idea.updateDate);
      setImgBeforeConf(idea.beforeIsConf);
      setImgAfterConf(idea.afterIsConf);
    }
  }, [idea]);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        navigate("/login");
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  useEffect(() => {
    if (response) {
      if (response.status) {
        toast.current.show({
          severity: "success",
          summary: "Berhasil",
          detail: response.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Gagal",
          detail: response.message,
          life: 3000,
        });
      }
    }
  }, [response]);

  const handleConfirm = () => {
    deleteIdea();
  };

  const deleteIdea = async () => {
    setLoadingModal(true);
    try {
      const res = await IdeaServices.deleteIdea(Decrypt(id));
      setResponse(res.data);
    } catch (err) {
      setErrors(err);
    } finally {
      setLoadingModal(false);
    }
  };

  const onHandleBack = (to) => {
    navigate(to, { replace: true });
    // navigate(-1);
  };

  return (
    <>
      <Toast
        ref={toast}
        onHide={() => navigate("/myideas", { replace: true })}
      />
      {confirmModal && (
        <ConfirmModal
          title="Konfirmasi"
          message="Apakah yakin ingin hapus data?"
          setConfirmModal={setConfirmModal}
          handleConfirm={() => handleConfirm()}
        />
      )}
      {loadingModal && <LoadingModal></LoadingModal>}
      <div className="main-container">
        <div className="card-header">
          <h3>
            Ide <span style={{ color: "rgb(0,0,0,0.6)" }}>{name}</span>{" "}
          </h3>
          {user.level === "1" ? (
            <Back onClick={() => onHandleBack("/myideas")}></Back>
          ) : (
            <Back onClick={() => onHandleBack("/listidea")}></Back>
          )}
        </div>
        <div
          className={
            status === "Draft" || status === "Ditolak"
              ? "card-body"
              : "card-body without-footer"
          }
        >
          <GroupCol>
            <Col1>
              <FormGroupCol>
                <label>No. Ide :</label>
                <p>{noidea}</p>
              </FormGroupCol>
              <Title>{title}</Title>
              <DateIdea>{FormatDate(new Date(updateDate))}</DateIdea>
            </Col1>
            <Col2>
              <FormGroupCol>
                <label style={{ width: "60px" }}>Kategori</label>
                <label>:</label>
                <p>{category}</p>
              </FormGroupCol>
              <FormGroupCol>
                <label style={{ width: "60px" }}>Kebijakan Perusahaan</label>
                <label>:</label>
                <p>{policy}</p>
              </FormGroupCol>
            </Col2>
          </GroupCol>
          <div className="form-group">
            {/* <ImageIdea title="Sebelum" img={imgBeforePrev} hidden /> */}
            <StyleImageIdea>
              <h3>Sebelum</h3>
              <Image
                src={
                  !imgBeforePrev
                    ? defaultImg
                    : !imgBeforeConf || user.level !== "1"
                    ? imgBeforePrev
                    : defaultConf
                }
                alt="Sebelum"
                preview={
                  imgBeforePrev && (!imgBeforeConf || user.level !== "1")
                    ? true
                    : false
                }
                onError={(e) => (e.target.src = defaultImg)}
              />
            </StyleImageIdea>
            {/* <ImageIdea title="Sesudah" img={imgAfterPrev} hidden /> */}
            <StyleImageIdea>
              <h3>Sesudah</h3>
              <Image
                src={
                  !imgAfterPrev
                    ? defaultImg
                    : !imgAfterConf || user.level !== "1"
                    ? imgAfterPrev
                    : defaultConf
                }
                alt="Sesudah"
                preview={
                  imgAfterPrev && (!imgAfterConf || user.level !== "1")
                    ? true
                    : false
                }
                onError={(e) => (e.target.src = defaultImg)}
              />
            </StyleImageIdea>
            {/* <ImageIdea title="Standarisasi" img={imgStandarPrev} hidden /> */}
            <StyleImageIdea>
              <h3>Standarisasi</h3>
              <Image
                src={imgStandarPrev ? imgStandarPrev : defaultDoc}
                alt="Standarisasi"
                preview={imgStandarPrev ? true : false}
                onError={(e) => (e.target.src = defaultDoc)}
              />
            </StyleImageIdea>
          </div>
          <FormGroupRow>
            <label>Sebelum Ide</label>
            <p>{beforeIdea}</p>
          </FormGroupRow>
          <FormGroupRow>
            <label>Usulan Ide (yang dilaksanakan)</label>
            <p>{suggestion}</p>
          </FormGroupRow>
          <FormGroupRow>
            <label>Lokasi Ide</label>
            <p>{location}</p>
          </FormGroupRow>
          <FormGroupRow>
            <label>Sesudah Ide</label>
            <p>{afterIdea}</p>
          </FormGroupRow>
          <FormGroupRow>
            <label>Efisiensi Biaya /Bulan</label>
            <p>{saveMonth}</p>
          </FormGroupRow>
          <FormGroupRow>
            <label>Efisiensi Waktu /Hari</label>
            <p>{saveTime}</p>
          </FormGroupRow>
        </div>
        {status === "Draft" || status === "Ditolak" ? (
          <div className="card-footer align-right">
            <div className="btn-group">
              <button
                type="button"
                onClick={() => {
                  setConfirmModal(true);
                }}
                className="btn btn-danger"
              >
                Hapus
              </button>
              <NavLink to={"/editidea/" + encodeURIComponent(id)}>
                <button type="button" className="btn btn-primary">
                  Ubah
                </button>
              </NavLink>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ViewIdea;
