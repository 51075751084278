import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import ImgEmpty from "../../assets/isempty.png";
import { Chart } from "primereact/chart";
import IdeaServices from "../../services/IdeaServices";
import BeritaServices from "../../services/BeritaServices";
import { NavLink, useNavigate } from "react-router-dom";
import jwt from "jwt-decode";
import { UrlImg, UrlFoto } from "../../api/apiFile";
import { Avatar } from "primereact/avatar";
// import { Toast } from "primereact/toast";
import UserServices from "../../services/UserServices";
import RoleServices from "../../services/RoleServices";
// import { ReceiptLongOutlined } from "@mui/icons-material";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useAuth } from "../auth";
import { useSelector } from "react-redux";
import { selectNama } from "../../redux/slice/authSlice";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  gap: 10px;
  padding: 0px 50px;
  @media screen and (max-width: 780px) {
    gap: 5px;
    padding: 5px;
  }
`;

const ColProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--color-border);
  box-shadow: 1px 3px 10px var(--color-border);
  border-radius: 10px;
  background-color: white;
  padding: 10px;
  color: var(--color-primary);
  // height: 70px;
`;

const ProfileName = styled.div`
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  // @media screen and (max-width: 780px) {
  //   flex-direction: column;
  // }
`;

const ColContent = styled.div`
  display: flex;
  border: 1px solid var(--color-border);
  box-shadow: 1px 3px 10px var(--color-border);
  border-radius: 10px;
  background-color: white;
  flex-direction: column;
  height: auto;
  width: 100%;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  color: var(--color-primary);
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  padding: 0px 20px;
`;

const CardBody = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  padding: 5px 10px;
`;

const defaultImg = require("../../assets/avatar.png");
const menuCode = "beranda";

const Beranda = () => {
  const token = localStorage.getItem("token");
  const { username, nama, dept, status } = jwt(token);

  const [berita, setBerita] = useState([]);
  const [pip, setPip] = useState(null);
  const [basicData, setBasicData] = useState({});
  const [fotoUser, setFotoUser] = useState(null);
  const [fotoSrc, setFotoSrc] = useState("");
  const [errors, setErrors] = useState(null);
  const [akses, setAkses] = useState(null);
  const [isView, setIsView] = useState(false);

  const navigate = useNavigate();
  const namaX = useSelector(selectNama);
  // console.log(nama);
  const { logout } = useAuth();

  useEffect(() => {
    async function getIdeaTotalDiterima() {
      try {
        const res = await IdeaServices.getIdeaTotalDiterima();
        setPip(res.data.data);
      } catch (err) {
        setErrors(err);
      } finally {
      }
    }
    getIdeaTotalDiterima();

    async function getUserFoto() {
      try {
        const res = await UserServices.getUserFoto(username);
        setFotoUser(res.data.data);
      } catch (err) {
        setErrors(err);
        // console.log(err);
      } finally {
      }
    }
    getUserFoto();

    async function getBerita() {
      try {
        const res = await BeritaServices.getBerita();
        setBerita(res.data.data);
      } catch (err) {
        // console.log(err);
        setErrors(err);
      } finally {
        // setLoading(false);
      }
    }
    getBerita();

    async function getRoleAkses() {
      try {
        const res = await RoleServices.getUserRoleAkses(username, menuCode);
        setAkses(res.data.data);
      } catch (err) {
        // console.log(err);
        setErrors(err);
      } finally {
        // getAllUsers();
      }
    }
    getRoleAkses();
  }, []);

  useEffect(() => {
    if (akses) {
      setIsView(akses.isView);
    }
  }, [akses]);

  useEffect(() => {
    if (fotoUser) {
      setFotoSrc(fotoUser.fotoSrc && UrlFoto + fotoUser.fotoSrc);
    }
  }, [fotoUser]);

  useEffect(() => {
    if (pip) {
      setBasicData({
        labels: pip.map((data) => data.bulanName),
        datasets: [
          {
            label: "Tangerang",
            backgroundColor: "#42A5F5",
            data: pip.map((data) => data.tng),
          },
          {
            label: "Cikarang",
            backgroundColor: "#FFA726",
            data: pip.map((data) => data.ckr),
          },
        ],
      });
    }
  }, [pip]);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        logout();
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  let basicOptions = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
      title: {
        display: true,
        text: "PENCAPAIAN PIP / IDE",
        fontSize: 20,
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
    },
  };

  const getFirstChar = (str) => {
    const listName = str.split(" ");
    let initial = listName[0].charAt(0).toUpperCase();
    if (listName.length > 1) {
      initial += listName[1].charAt(0).toUpperCase();
    }
    return initial;
  };

  return (
    <>
      <ConfirmDialog />
      <div className="main-container">
        <GridContainer>
          <ColProfile>
            <ProfileName>
              <h3
                style={{
                  marginBottom: "5px",
                  marginRight: "5px",
                  color: "rgb(0, 0, 0, 0.7)",
                }}
              >
                Selamat datang
              </h3>
              <h3>{nama.toUpperCase()}</h3>
              <h4>
                {status === "User" ? "NIK" : "Username"}:&nbsp;{username}
              </h4>
              <h4>Dept:&nbsp;{dept}</h4>
            </ProfileName>
            <div style={{ display: "flex" }}>
              {fotoSrc ? (
                <Avatar
                  style={{
                    border: "1px solid rgb(0, 102, 153, 0.5)",
                  }}
                  image={fotoSrc}
                  className="mr-2"
                  size="xlarge"
                  shape="circle"
                  onError={(e) => (e.target.src = defaultImg)}
                />
              ) : (
                <Avatar
                  style={{
                    border: "1px solid rgb(0, 102, 153, 0.5)",
                    backgroundColor: "var(--color-primary)",
                    color: "#ffffff",
                  }}
                  className="mr-2"
                  size="xlarge"
                  shape="circle"
                  label={getFirstChar(nama)}
                />
              )}
            </div>
          </ColProfile>
          {isView ? (
            <ColContent>
              <div style={{ width: "96%", margin: "10px" }}>
                <Chart type="bar" data={basicData} options={basicOptions} />
              </div>
              <div
                style={{
                  width: "96%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "5px",
                }}
              >
                <NavLink to={"/laptotalide"}>{"Lihat detail >>"}</NavLink>
              </div>
            </ColContent>
          ) : null}
          <ColContent>
            <CardHeader>
              <h3>Berita</h3>
            </CardHeader>
            <CardBody>
              {berita.length > 0 ? (
                berita.map((item) => {
                  let ket = (
                    <div
                      style={{
                        margin: "5px",
                      }}
                      key={item.id}
                    >
                      {item.ket}
                    </div>
                  );
                  if (item.tipe === "Gambar") {
                    ket = (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "5px",
                        }}
                        key={item.id}
                      >
                        <img
                          style={{ maxHeight: "50vw", width: "auto" }}
                          src={`${UrlImg}${item.ket}`}
                          onError={(e) =>
                            (e.target.src = require("../../assets/addimage.png"))
                          }
                          alt={item.image}
                          // className="berita-beranda-image"
                        />
                      </div>
                    );
                  }
                  return ket;
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "5px",
                    flexDirection: "column",
                  }}
                >
                  <img src={ImgEmpty} alt=""></img>
                  <p style={{ marginTop: "5px" }}>Tidak ada berita</p>
                </div>
              )}
            </CardBody>
          </ColContent>
        </GridContainer>
      </div>
    </>
  );
};

export default Beranda;
