import { useState, createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
// import { useLocalStorage } from "./useLocalStorage";
import jwt from "jwt-decode";
import { useDispatch } from "react-redux";
import { REMOVE_ACTIVE_USER, SET_ACTIVE_USER } from "../redux/slice/authSlice";
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  // const [user, setUser] = useState(useLocalStorage("token", null));
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const login = async (user) => {
    setUser(jwt(user));
    dispatch(SET_ACTIVE_USER(jwt(user)));
    navigate("/", { replace: true });
  };

  const logout = () => {
    setUser(null);
    dispatch(REMOVE_ACTIVE_USER());
    localStorage.clear();
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
