import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// import { Dropdown } from "primereact/dropdown";
import RoleServices from "../../services/RoleServices";
// import SettingServices from "../../services/SettingServices";
import "./PTableIdea.css";
import styled from "styled-components";
import { FaRegTrashAlt, FaEdit } from "react-icons/fa";
import ConfirmModal from "../modal/ConfirmModal";
import UserRoleModal from "../modal/UserRoleModal";
import MessageModal from "../modal/MessageModal";

const DeleteModalButton = styled(FaRegTrashAlt)`
  cursor: pointer;
  color: crimson;
  width: 15px;
  height: 15px;
  margin: 0 5px;
`;

const EditModalButton = styled(FaEdit)`
  cursor: pointer;
  color: var(--color-primary);
  width: 15px;
  height: 15px;
  margin: 0 5px;
`;

const PTableUserRole = () => {
  const [id, setId] = useState(null);
  const [username, setUsername] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [userRoleModal, setUserRoleModal] = useState(false);
  const [msgModal, setMsgModal] = useState("");
  // const [listRole, setListRole] = useState([]);

  useEffect(() => {
    getAllUserRole();
    // getRole();
  }, []);

  const getAllUserRole = async () => {
    setLoading(true);
    try {
      const res = await RoleServices.getUserRole();
      setUserRoles(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // const getRole = async () => {
  //   try {
  //     const response = await SettingServices.getRole();
  //     setListRole(response.data.data);
  //   } catch (err) {}
  // };

  const pilihanTemplate = (rowData) => {
    return (
      <div>
        <EditModalButton
          onClick={(e) => {
            setId(rowData.id);
            setUsername(rowData.username);
            setUserRoleModal(true);
          }}
        ></EditModalButton>
        <DeleteModalButton
          onClick={(e) => {
            setId(rowData.id);
            setConfirmModal(true);
          }}
        ></DeleteModalButton>
      </div>
    );
  };

  const handleDelete = () => {
    deleteUser();
  };

  const deleteUser = async () => {
    setLoadingModal(true);
    try {
      await RoleServices.deleteUserRole(id);
    } catch (err) {
      // setErrors(err);
    } finally {
      setLoadingModal(false);
      setMsgModal(true);
    }
    getAllUserRole();
  };

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={options.filterClearCallback}
        className="p-button-secondary"
      ></Button>
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={options.filterApplyCallback}
        className="p-button-success"
      ></Button>
    );
  };

  // const roleFilterTemplate = (options) => {
  //   return (
  //     <Dropdown
  //       value={options.value}
  //       options={listRole}
  //       optionLabel="name"
  //       onChange={(e) => options.filterCallback(e.value, options.index)}
  //       placeholder="Pilih role"
  //       panelStyle={{ fontSize: "0.8rem" }}
  //       showClear
  //     />
  //   );
  // };

  return (
    <div>
      {confirmModal && (
        <ConfirmModal
          title="Konfirmasi"
          message="Apakah yakin ingin hapus data?"
          setConfirmModal={setConfirmModal}
          handleConfirm={() => handleDelete()}
        />
      )}
      {userRoleModal && (
        <UserRoleModal
          setUserModal={setUserRoleModal}
          id={id}
          username={username}
          getAllUsers={getAllUserRole}
        ></UserRoleModal>
      )}
      {msgModal && (
        <MessageModal
          status={true}
          message="Berhasil dihapus"
          setShow={setMsgModal}
        ></MessageModal>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "5px",
        }}
      ></div>
      <DataTable
        value={userRoles}
        scrollable
        loading={loading}
        className="header-class"
        rowClassName="row-class-report"
        removableSort
        paginator
        rows={10}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[10, 25, 50]}
        dataKey="id"
        rowHover
        filterDisplay="menu"
        responsiveLayout="scroll"
        emptyMessage="Data tidak ditemukan"
        currentPageReportTemplate="Tampil {first} ke {last} dari {totalRecords} data"
        scrollHeight="calc(100vh - 206px)"
      >
        <Column
          header="No."
          headerStyle={{ minWidth: "5em", maxWidth: "10em" }}
          style={{ minWidth: "5em", maxWidth: "10em" }}
          body={(data, options) => options.rowIndex + 1}
        ></Column>
        <Column
          field="username"
          header="Username"
          headerStyle={{ minWidth: "10em", maxWidth: "15em" }}
          style={{ minWidth: "10em", maxWidth: "15em" }}
          sortable
          filter
          filterPlaceholder="Cari username"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="nama"
          header="Nama"
          headerStyle={{ minWidth: "15em" }}
          style={{ minWidth: "15em" }}
          sortable
          filter
          filterPlaceholder="Cari nama"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="desc"
          header="Role"
          headerStyle={{ minWidth: "10em", maxWidth: "15em" }}
          style={{ minWidth: "10em", maxWidth: "15em" }}
          sortable
          filter
          filterPlaceholder="Cari role"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          header="Pilihan"
          headerStyle={{ minWidth: "7em", maxWidth: "10em" }}
          style={{ minWidth: "7em", maxWidth: "10em" }}
          body={pilihanTemplate}
        ></Column>
      </DataTable>
    </div>
  );
};

export default PTableUserRole;
