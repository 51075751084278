import React, { useEffect } from "react";
import styled from "styled-components";

const Background = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 350px;
  height: 100px;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 50px;
    height: 50px;
  }
`;

const SuccessMessage = styled.p`
  font-size: 16px;
  margin: 5px;
  color: green;
`;

const ErrorMessage = styled.p`
  font-size: 16px;
  margin: 5px;
  color: crimson;
`;

function MessageModal({ status, message, setShow }) {
  useEffect(() => {
    let timer1 = setTimeout(() => setShow(false), 2000);
    return () => {
      clearTimeout(timer1);
    };
  }, [setShow]);

  return (
    <Background>
      <ModalContainer>
        {status ? (
          <>
            <img src={require("../../assets/success.png")} alt=""></img>
            <SuccessMessage>{message}</SuccessMessage>
          </>
        ) : (
          <>
            <img src={require("../../assets/error.png")} alt=""></img>
            <ErrorMessage>{message}</ErrorMessage>
          </>
        )}
      </ModalContainer>
    </Background>
  );
}

export default MessageModal;
