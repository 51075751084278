import React, { useEffect, useState } from "react";
// import { NavLink } from "react-router-dom";
// import { FaChevronCircleRight } from "react-icons/fa";
// import { ImUsers } from "react-icons/im";
// import { FcIdea } from "react-icons/fc";
import styled from "styled-components";
import ModuleServices from "../../services/ModuleServices";
import ListItemButton from "@mui/material/ListItemButton";
// import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Folder from "@mui/icons-material/Folder";
import Lightbulb from "@mui/icons-material/Lightbulb";
import UploadIcon from "@mui/icons-material/Upload";
import Group from "@mui/icons-material/Group";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import BarChart from "@mui/icons-material/BarChart";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import HistoryIcon from "@mui/icons-material/History";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import jwt from "jwt-decode";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 20px;
  padding: 0px 50px;
  @media screen and (max-width: 810px) {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
`;

const ColContent = styled.div`
  display: flex;
  border: 1px solid var(--color-border);
  box-shadow: 1px 3px 10px var(--color-border);
  border-radius: 10px;
  background-color: white;
  flex-direction: column;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  background-color: var(--color-primary);
  color: #ffff;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0px 20px;
`;

const Menu = () => {
  const token = localStorage.getItem("token");
  const user = jwt(token);

  const [menuItems, setMenuItems] = useState(null);
  const [open, setOpen] = React.useState(true);
  const [openSetting, setOpenSetting] = React.useState(true);
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickSetting = () => {
    setOpenSetting(!openSetting);
  };

  const handleClickLink = (path) => {
    navigate(path);
  };

  useEffect(() => {
    async function getModuleUser() {
      try {
        const res = await ModuleServices.getModuleByUser(user.username);
        setMenuItems(res.data.data);
      } catch (err) {
        setErrors(err);
      } finally {
      }
    }
    getModuleUser();
  }, []);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        navigate("/login");
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  return (
    <div className="main-container">
      <GridContainer>
        <ColContent>
          <CardHeader>MENU</CardHeader>
          <List>
            {menuItems && menuItems.length > 0
              ? menuItems.map((item, index) => {
                  if (item.child === 1) {
                    return (
                      <ListItemButton
                        key={index}
                        onClick={() => handleClickLink(item.path)}
                      >
                        <ListItemIcon>
                          {item.icon === "user" ? (
                            <Group />
                          ) : item.icon === "news" ? (
                            <NewspaperIcon />
                          ) : item.icon === "upload" ? (
                            <UploadIcon />
                          ) : (
                            <Lightbulb />
                          )}
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                      </ListItemButton>
                    );
                  }
                  return null;
                })
              : null}
            <ListItemButton onClick={handleClick}>
              <ListItemIcon>
                <Folder />
              </ListItemIcon>
              <ListItemText primary="Laporan" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {menuItems && menuItems.length > 0
                  ? menuItems.map((item, index) => {
                      if (item.child === 2) {
                        return (
                          <ListItemButton
                            key={index}
                            sx={{ pl: 4 }}
                            onClick={() => handleClickLink(item.path)}
                          >
                            <ListItemIcon>
                              <BarChart />
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                          </ListItemButton>
                        );
                      }
                      return null;
                    })
                  : null}
              </List>
            </Collapse>
            {/* Sub menu pengaturan */}
            {user.status === "Admin" ? (
              <>
                <ListItemButton onClick={handleClickSetting}>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Pengaturan" />
                  {openSetting ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openSetting} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {menuItems && menuItems.length > 0
                      ? menuItems.map((item, index) => {
                          if (item.child === 3) {
                            return (
                              <ListItemButton
                                key={index}
                                sx={{ pl: 4 }}
                                onClick={() => handleClickLink(item.path)}
                              >
                                <ListItemIcon>
                                  {item.icon === "menu" ? (
                                    <MenuIcon />
                                  ) : (
                                    <HistoryIcon />
                                  )}
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                              </ListItemButton>
                            );
                          }
                          return null;
                        })
                      : null}
                  </List>
                </Collapse>
              </>
            ) : null}
          </List>
        </ColContent>
      </GridContainer>
    </div>
  );
};

export default Menu;
