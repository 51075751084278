import React from "react";
import styled from "styled-components";

const Background = styled.div`
  position: absolute;
  z-index: 50000;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 350px;
  height: 300px;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 50px;
    height: 50px;
  }

  p {
    font-size: 14px;
    margin: 5px;
    color: var(--color-primary);
  }
`;

function LoadingModal() {
  return (
    <Background>
      <ModalContainer>
        <img src={require("../../assets/loading.gif")} alt=""></img>
        <p>Menunggu</p>
      </ModalContainer>
    </Background>
  );
}

export default LoadingModal;
