import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import { FaSearch, FaCircle } from "react-icons/fa";
import { BsChevronRight } from "react-icons/bs";
import FormatDate from "../utils/FormatDate";
import LoadingModal from "../modal/LoadingModal";
import NilaiServices from "../../services/NilaiServices";
import jwt from "jwt-decode";
import { Encrypt } from "../utils/UrlCrypt";

const CardIdea = styled.div`
  padding: 5px;
  margin: 5px;
  // border-radius: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
  // background-color: #f0f8ff;
  // border: 1px solid #e5e5e5;
  // box-shadow: 0 1px 2px 1px rgb(0 0 0 / 6%);
  display: flex;
  align-items: center;
`;

const FormGroupCol = styled.div`
  display: flex;
  font-size: 16px;
  label {
    width: 70px;
    font-weight: 500;
  }
  p {
    margin-left: 5px;
  }
`;

const Status = styled.p`
  padding: 0px 10px;
  margin-right: 5px;
  border-radius: 5px;
`;

const ChevronRightIcon = styled(BsChevronRight)`
  color: #0066cc;
`;

const SearchIcon = styled(FaSearch)`
  margin: 5px 3px 5px 8px;
  pointer-events: none;
`;

const CircleIcon = styled(FaCircle)`
  pointer-events: none;
  font-size: 9px;
  margin-right: 5px;
`;

const PenilaianIdeas = () => {
  const [ideas, setIdeas] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [errors, setErrors] = useState(null);
  const [query, setQuery] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    getAllIdeas();
  }, []);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        navigate("/login");
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  const getAllIdeas = async () => {
    const token = localStorage.getItem("token");
    const user = jwt(token);

    setLoadingModal(true);
    try {
      const res = await NilaiServices.getNilaiByUser(user.username, user.level);
      setIdeas(res.data.data);
    } catch (err) {
      setErrors(err);
    }
    setLoadingModal(false);
  };

  return (
    <>
      {loadingModal && <LoadingModal></LoadingModal>}
      <div className="main-container">
        <div className="card-header">
          <h3>Total Ide</h3>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="left-inner-addon input-container">
              <SearchIcon></SearchIcon>
              {/* <i className="fa fa-search"></i> */}
              <input
                type="search"
                className="form-control"
                placeholder="Pencarian"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="card-body without-footer">
          <div
            style={{
              maxHeight: "calc(100vh - 142px)",
              overflowY: "auto",
            }}
          >
            {ideas && ideas.length > 0 ? (
              ideas
                .filter((idea) => {
                  if (query === "") {
                    return idea;
                  } else if (
                    idea.status.toLowerCase().includes(query.toLowerCase()) ||
                    idea.noIdea.toLowerCase().includes(query.toLowerCase()) ||
                    idea.name.toLowerCase().includes(query.toLowerCase()) ||
                    idea.title.toLowerCase().includes(query.toLowerCase())
                  ) {
                    return idea;
                  }
                })
                .map((idea) => (
                  <CardIdea key={idea.id}>
                    <div className="idea-icon">
                      <div>
                        <img
                          src={require("../../assets/" + idea.categoryIcon)}
                          alt=""
                        />
                      </div>
                      <h1>{idea.categoryDesc}</h1>
                    </div>
                    <div className="idea-content">
                      <div>
                        <FormGroupCol>
                          <label>No. Idea</label>
                          <>:</>
                          <p>{idea.noIdea}</p>
                        </FormGroupCol>
                        <FormGroupCol>
                          <label>Nama</label>
                          <>:</>
                          <p>{idea.name}</p>
                        </FormGroupCol>
                        <FormGroupCol>
                          <label>NIK</label>
                          <>:</>
                          <p>{idea.nik}</p>
                        </FormGroupCol>
                        <FormGroupCol>
                          <label>Judul</label>
                          <>:</>
                          <p>{idea.title}</p>
                        </FormGroupCol>
                        <div className="idea-content-policy">
                          {/* <i className={"fa fa-circle " + idea.policyIcon}></i> */}
                          <div>
                            <CircleIcon
                              style={{ color: idea.policyIcon }}
                            ></CircleIcon>
                          </div>
                          <p>{idea.policyDesc}</p>
                        </div>
                        <div className="idea-content-date">
                          <Status
                            style={
                              idea.status === "Level 2"
                                ? {
                                    color: "#FFA500",
                                    backgroundColor: "rgb(255,165,0, 0.2)",
                                  }
                                : idea.status === "Level 3"
                                ? {
                                    padding: "0px 10px",
                                    marginRight: "5px",
                                    borderRadius: "5px",
                                    color: "#0000FF",
                                    backgroundColor: "rgb(0,0,255, 0.2)",
                                  }
                                : idea.status === "Tinjau Ulang Level 2"
                                ? {
                                    padding: "0px 10px",
                                    marginRight: "5px",
                                    borderRadius: "5px",
                                    color: "#FF00FF",
                                    backgroundColor: "rgb(255,0,255, 0.2)",
                                  }
                                : {
                                    padding: "0px 10px",
                                    marginRight: "5px",
                                    borderRadius: "5px",
                                    color: "#FF0000",
                                    backgroundColor: "rgb(255,0,0, 0.2)",
                                  }
                            }
                          >
                            {idea.status}
                          </Status>
                          <p>
                            Terakhir Ubah{" "}
                            {FormatDate(new Date(idea.updateDate))}
                          </p>
                        </div>
                      </div>
                      <div>
                        <NavLink
                          to={
                            idea.idNilai === 0
                              ? "/checkidea/" + Encrypt(idea.id.toString())
                              : "/summaryidea/" + Encrypt(idea.id.toString())
                          }
                        >
                          {/* <i className="fas fa-angle-right"></i> */}
                          <ChevronRightIcon></ChevronRightIcon>
                        </NavLink>
                      </div>
                    </div>
                  </CardIdea>
                ))
            ) : (
              <div style={{ textAlign: "center" }}>Tidak ada data</div>
            )}
          </div>
        </div>
        {/* <div className="card-footer"></div> */}
      </div>
    </>
  );
};

export default PenilaianIdeas;
