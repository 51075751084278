import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import IdeaServices from "../../services/IdeaServices";
import "./PTableIdea.css";
import { Label, GroupCol, Tahun } from "../element/GlobalStyle";
import jwt from "jwt-decode";

const PTableLapTotalIdeCategoryUser = () => {
  const token = localStorage.getItem("token");
  const user = jwt(token);
  const nik = user.status === "Admin" ? "All" : user.username;

  const [ideas, setIdeas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [selectedYear, setSelectedYear] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const tgl = new Date();
    setSelectedYear(tgl);
  }, []);

  useEffect(() => {
    if (selectedYear) {
      // setLoading(true);
      async function getAllIdeas() {
        setLoading(true);
        try {
          const res = await IdeaServices.getIdeaTotalIdeCategory(
            selectedYear.getFullYear(),
            nik
          );
          setIdeas(res.data.data);
        } catch (err) {
          console.log(err);
          setErrors(err);
        } finally {
          setLoading(false);
        }
      }
      getAllIdeas();
    }
  }, [selectedYear, nik]);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        navigate("/login");
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  const onYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        <GroupCol>
          <Label
            style={{
              marginRight: "10px",
            }}
          >
            Tahun
          </Label>
          <Tahun
            id="year"
            value={selectedYear}
            onChange={(e) => onYearChange(e)}
            view="year"
            dateFormat="yy"
          />
        </GroupCol>
      </div>
      <DataTable
        value={ideas}
        scrollable
        loading={loading}
        scrollDirection="both"
        className="header-class"
        rowClassName="row-class-report"
        emptyMessage="Data tidak ditemukan"
      >
        <Column
          field="category"
          header="Kategori"
          style={{ flexGrow: 1, flexBasis: "90px" }}
          frozen
        ></Column>
        <Column
          field="jan"
          header="Jan"
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="feb"
          header="Feb"
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="mar"
          header="Mar"
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="apr"
          header="Apr"
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="may"
          header="May"
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="jun"
          header="Jun"
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="jul"
          header="Jul"
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="aug"
          header="Aug"
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="sep"
          header="Sep"
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="oct"
          header="Oct"
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="nov"
          header="Nov"
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column field="dec" header="Dec"></Column>
      </DataTable>
    </div>
  );
};

export default PTableLapTotalIdeCategoryUser;
