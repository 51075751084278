import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import IdeaServices from "../../services/IdeaServices";
import "./PTableIdea.css";
import { Label, GroupCol, Tahun } from "../element/GlobalStyle";
import jwt from "jwt-decode";

const PTableLapTotalIdeRupiahUser = () => {
  const token = localStorage.getItem("token");
  const user = jwt(token);
  const nik = user.status === "Admin" ? "All" : user.username;

  const [ideas, setIdeas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");

  useEffect(() => {
    const tgl = new Date();

    setSelectedYear(tgl);
  }, []);

  useEffect(() => {
    if (selectedYear) {
      setLoading(true);
      async function getAllIdeas() {
        setLoading(true);
        try {
          const res = await IdeaServices.getIdeaTotalIdeRupiah(
            selectedYear.getFullYear(),
            nik
          );
          setIdeas(res.data.data);
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      }
      getAllIdeas();
    }
  }, [selectedYear, nik]);

  const onYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const rewardjanTemplate = (rowData) => {
    return <span>{rowData.jan.toLocaleString("en-US")}</span>;
  };

  const rewardfebTemplate = (rowData) => {
    return <span>{rowData.feb.toLocaleString("en-US")}</span>;
  };

  const rewardmarTemplate = (rowData) => {
    return <span>{rowData.mar.toLocaleString("en-US")}</span>;
  };

  const rewardaprTemplate = (rowData) => {
    return <span>{rowData.apr.toLocaleString("en-US")}</span>;
  };

  const rewardmayTemplate = (rowData) => {
    return <span>{rowData.may.toLocaleString("en-US")}</span>;
  };

  const rewardjunTemplate = (rowData) => {
    return <span>{rowData.jun.toLocaleString("en-US")}</span>;
  };

  const rewardjulTemplate = (rowData) => {
    return <span>{rowData.jul.toLocaleString("en-US")}</span>;
  };

  const rewardaugTemplate = (rowData) => {
    return <span>{rowData.aug.toLocaleString("en-US")}</span>;
  };

  const rewardsepTemplate = (rowData) => {
    return <span>{rowData.sep.toLocaleString("en-US")}</span>;
  };

  const rewardoctTemplate = (rowData) => {
    return <span>{rowData.oct.toLocaleString("en-US")}</span>;
  };

  const rewardnovTemplate = (rowData) => {
    return <span>{rowData.nov.toLocaleString("en-US")}</span>;
  };

  const rewarddecTemplate = (rowData) => {
    return <span>{rowData.dec.toLocaleString("en-US")}</span>;
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        <GroupCol>
          <Label
            style={{
              marginRight: "10px",
            }}
          >
            Tahun
          </Label>
          <Tahun
            id="year"
            value={selectedYear}
            onChange={(e) => onYearChange(e)}
            view="year"
            dateFormat="yy"
          />
        </GroupCol>
      </div>
      <DataTable
        value={ideas}
        scrollable
        loading={loading}
        scrollDirection="both"
        responsiveLayout="scroll"
        className="header-class"
        rowClassName="row-class-report"
        emptyMessage="Data tidak ditemukan"
      >
        <Column
          field="jan"
          header="Jan"
          body={rewardjanTemplate}
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="feb"
          header="Feb"
          body={rewardfebTemplate}
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="mar"
          header="Mar"
          body={rewardmarTemplate}
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="apr"
          header="Apr"
          body={rewardaprTemplate}
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="may"
          header="May"
          body={rewardmayTemplate}
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="jun"
          header="Jun"
          body={rewardjunTemplate}
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="jul"
          header="Jul"
          body={rewardjulTemplate}
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="aug"
          header="Aug"
          body={rewardaugTemplate}
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="sep"
          header="Sep"
          body={rewardsepTemplate}
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="oct"
          header="Oct"
          body={rewardoctTemplate}
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="nov"
          header="Nov"
          body={rewardnovTemplate}
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
        <Column
          field="dec"
          header="Dec"
          body={rewarddecTemplate}
          style={{ flexGrow: 1, flexBasis: "50px" }}
        ></Column>
      </DataTable>
    </div>
  );
};

export default PTableLapTotalIdeRupiahUser;
