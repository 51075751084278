import "./App.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Login from "./components/pages/Login";
import Beranda from "./components/pages/Beranda";
import Menu from "./components/pages/Menu";
import MyIdeas from "./components/pages/MyIdeas";
import NilaiIdeas from "./components/pages/PenilaianIdeas";
import Profile from "./components/pages/Profile";
import InputIdea from "./components/pages/InputIdea";
import ViewIdea from "./components/pages/ViewIdea";
import CheckIdea from "./components/pages/CheckIdea";
import SummaryIdea from "./components/pages/SummaryIdea";
import UserList from "./components/pages/UserList";
import IdeaList from "./components/pages/IdeaList";
import Error from "./components/pages/Error";
import { AuthProvider } from "./components/auth";
import RequireAuth from "./components/RequireAuth";
import NotFound from "./components/pages/NotFound";
import UserRoleList from "./components/pages/UserRoleList";
import LapTotalIde from "./components/pages/LapTotalIde";
import LapPayReward from "./components/pages/LapPayReward";
import LapPayRewardPGA from "./components/pages/LapPayRewardPGA";
import Berita from "./components/pages/Berita";
import LapPayRewardHistory from "./components/pages/LapPayRewardHistory";
import LapPayRewardHistoryId from "./components/pages/LapPayRewardHistoryId";
import Maintenance from "./components/pages/Maintenance";
import UploadGambarIdea from "./components/pages/UploadGambarIdea";
import ModuleList from "./components/pages/ModuleList";
import HistoryLoginList from "./components/pages/HistoryLoginList";

function App() {
  console.log("render App");

  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route
              element={
                <RequireAuth>
                  <Navbar />
                </RequireAuth>
              }
            >
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Beranda />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/menu"
                element={
                  <RequireAuth>
                    <Menu />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/myideas"
                element={
                  <RequireAuth>
                    <MyIdeas />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/inputidea"
                element={
                  // <RequireAuth>
                  //   <InputIdea />
                  // </RequireAuth>
                  <InputIdea />
                }
              ></Route>
              <Route
                path="/viewidea/:id"
                element={
                  <RequireAuth>
                    <ViewIdea />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/editidea/:id"
                element={
                  <RequireAuth>
                    <InputIdea />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/nilaiideas"
                element={
                  <RequireAuth>
                    <NilaiIdeas />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/checkidea/:id"
                element={
                  <RequireAuth>
                    <CheckIdea />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/summaryidea/:id"
                element={
                  <RequireAuth>
                    <SummaryIdea />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/listuser"
                element={
                  <RequireAuth>
                    <UserList />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/listuserrole"
                element={
                  <RequireAuth>
                    <UserRoleList />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/listidea"
                element={
                  <RequireAuth>
                    <IdeaList />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/profile"
                element={
                  <RequireAuth>
                    <Profile />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/laptotalide"
                element={
                  <RequireAuth>
                    <LapTotalIde />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/lappayreward"
                element={
                  <RequireAuth>
                    <LapPayReward></LapPayReward>
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/lappayrewardpga"
                element={
                  <RequireAuth>
                    <LapPayRewardPGA></LapPayRewardPGA>
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/lappayrewardhis"
                element={
                  <RequireAuth>
                    <LapPayRewardHistory></LapPayRewardHistory>
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/lappayrewardhis/:id"
                element={
                  <RequireAuth>
                    <LapPayRewardHistoryId />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/listberita"
                element={
                  <RequireAuth>
                    <Berita></Berita>
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/uploadimgidea"
                element={
                  <RequireAuth>
                    <UploadGambarIdea></UploadGambarIdea>
                  </RequireAuth>
                }
              ></Route>
              <Route path="/error" element={<Error />}></Route>
              <Route path="/maintenance" element={<Maintenance />}></Route>
              <Route path="/listmodule" element={<ModuleList />}></Route>
              <Route path="/listhistory" element={<HistoryLoginList />}></Route>
            </Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
