import React, { useState, useEffect, useRef } from "react";
import Moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import IdeaServices from "../../services/IdeaServices";
import "./PTableIdea.css";
import { Label, GroupRow } from "../element/GlobalStyle";
import styled from "styled-components";
import jwt from "jwt-decode";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { NavLink } from "react-router-dom";
import { Encrypt } from "../utils/UrlCrypt";

const ColGroup = styled.div`
  display: flex;
  @media screen and (max-width: ${(props) => props.maxWidth}) {
    flex-direction: column;
  }
`;

const PTableLapTotalIdeBatal = () => {
  const token = localStorage.getItem("token");
  const user = jwt(token);
  const status = user.status;
  const level = user.level;
  const nik = status === "Admin" ? "All" : user.username;

  const today = new Date();
  var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const [ideas, setIdeas] = useState([]);
  const [loading, setLoading] = useState(false);
  const dt = useRef(null);
  const [dateDari, setDateDari] = useState(firstDay);
  const [dateSampai, setDateSampai] = useState(lastDay);
  const [selectedFactory, setSelectedFactory] = useState({
    name: "All",
    code: 0,
  });

  const [filter, setFilter] = useState(null);

  useEffect(() => {
    if (dateDari && dateSampai) {
      setFilter({
        dateFrom: dateDari.toDateString(),
        dateTo: dateSampai.toDateString(),
        nik: nik,
        site: selectedFactory.code,
        status: status,
        level: level,
      });
    }
  }, [dateDari, dateSampai, nik, selectedFactory, status, level]);

  useEffect(() => {
    if (filter) {
      setLoading(true);
      async function getAllIdeas() {
        setLoading(true);
        try {
          const res = await IdeaServices.getIdeaTotalIdeBatal(filter);
          setIdeas(res.data.data);
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      }
      getAllIdeas();
    }
  }, [filter]);

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      let dataExport = [];
      console.log(ideas);
      ideas.map((idea) => {
        const data = {
          Dept: idea.dept,
          Tgl: formatDate(new Date(idea.date)),
          NIK: idea.nik,
          Nama: idea.nama,
          NoIde: idea.noIdea,
          Judul: idea.title,
          Alasan: idea.alasanDitolak,
        };
        return dataExport.push(data);
      });
      const worksheet = xlsx.utils.json_to_sheet(dataExport);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Per Pengembangan QCC");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default("l", "mm", [297, 210]);
        doc.autoTable(exportColumns, ideas);
        doc.save("Per Dibatalkan.pdf");
      });
    });
  };

  const cols = [
    { field: "dept", header: "Dept" },
    { field: "date", header: "Tgl" },
    { field: "nik", header: "NIK" },
    { field: "nama", header: "Nama" },
    { field: "noIdea", header: "No Ide" },
    { field: "title", header: "Judul" },
    { field: "alasanDitolak", header: "Alasan" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const factories = [
    { name: "All", code: 0 },
    { name: "TNG", code: 1 },
    { name: "CKR", code: 3 },
  ];

  const onFactoryChange = (e) => {
    setSelectedFactory(e.value);
  };

  function formatDate(value) {
    return Moment(value).format("DD MMM YYYY");
  }

  const tanggalTemplate = (rowData) => {
    return (
      <span style={{ wordBreak: "keep-all" }}>
        {formatDate(new Date(rowData.date))}
      </span>
    );
  };

  const noideaTemplate = (rowData) => {
    return (
      <NavLink to={"/viewidea/" + Encrypt(rowData.id.toString())}>
        {rowData.noIdea}
      </NavLink>
    );
  };

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={options.filterClearCallback}
        className="p-button-secondary"
      ></Button>
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={options.filterApplyCallback}
        className="p-button-success"
      ></Button>
    );
  };

  return (
    <div>
      <ColGroup maxWidth={status === "Admin" ? "410px" : "315px"}>
        <div
          style={{
            display: "flex",
            marginBottom: "5px",
          }}
        >
          <GroupRow>
            <Label>Dari tgl</Label>
            <Calendar
              id="dtdari"
              value={dateDari}
              onChange={(e) => setDateDari(e.value)}
              dateFormat="dd-mm-yy"
              maxDate={dateSampai}
              readOnlyInput
              inputStyle={{
                fontSize: "0.8rem",
              }}
              style={{
                minWidth: "90px",
                marginTop: "2px",
                marginRight: "10px",
              }}
            />
          </GroupRow>
          <GroupRow>
            <Label>s/d</Label>
            <Calendar
              id="dtsampai"
              value={dateSampai}
              onChange={(e) => setDateSampai(e.value)}
              dateFormat="dd-mm-yy"
              minDate={dateDari}
              readOnlyInput
              inputStyle={{
                fontSize: "0.8rem",
              }}
              style={{
                minWidth: "90px",
                marginTop: "2px",
                marginRight: "10px",
              }}
            />
          </GroupRow>
        </div>
        {status === "Admin" || level > 1 ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <GroupRow>
              <Label>Factory</Label>
              <Dropdown
                panelStyle={{ fontSize: "0.8rem" }}
                optionLabel="name"
                value={selectedFactory}
                options={factories}
                onChange={onFactoryChange}
                placeholder="Pilih factory"
                style={{
                  marginTop: "2px",
                  marginRight: "10px",
                }}
              />
            </GroupRow>

            {status === "Admin" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  style={{
                    marginRight: "10px",
                  }}
                  type="button"
                  icon="pi pi-file-excel"
                  onClick={exportExcel}
                  className="p-button-success mr-2"
                  tooltip="XLS"
                  tooltipOptions={{ position: "bottom" }}
                />
                <Button
                  type="button"
                  icon="pi pi-file-pdf"
                  onClick={exportPdf}
                  className="p-button-warning mr-2"
                  tooltip="PDF"
                  tooltipOptions={{ position: "bottom" }}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </ColGroup>
      <DataTable
        value={ideas}
        loading={loading}
        sortMode="single"
        sortField="dept"
        sortOrder={1}
        responsiveLayout="scroll"
        className="header-class-report"
        rowClassName="row-class-report"
        ref={dt}
        emptyMessage="Data tidak ditemukan"
        removableSort
      >
        <Column
          field="dept"
          header="Dept"
          style={{ minWidth: "90px", maxWidth: "90px" }}
          frozen
          sortable
          filter
          filterPlaceholder="Cari dept"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="date"
          header="Tgl Dibatalkan"
          body={tanggalTemplate}
          style={{ minWidth: "200px", maxWidth: "200px" }}
          sortable
          filter
          filterPlaceholder="Cari tgl dibatalkan"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="approve"
          header="Nama Pimpinan Kerja Yang Cancel Ide"
          style={{ minWidth: "250px", maxWidth: "300px" }}
          sortable
          filter
          filterPlaceholder="Cari approval"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="nik"
          header="NIK"
          style={{ minWidth: "100px", maxWidth: "100px" }}
          sortable
          filter
          filterPlaceholder="Cari NIK"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="nama"
          header="Nama"
          style={{ minWidth: "200px", maxWidth: "300px" }}
          sortable
          filter
          filterPlaceholder="Cari nama"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="noIdea"
          header="No Ide"
          body={noideaTemplate}
          style={{ minWidth: "120px", maxWidth: "120px" }}
          sortable
          filter
          filterPlaceholder="Cari no ide"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="title"
          header="Judul Ide"
          style={{ minWidth: "500px" }}
          filter
          filterPlaceholder="Cari judul"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="alasanDitolak"
          header="Alasan Dibatalkan"
          style={{ minWidth: "350px" }}
          filter
          filterPlaceholder="Cari alasan dibatalkan"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
      </DataTable>
    </div>
  );
};

export default PTableLapTotalIdeBatal;
