import React from "react";
import PTableUserRole from "../table/PTableUserRole";

const UserRoleList = () => {
  return (
    <div className="main-container">
      <div className="card-header">
        <h3>Data User Role</h3>
      </div>
      <div className="card-body without-footer">
        <PTableUserRole></PTableUserRole>
      </div>
    </div>
  );
};

export default UserRoleList;
