import React, { useRef } from "react";
import styled from "styled-components";
// import { Image } from "primereact/image";

const StyleImageIdea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  border: 1px solid var(--color-border);
  // padding-bottom: 5px;

  h3 {
    background-color: var(--color-primary);
    width: 100%;
    text-align: center;
    color: #fff;
  }

  // button {
  //   padding: 2px 15px;
  //   margin: 0px 5px;
  //   border-radius: 5px;
  //   outline: none;
  //   border: none;
  //   font-size: var(--font-size-text);
  //   cursor: pointer;
  //   background-color: #ffdb58;
  // }
`;

const Button = styled.button`
  padding: 2px 15px;
  margin: 0px 5px;
  border-radius: 5px;
  outline: none;
  border: none;
  font-size: var(--font-size-text);
  cursor: pointer;
  background-color: #ffdb58;
`;

const StyleImageStandar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flext-grow: 1;
  padding-bottom: 5px;
  img {
    width: 100px;
    height: 100px;
    margin: 5px;
  }

  input {
    margin: 5px 0;
    display: none;
  }
`;

const defaultImg = require("../../assets/adddoc.png");

const DocumentIdea = ({ title, img, handleRemove, handleReview, ...rest }) => {
  const target = useRef(null);
  // const targetView = useRef(null);
  // const [reviewURL, setRiviewURL] = useState(
  //   require("../../assets/document.png")
  // );

  const reset = () => {
    target.current.value = null;
    handleRemove();
  };

  // const review = () => {
  //   // targetView.current.click();
  //   // handleRemove();
  //   if (targetView.current) {
  //     targetView.current.click();
  //   }
  // };

  // useEffect(() => {
  //   console.log(target);
  //   console.log(targetView);
  //   if (targetView.current) {
  //     targetView.current.click();
  //   }
  // }, [targetView]);

  return (
    <StyleImageIdea>
      <h3>{title}</h3>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          // width: "100%",
        }}
      >
        <StyleImageStandar>
          {img ? (
            <img src={img} alt=""></img>
          ) : (
            <img src={defaultImg} alt=""></img>
          )}
          <input ref={target} type="file" accept="image/*" {...rest} />
          <div>
            <Button
              type="button"
              onClick={() => target.current.click()}
              {...rest}
            >
              Ambil
            </Button>
            <Button type="button" onClick={() => reset()} {...rest}>
              Hapus
            </Button>
          </div>
        </StyleImageStandar>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: "1",
          }}
        >
          <img
            style={{ height: "60px", marginBottom: "5px", cursor: "pointer" }}
            src={require("../../assets/document.png")}
            alt=""
            onClick={() => handleReview()}
          ></img>
          {/* <Image
            // src={reviewURL}
            src={require("../../assets/reviewdoc.jpg")}
            alt="Format"
            imageStyle={{
              height: "60px",
              marginBottom: "5px",
              cursor: "pointer",
            }}
            preview
            ref={targetView}
            // onClick={() => setRiviewURL(require("../../assets/reviewdoc.jpg"))}
            // onHide={() => setRiviewURL(require("../../assets/document.png"))}
            // onLoad={(event) => (event.target.style.display = "none")}
            // onHide
          /> */}
          <p style={{ fontSize: "0.6rem", fontStyle: "italic" }}>
            Silahkan menghubungi Admin / Pimpinan kerja, jika ingin membuat
            standarisasi.
          </p>
        </div>
      </div>
    </StyleImageIdea>
  );
};

export default DocumentIdea;
