import React, { useState, useRef, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Moment from "moment";
import { FaEye } from "react-icons/fa";
import styled from "styled-components";
import PaymentServices from "../../services/PaymentServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// import { Dropdown } from "primereact/dropdown";

const SearchEye = styled(FaEye)`
  color: gray;
  font-size: 0.9rem;
  cursor: pointer;
`;

const PTableLapPayRewardHistory = () => {
  const [payIdeas, setPayIdeas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const dt = useRef(null);
  // const [selectedIde, setSelectedIde] = useState(null);
  // const [selectedFactory, setSelectedFactory] = useState({
  //   name: "All",
  //   code: 0,
  // });

  const navigate = useNavigate();

  useEffect(() => {
    async function getPayIdea() {
      setLoading(true);
      try {
        const res = await PaymentServices.getPayIdea();
        setPayIdeas(res.data.data);
      } catch (err) {
        // console.log(err);
        setErrors(err);
      } finally {
        setLoading(false);
      }
    }
    getPayIdea();
  }, []);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        navigate("/login");
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  // const exportExcel = () => {
  //   import("xlsx").then((xlsx) => {
  //     let dataExport = [];
  //     payIdeas.map((idea) => {
  //       const data = {
  //         NIK: idea.nik,
  //         Nama: idea.nama,
  //         Dept: idea.dept,
  //         Rupiah: idea.rupiah,
  //       };
  //       return dataExport.push(data);
  //     });
  //     const worksheet = xlsx.utils.json_to_sheet(dataExport);
  //     const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  //     const excelBuffer = xlsx.write(workbook, {
  //       bookType: "xlsx",
  //       type: "array",
  //     });
  //     saveAsExcelFile(excelBuffer, "Payment Reward");
  //   });
  // };

  // const saveAsExcelFile = (buffer, fileName) => {
  //   import("file-saver").then((module) => {
  //     if (module && module.default) {
  //       let EXCEL_TYPE =
  //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //       let EXCEL_EXTENSION = ".xlsx";
  //       const data = new Blob([buffer], {
  //         type: EXCEL_TYPE,
  //       });

  //       module.default.saveAs(
  //         data,
  //         fileName + "_" + new Date().getTime() + EXCEL_EXTENSION
  //       );
  //     }
  //   });
  // };

  // const exportPdf = () => {
  //   import("jspdf").then((jsPDF) => {
  //     import("jspdf-autotable").then(() => {
  //       const doc = new jsPDF.default(0, 0);
  //       doc.autoTable(exportColumns, payIdeas);
  //       doc.save("Payment Reward.pdf");
  //     });
  //   });
  // };

  // const cols = [
  //   { field: "nik", header: "NIK" },
  //   { field: "nama", header: "Nama" },
  //   { field: "dept", header: "Dept" },
  //   { field: "rupiah", header: "Rupiah" },
  // ];

  // const exportColumns = cols.map((col) => ({
  //   title: col.header,
  //   dataKey: col.field,
  // }));

  // const factories = [
  //   { name: "All", code: 0 },
  //   { name: "TNG", code: 1 },
  //   { name: "CKR", code: 3 },
  // ];

  // const onFactoryChange = (e) => {
  //   setSelectedFactory(e.value);
  // };

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={options.filterClearCallback}
        className="p-button-secondary"
      ></Button>
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={options.filterApplyCallback}
        className="p-button-success"
      ></Button>
    );
  };

  function formatDate(value) {
    return Moment(value).format("DD-MM-YYYY");
  }

  function formatPeriode(value) {
    return Moment(value).format("MM-YYYY");
  }

  const periodeTemplate = (rowData) => {
    return (
      <span style={{ wordBreak: "keep-all" }}>
        {formatPeriode(new Date(rowData.periode))}
      </span>
    );
  };

  const tglMulaiTemplate = (rowData) => {
    return (
      <span style={{ wordBreak: "keep-all" }}>
        {formatDate(new Date(rowData.tglMulai))}
      </span>
    );
  };

  const tglSampaiTemplate = (rowData) => {
    return (
      <span style={{ wordBreak: "keep-all" }}>
        {formatDate(new Date(rowData.tglSampai))}
      </span>
    );
  };

  const rupiahTemplate = (rowData) => {
    return <span>{rowData.totalRupiah.toLocaleString("en-US")}</span>;
  };

  const lihatTemplate = (rowData) => {
    return (
      <NavLink to={"/lappayrewardhis/" + rowData.id}>
        <SearchEye></SearchEye>
      </NavLink>
    );
  };

  const payrollTemplate = (rowData) => {
    return (
      <input
        style={{ width: "auto" }}
        type="checkbox"
        value={rowData.payProses}
        checked={rowData.payProses}
        readOnly
      />
    );
  };

  return (
    <div>
      <DataTable
        value={payIdeas}
        scrollable
        loading={loading}
        scrollDirection="both"
        className="header-class"
        rowClassName="row-class-report"
        ref={dt}
        removableSort
        // selectionMode="single"
        // selection={selectedIde}
        // onSelectionChange={(e) => setSelectedIde(e.value)}
        emptyMessage="Data tidak ditemukan"
      >
        <Column
          header="Lihat"
          headerStyle={{ width: "4em" }}
          style={{ width: "4em" }}
          body={lihatTemplate}
          align="center"
        ></Column>
        <Column
          field="periode"
          header="Periode"
          body={periodeTemplate}
          style={{ flexGrow: 1, flexBasis: "120px" }}
          sortable
          filter
          filterPlaceholder="Cari periode"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="tglMulai"
          header="Dari Tgl"
          body={tglMulaiTemplate}
          style={{ flexGrow: 1, flexBasis: "120px" }}
          sortable
          filter
          filterPlaceholder="Cari dari tgl"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="tglSampai"
          header="Sampai Tgl"
          body={tglSampaiTemplate}
          style={{ flexGrow: 1, flexBasis: "140px" }}
          sortable
          filter
          filterPlaceholder="Cari sampai tgl"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="totalIde"
          header="Total Ide"
          style={{ flexGrow: 1, flexBasis: "100px" }}
          align="right"
          sortable
        ></Column>
        <Column
          field="totalRupiah"
          header="Total Rupiah"
          body={rupiahTemplate}
          style={{ flexGrow: 1, flexBasis: "115px" }}
          align="right"
          sortable
          // footer={"Total:"}
          // footerStyle={{ padding: "5px 7px" }}
        ></Column>
        <Column
          field="payProses"
          header="Proses Payroll"
          body={payrollTemplate}
          style={{ flexGrow: 1, flexBasis: "100px" }}
          sortable
          filter
          filterPlaceholder="Cari proses payroll"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
      </DataTable>
    </div>
  );
};

export default PTableLapPayRewardHistory;
