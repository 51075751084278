import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import Divider from "../element/Divider";
import ConfirmModal from "../modal/ConfirmModal";
import LoadingModal from "../modal/LoadingModal";
import MessageModal from "./MessageModal";
import UserServices from "../../services/UserServices";
import SettingServices from "../../services/SettingServices";

const Background = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 300px;
  height: auto;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 394px) {
    width: 100vw;
  }
`;

const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 25px 10px 25px;
  color: var(--color-primary);
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: #474747;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px 25px;
`;

const Label = styled.label`
  font-size: var(--font-size-text);
  font-weight: bold;
  margin: 5px 0px 2px 0px;
`;

const Input = styled.input`
  display: inline;
  width: 100%;
  padding: 5px 8px;
  border: 1px solid silver;
  border-radius: 5px;
  font-size: var(--font-size-text);
  transition: 0.3s;
  outline: none;
  &:focus {
    outline: none;
    border: 1px solid var(--color-primary);
    background-color: #fff;
  }
`;

const Select = styled.select`
  display: inline;
  width: 100%;
  padding: 5px 8px;
  border: 1px solid silver;
  border-radius: 5px;
  font-size: var(--font-size-text);
  transition: 0.3s;
  outline: none;
  &:focus {
    outline: none;
    border: 1px solid var(--color-primary);
    background-color: #fff;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 25px 10px 25px;
`;

const Button = styled.button`
  padding: 5px 15px;
  border-radius: 15px;
  outline: none;
  border: none;
  font-size: var(--font-size-text);
  color: #fff;
  cursor: pointer;
  background-color: var(--color-primary);
  margin: 5px;
`;

const Message = styled.i`
  color: red;
  font-size: 11px;
`;

const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-text);
  margin-top: 5px;
`;

function UserModal({ setUserModal, id, getAllUsers }) {
  const [isChecked, setIsChecked] = useState(true);
  const [isCheckedPass, setIsCheckedPass] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const initialValues = {
    id: 0,
    username: "",
    nama: "",
    codeDept: "",
    dept: "",
    codeSect: "",
    sect: "",
    alias: "",
    site: "",
    level: 0,
    status: "User",
    password: "",
    repassword: "",
    aktif: true,
  };

  const [response, setResponse] = useState(null);
  const [msg, setMsg] = useState("");
  const [msgConfirm, setMsgConfirm] = useState("");
  const [errors, setErrors] = useState(null);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  // const [isExist, setIsExist] = useState(false);
  const [userExist, setUserExist] = useState(null);
  const [errorUser, setErrorUser] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [msgModal, setMsgModal] = useState("");
  const [msgStatus, setMsgStatus] = useState(true);
  const [user, setUser] = useState(null);
  const [listDept, setListDept] = useState(null);
  const [listSect, setListSect] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    async function getDept() {
      //setLoadingModal(true);
      try {
        const res = await SettingServices.getDept();
        setListDept(res.data.data);
      } catch (err) {}
      //setLoadingModal(false);
    }
    getDept();
  }, []);

  useEffect(() => {
    setMsgConfirm("Apakah yakin ingin simpan data?");
    if (id) {
      async function getUserById() {
        setLoadingModal(true);
        try {
          const res = await UserServices.getUserById(id);
          setUser(res.data.data);
        } catch (err) {}
        setLoadingModal(false);
      }
      getUserById();
      setIsCheckedPass(false);
      setIsReadOnly(true);
      setMsgConfirm("Apakah yakin ingin ubah data?");
    }
  }, [id]);

  useEffect(() => {
    if (user) {
      setFormValues({
        id: user.id,
        username: user.username,
        nama: user.nama || "",
        codeDept: user.codeDept || "",
        dept: user.dept || "",
        codeSect: user.codeSect || "",
        sect: user.sect || "",
        alias: user.alias || "",
        site: user.site || "",
        level: user.level,
        status: user.status,
        password: "",
        repassword: "",
        aktif: user.aktif,
      });
      setIsChecked(user.aktif);
      getListSect(user.codeDept);
    }
  }, [user]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit && !errorUser) {
      setConfirmModal(true);
    }
  }, [formErrors, isSubmit, errorUser]);

  useEffect(() => {
    if (errors) {
      navigate("/error");
      localStorage.setItem("path", "/error");
    }
  }, [errors, navigate]);

  useEffect(() => {
    if (response) {
      setMsgModal(true);
      setMsgStatus(response.status);
      setMsg(response.message);
    }
  }, [response]);

  useEffect(() => {
    setErrorUser(null);
    if (userExist) {
      if (!id && !userExist.status) {
        setErrorUser(userExist.message);
        setIsSubmit(false);
      } else {
        setIsSubmit(true);
      }
    }
  }, [userExist]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleChangeDept = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      codeDept: value,
      [name]: getDeptDesc(value),
    });
    getListSect(value);
  };

  const handleChangeSect = (e) => {
    const { name, value } = e.target;
    if (value) {
      setFormValues({
        ...formValues,
        codeSect: value,
        [name]: getSectDesc(value),
        alias: getAlias(value),
      });
    }
  };

  const handleChangeStatus = (e) => {
    const { name, value } = e.target;
    if (value) {
      setFormValues({
        ...formValues,
        level: 0,
        [name]: value,
      });
    }
  };

  function getDeptDesc(value) {
    if (!value) {
      return "";
    }
    return listDept.filter((item) => item.codeDept === value)[0].dept;
  }

  function getSectDesc(value) {
    if (!value) {
      return "";
    }
    return listSect.filter((item) => item.codeSect === value)[0].sect;
  }

  function getAlias(value) {
    if (!value) {
      return "";
    }
    return listSect.filter((item) => item.codeSect === value)[0].alias;
  }

  const handleChangeNIK = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value, username: value });
  };

  const handleChangeActive = (e) => {
    setIsChecked(!isChecked);
    setFormValues({ ...formValues, [e.target.name]: !isChecked });
  };

  const handleChangeSetPass = (e) => {
    setIsCheckedPass(!isCheckedPass);
    setFormValues({ ...formValues, [e.target.name]: !isCheckedPass });
    setIsReadOnly(isCheckedPass);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    if (formValues.username) {
      async function checkUser() {
        try {
          const res = await UserServices.getUserByUser(formValues.username);
          setUserExist(res.data);
        } catch (err) {
          setErrors(err);
        } finally {
        }
      }
      checkUser();
    }
  };

  const handleConfirm = () => {
    setLoadingModal(true);
    if (id) {
      if (isCheckedPass) {
        updateUser();
      } else {
        updateUserWithOutPass();
      }
    } else {
      createUser();
    }
  };

  const validate = (values) => {
    // const regex = /[0-9]|\./;
    const errors = {};
    if (values.status === "User" && !values.username) {
      errors.username = "NIK harus diisi!";
    }

    if (values.status === "Admin" && !values.username) {
      errors.username = "Username harus diisi!";
    }

    // if (values.username) {
    //   async function checkUser() {
    //     try {
    //       const res = await UserServices.getUserByUser(values.username);
    //       setIsExist(res.data.status);
    //       console.log("cek");
    //     } catch (err) {
    //       setErrors(err);
    //     } finally {
    //     }
    //   }
    //   checkUser();
    //   // UserServices.getUserByUser(values.username).then((res) => {
    //   //   if (!res.data.status) {
    //   //     errors.username = res.data.message;
    //   //     setIsExist(true);
    //   //   }
    //   // });
    // }

    if (!values.nama) {
      errors.nama = "Nama harus diisi!";
    }

    if (!values.codeDept) {
      errors.dept = "Dept harus diisi!";
    }

    if (!values.codeSect) {
      errors.sect = "Section harus diisi!";
    }

    if (!values.site) {
      errors.site = "Site harus dipilih!";
    }

    if (values.status === "User" && !values.level) {
      errors.level = "Level harus dipilih!";
    }

    if (!values.password && !id) {
      errors.password = "Password harus diisi!";
    } else if (!values.password && id && isCheckedPass) {
      errors.password = "Password harus diisi!";
    }

    if (!values.repassword && !id) {
      errors.repassword = "Ulangi password harus diisi!";
    } else if (!values.repassword && id && isCheckedPass) {
      errors.repassword = "Ulangi password harus diisi!";
    } else if (values.repassword) {
      if (values.password !== values.repassword && !id) {
        errors.repassword = "Ulangi password harus sama dengan password!";
      } else if (values.password !== values.repassword && id && isCheckedPass) {
        errors.repassword = "Ulangi password harus sama dengan password!";
      }
    }

    return errors;
  };

  const createUser = async () => {
    try {
      await UserServices.createUser(formValues);
    } catch (err) {
      setErrors(err);
    } finally {
      setLoadingModal(false);
      setUserModal(false);
      getAllUsers();
    }
  };

  const updateUser = async () => {
    try {
      var res = await UserServices.updateUser(formValues);
      setResponse(res.data);
    } catch (err) {
      setErrors(err);
    } finally {
      setLoadingModal(false);
      setUserModal(false);
      getAllUsers();
    }
  };

  const updateUserWithOutPass = async () => {
    try {
      var res = await UserServices.updateUserWithOutPass(formValues);
      setResponse(res.data);
    } catch (err) {
      setErrors(err);
    } finally {
      setLoadingModal(false);
      setUserModal(false);
      getAllUsers();
    }
  };

  // const checkUser = async () => {
  //   try {
  //     await UserServices.getUserByUser(formValues.username);
  //   } catch (err) {
  //     setErrors(err);
  //   } finally {
  //   }
  // };

  const getListSect = async (dept) => {
    if (dept) {
      async function getSect() {
        //setLoadingModal(true);
        try {
          const res = await SettingServices.getSect(dept);
          setListSect(res.data.data);
        } catch (err) {}
        //setLoadingModal(false);
      }
      getSect();
    } else {
      setListSect(null);
    }
  };

  return (
    <Background>
      {confirmModal && (
        <ConfirmModal
          title="Konfirmasi"
          message={msgConfirm}
          setConfirmModal={setConfirmModal}
          handleConfirm={() => handleConfirm()}
        />
      )}
      {loadingModal && <LoadingModal></LoadingModal>}
      {msgModal && (
        <MessageModal
          status={msgStatus}
          message={msg}
          setShow={setMsgModal}
        ></MessageModal>
      )}
      <form onSubmit={handleSubmit}>
        <ModalContainer>
          <ModalTitle>
            <h3>{!id ? "Buat User" : "Ubah User"} </h3>
            <CloseModalButton
              onClick={() => {
                setUserModal(false);
              }}
            ></CloseModalButton>
          </ModalTitle>
          <Divider></Divider>
          <ModalBody>
            <Status>
              <input
                style={{ width: "auto" }}
                type="radio"
                value={"User"}
                name="status"
                checked={formValues.status === "User"}
                onChange={(e) => handleChangeStatus(e)}
              />
              <p style={{ margin: "0px 10px 0px 3px" }}>User</p>
              <input
                style={{ width: "auto", marginRight: "3px" }}
                type="radio"
                value={"Admin"}
                name="status"
                checked={formValues.status === "Admin"}
                onChange={(e) => handleChangeStatus(e)}
              />
              <p style={{ margin: "0px 0px 0px 3px" }}>Admin</p>
            </Status>
            {formValues.status === "Admin" ? (
              <>
                <Label>
                  Username
                  <span style={{ color: "red" }}> *</span>
                </Label>
                <Input
                  type="text"
                  name="username"
                  value={formValues.username}
                  placeholder="Masukkan Username"
                  onChange={(e) => handleChange(e)}
                  readOnly={id ? true : false}
                ></Input>
                <Message>{formErrors.username}</Message>
                <Message>{errorUser}</Message>
              </>
            ) : null}
            {formValues.status === "User" ? (
              <>
                <Label>
                  NIK
                  <span style={{ color: "red" }}> *</span>
                </Label>
                <Input
                  type="text"
                  name="username"
                  value={formValues.username}
                  placeholder="Masukkan NIK"
                  onChange={(e) => handleChangeNIK(e)}
                  readOnly={id ? true : false}
                ></Input>
                <Message>{formErrors.username}</Message>
                <Message>{errorUser}</Message>
              </>
            ) : null}
            <Label>Nama</Label>
            <Input
              type="text"
              name="nama"
              value={formValues.nama}
              placeholder="Masukkan nama"
              onChange={(e) => handleChange(e)}
              // readOnly={formValues.status === "User" ? true : false}
            ></Input>
            <Message>{formErrors.nama}</Message>
            <Label>Dept</Label>
            {/* <Input
              type="text"
              name="dept"
              value={formValues.dept}
              placeholder="Dept otomatis terisi sesuai NIK"
              onChange={(e) => handleChange(e)}
              // readOnly={formValues.status === "User" ? true : false}
            ></Input> */}
            <Select
              name="dept"
              value={formValues.codeDept}
              onChange={(e) => handleChangeDept(e)}
              placeholder="Pilih departement"
            >
              <option value="">-- Pilih --</option>
              {listDept &&
                listDept.map((dept) => {
                  return (
                    <option key={dept.codeDept} value={dept.codeDept}>
                      {dept.dept}
                    </option>
                  );
                })}
            </Select>
            <Message>{formErrors.dept}</Message>
            <Label>Sect</Label>
            {/* <Input
              type="text"
              name="sect"
              value={formValues.sect}
              placeholder="Section otomatis terisi sesuai NIK"
              onChange={(e) => handleChange(e)}
              // readOnly={formValues.status === "User" ? true : false}
            ></Input> */}
            <Select
              name="sect"
              value={formValues.codeSect}
              onChange={(e) => handleChangeSect(e)}
              placeholder="Pilih section"
            >
              <option value="">-- Pilih --</option>
              {listSect &&
                listSect.map((sect) => {
                  return (
                    <option key={sect.codeSect} value={sect.codeSect}>
                      {sect.sect}
                    </option>
                  );
                })}
            </Select>
            <Message>{formErrors.sect}</Message>
            <Label>Site</Label>
            <Select
              name="site"
              value={formValues.site}
              onChange={(e) => handleChange(e)}
              placeholder="Pilih site"
            >
              <option value="">-- Pilih --</option>
              <option value="TSC1">TSC1</option>
              <option value="TSC3">TSC3</option>
              <option value="TSC3A">TSC3A</option>
              <option value="TSC3B">TSC3B</option>
            </Select>
            <Message>{formErrors.site}</Message>
            <Label>
              Level<span style={{ color: "red" }}> *</span>
            </Label>
            <Select
              name="level"
              value={formValues.level}
              onChange={(e) => handleChange(e)}
              placeholder="Pilih level"
              disabled={formValues.status === "Admin" ? true : false}
            >
              <option value="">-- Pilih --</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Select>
            <Message>{formErrors.level}</Message>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Label>Password</Label>
              <div>
                <input
                  style={{ width: "auto", fontSize: "12px" }}
                  type="checkbox"
                  name="setpass"
                  value={isCheckedPass}
                  checked={isCheckedPass}
                  onChange={(e) => handleChangeSetPass(e)}
                />
                <Label style={{ marginLeft: "5px" }}>Set</Label>
              </div>
            </div>
            <Input
              type="password"
              name="password"
              value={formValues.password}
              placeholder="Masukkan password"
              onChange={(e) => handleChange(e)}
              readOnly={isReadOnly}
            ></Input>
            <Message>{formErrors.password}</Message>
            <Label>Ulangi Password</Label>
            <Input
              type="password"
              name="repassword"
              value={formValues.repassword}
              placeholder="Ulangi password"
              onChange={(e) => handleChange(e)}
              readOnly={isReadOnly}
            ></Input>
            <Message>{formErrors.repassword}</Message>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <input
                style={{ width: "auto" }}
                type="checkbox"
                name="aktif"
                value={isChecked}
                checked={isChecked}
                onChange={(e) => handleChangeActive(e)}
              />
              <Label style={{ marginLeft: "5px" }}>Aktif</Label>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              style={{ backgroundColor: "crimson" }}
              onClick={() => {
                setUserModal(false);
              }}
            >
              Batal
            </Button>
            <Button type="submit">Simpan</Button>
          </ModalFooter>
        </ModalContainer>
      </form>
    </Background>
  );
}

export default UserModal;
