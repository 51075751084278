import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ImageIdea from "../element/ImageIdea";
import IdeaServices from "../../services/IdeaServices";
import configData from "../../config/config.json";
import LoadingModal from "../modal/LoadingModal";
import styled from "styled-components";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { UrlImg } from "../../api/apiFile";
import jwt from "jwt-decode";

const FormgGroup = styled.div`
  display: flex;
  div {
    flex: 50%;
  }
  @media screen and (max-width: 970px) {
    flex-direction: column;
    gap: 5px;
  }
`;

// const Group = styled.div`
//   display: flex;
//   justify-content: space-between;
//   // align-items: stretch;
// `;

const Label = styled.label`
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
`;

const MessageReq = styled.span`
  font-size: 11px;
  color: red;
`;

const UploadGambarIdea = () => {
  const token = localStorage.getItem("token");
  const user = jwt(token);

  const initialValues = {
    id: null,
    noidea: null,
    // nik: null,
    beforeimgname: "",
    afterimgname: "",
    beforeimgsrc: "",
    afterimgsrc: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [ideas, setIdeas] = useState([]);
  const [idea, setIdea] = useState([]);
  const [errors, setErrors] = useState(null);
  const [response, setResponse] = useState(null);

  const toast = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    getIdeas();
  }, []);

  useEffect(() => {
    if (idea.length > 0) {
      setFormValues({
        id: idea[0].id,
        noidea: idea[0].noIdea,
        beforeimgname: idea[0].beforeImgName ? idea[0].beforeImgName : "",
        afterimgname: idea[0].afterImgName ? idea[0].afterImgName : "",
        beforeimgsrc: idea[0].beforeImgSrc && UrlImg + idea[0].beforeImgSrc,
        afterimgsrc: idea[0].afterImgSrc && UrlImg + idea[0].afterImgSrc,
      });
    } else {
      reset();
    }
  }, [idea]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      confirmDialog({
        message: "Apakah yakin ingin upload gambar?",
        header: "Konfirmasi",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Yakin",
        rejectLabel: "Tidak",
        accept: () => updateIdea(),
      });
    }
  }, [formErrors, isSubmit]);

  useEffect(() => {
    if (response) {
      if (response.status) {
        toast.current.show({
          severity: "success",
          summary: "Berhasil",
          detail: response.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Gagal",
          detail: response.message,
          life: 3000,
        });
      }
    }
  }, [response]);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        navigate("/login");
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  const getIdeas = async () => {
    try {
      const response = await IdeaServices.getIdeaByUserDept(user.username);
      setIdeas(response.data.data);
    } catch (err) {
      // console.log(err);
      setErrors(err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const reset = () => {
    setFormValues({
      id: null,
      noidea: null,
      beforeimgname: "",
      afterimgname: "",
      beforeimgfile: null,
      beforeimgsrc: "",
      afterimgsrc: "",
      afterimgfile: null,
    });
  };

  const updateIdea = async () => {
    const formData = new FormData();
    formData.append("id", formValues.id);
    formData.append("noidea", formValues.noidea);
    formData.append("beforeimgfile", formValues.beforeimgfile);
    formData.append("afterimgfile", formValues.afterimgfile);
    formData.append("beforeimgname", formValues.beforeimgname);
    formData.append("afterimgname", formValues.afterimgname);
    formData.append("beforeimgsrc", formValues.beforeimgsrc);
    formData.append("afterimgsrc", formValues.afterimgsrc);

    setLoadingModal(true);
    try {
      const res = await IdeaServices.updateIdeaImage(formData);
      setResponse(res.data);
    } catch (err) {
      setErrors(err);
      // console.log(err);
    } finally {
      setLoadingModal(false);
    }
  };

  const onImgUploadBefore = (e) => {
    if (e.target.files && e.target.files[0]) {
      let beforeimgfile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setFormValues({
          ...formValues,
          beforeimgfile,
          beforeimgsrc: x.target.result,
        });
      };
      reader.readAsDataURL(beforeimgfile);
    } else {
      setFormValues({ ...formValues, beforeimgfile: null, beforeimgsrc: null });
    }
  };

  const removeImgBefore = () => {
    setFormValues({
      ...formValues,
      beforeimgfile: null,
      beforeimgsrc: null,
    });
  };

  const onImgUploadAfter = (e) => {
    if (e.target.files && e.target.files[0]) {
      let afterimgfile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setFormValues({
          ...formValues,
          afterimgfile,
          afterimgsrc: x.target.result,
        });
      };
      reader.readAsDataURL(afterimgfile);
    } else {
      setFormValues({ ...formValues, afterimgfile: null, afterimgsrc: null });
    }
  };

  const removeImgAfter = () => {
    setFormValues({
      ...formValues,
      afterimgfile: null,
      afterimgsrc: null,
    });
  };

  // const onNIKChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues({ ...formValues, [name]: value });
  // };

  const onNoIdeaChange = (e) => {
    // const { name, value } = e.target;
    setFormValues({ ...formValues, id: e.value });
    setIdea(getFilteredList(e.value));
  };

  function getFilteredList(value) {
    if (!value) {
      return [];
    }
    return ideas.filter((item) => item.id === value);
  }

  const validate = (values) => {
    const errors = {};
    // if (!values.nik) {
    //   errors.nik = "Nik harus dipilih!";
    // }

    if (!values.noidea) {
      errors.noidea = "No ide harus dipilih!";
    }

    if (!values.beforeimgsrc) {
      errors.beforeimgsrc = "Gambar sebelum tidak boleh kosong!";
    } else if (formValues.beforeimgfile) {
      const file = Math.round(formValues.beforeimgfile.size / 1024);
      const fileType = formValues.beforeimgfile.type;
      if (!fileType.match(/image/)) {
        errors.beforeimgsrc = "File harus format gambar!";
      } else if (file > configData.MaxImgFile) {
        errors.beforeimgsrc = "Gambar maksimal 2MB!";
      }
    }

    if (!values.afterimgsrc) {
      errors.afterimgsrc = "Gambar sesudah tidak boleh kosong!";
    } else if (formValues.afterimgfile) {
      const file = Math.round(formValues.afterimgfile.size / 1024);
      const fileType = formValues.afterimgfile.type;
      if (!fileType.match(/image/)) {
        errors.afterimgsrc = "File harus format gambar!";
      } else if (file > configData.MaxImgFile) {
        errors.afterimgsrc = "Gambar maksimal 2MB!";
      }
    }
    return errors;
  };

  return (
    <>
      {loadingModal && <LoadingModal></LoadingModal>}
      <Toast
        ref={toast}
        onHide={(e) => {
          reset();
          getIdeas();
        }}
      />
      <ConfirmDialog />
      <div className="main-container">
        <div className="card-header">
          <h3>Upload Gambar Ide</h3>
        </div>
        <div className="card-body">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "0px 5px",
            }}
          >
            <Label>
              No Ide<span style={{ color: "red" }}> *</span>
            </Label>
            <div>
              <Dropdown
                value={formValues.id}
                options={ideas}
                onChange={onNoIdeaChange}
                optionLabel="noIdea"
                optionValue="id"
                filter
                showClear
                filterBy="noIdea"
                placeholder="Pilih No Ide"
                style={{
                  margin: "5px 0px",
                }}
                emptyMessage="Data tidak ditemukan"
                emptyFilterMessage="Data tidak ditemukan"
                panelStyle={{ fontSize: "0.8rem" }}
                resetFilterOnHide
              />
            </div>
            <MessageReq>{formErrors.noidea}</MessageReq>
          </div>
          {/* <Group>
          <div style={{ width: "100%", margin: "0px 5px" }}>
            <Label>
              NIK<span style={{ color: "red" }}> *</span>
            </Label>
            <Dropdown
              name="nik"
              value={formValues.nik}
              options={countries}
              onChange={onNIKChange}
              optionLabel="name"
              filter
              showClear
              filterBy="name"
              placeholder="Pilih NIK"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <MessageReq>{formErrors.nik}</MessageReq>
          </div>
          <div style={{ width: "100%", margin: "0px 5px" }}>
            <Label>
              No Ide<span style={{ color: "red" }}> *</span>
            </Label>
            <Dropdown
              name="noidea"
              value={formValues.noidea}
              options={countries}
              onChange={onNoIdeaChange}
              optionLabel="name"
              filter
              showClear
              filterBy="name"
              placeholder="Pilih No Ide"
              style={{
                marginTop: "5px",
                width: "100%",
              }}
            />
            <MessageReq>{formErrors.noidea}</MessageReq>
          </div>
        </Group> */}
          <FormgGroup>
            <div style={{ width: "100%", margin: "0px 5px" }}>
              <Label>
                Silahkan Unduh Foto/Gambar ! (mak 2MB)
                <span style={{ color: "red" }}> *</span>
              </Label>
              <div className="form-group">
                <div style={{ marginRight: "5px" }}>
                  <ImageIdea
                    title="Sebelum"
                    img={formValues.beforeimgsrc}
                    handleRemove={() => removeImgBefore()}
                    onChange={(e) => onImgUploadBefore(e)}
                  />
                  <MessageReq>{formErrors.beforeimgsrc}</MessageReq>
                </div>
                <div>
                  <ImageIdea
                    title="Sesudah"
                    img={formValues.afterimgsrc}
                    handleRemove={() => removeImgAfter()}
                    onChange={(e) => onImgUploadAfter(e)}
                  />
                  <MessageReq>{formErrors.afterimgsrc}</MessageReq>
                </div>
              </div>
            </div>
          </FormgGroup>
        </div>
        <div className="card-footer align-right">
          <div className="btn-group">
            {/* <NavLink to={"/menu"}>
            <button type="button" className="btn btn-danger">
              Batal
            </button>
          </NavLink> */}
            <button
              type="button"
              className="btn btn-danger"
              onClick={(e) => reset(e)}
            >
              Batal
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadGambarIdea;
