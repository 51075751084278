import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import configData from "../../config/config.json";

export function Encrypt(str) {
  const ciphertext = AES.encrypt(str, configData.Key);
  return encodeURIComponent(ciphertext.toString());
}

export function Decrypt(str) {
  const decodedStr = decodeURIComponent(str);
  return AES.decrypt(decodedStr, configData.Key).toString(enc.Utf8);
}
