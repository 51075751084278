import jwtDecode from "jwt-decode";
import React, { useState } from "react";
import PTableLapTotalIde from "../table/PTableLapTotalIde";
import PTableLapTotalIdeUser from "../table/PTableLapTotalIdeUser";
import { TabView, TabPanel } from "primereact/tabview";
import "./Laporan.css";
import PTableLapTotalIdeCategory from "../table/PTableLapTotalIdeCategory";
import PTableLapTotalIdeCategoryUser from "../table/PTableLapTotalIdeCategoryUser";
import PTableLapTotalIdePolicy from "../table/PTableLapTotalIdePolicy";
import PTableLapTotalIdePolicyUser from "../table/PTableLapTotalIdePolicyUser";
import PTableLapTotalIdeKriteria from "../table/PTableLapTotalIdeKriteria";
import PTableLapTotalIdeKriteriaUser from "../table/PTableLapTotalIdeKriteriaUser";
import PTableLapTotalIdeRupiah from "../table/PTableLapTotalIdeRupiah";
import PTableLapTotalIdeQCC from "../table/PTableLapTotalIdeQCC";
import PTableLapTotalIdeRupiahUser from "../table/PTableLapTotalIdeRupiahUser";
import PTableLapTotalIdeStandar from "../table/PTableLapTotalIdeStandar";
import PTableLapTotalIdeBatal from "../table/PTableLapTotalIdeBatal";

const LapTotalIde = () => {
  const token = localStorage.getItem("token");
  const user = jwtDecode(token);

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="main-container">
      {user.status === "User" && user.level === "1" ? (
        <>
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            scrollable
          >
            <TabPanel header="Total">
              <PTableLapTotalIdeUser></PTableLapTotalIdeUser>
            </TabPanel>
            <TabPanel header="Per Kategori">
              <PTableLapTotalIdeCategoryUser></PTableLapTotalIdeCategoryUser>
            </TabPanel>
            <TabPanel header="Per Kebijakan">
              <PTableLapTotalIdePolicyUser></PTableLapTotalIdePolicyUser>
            </TabPanel>
            <TabPanel header="Per Kriteria">
              <PTableLapTotalIdeKriteriaUser></PTableLapTotalIdeKriteriaUser>
            </TabPanel>
            <TabPanel header="Per Rupiah">
              <PTableLapTotalIdeRupiahUser></PTableLapTotalIdeRupiahUser>
            </TabPanel>
            <TabPanel header="Per Pengembangan QCC">
              <PTableLapTotalIdeQCC></PTableLapTotalIdeQCC>
            </TabPanel>
            <TabPanel header="Per Standarisasi">
              <PTableLapTotalIdeStandar></PTableLapTotalIdeStandar>
            </TabPanel>
            <TabPanel header="Per Dibatalkan">
              <PTableLapTotalIdeBatal></PTableLapTotalIdeBatal>
            </TabPanel>
          </TabView>
        </>
      ) : (
        <>
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            scrollable
          >
            <TabPanel header="Total">
              <PTableLapTotalIde></PTableLapTotalIde>
            </TabPanel>
            <TabPanel header="Per Kategori">
              <PTableLapTotalIdeCategory></PTableLapTotalIdeCategory>
            </TabPanel>
            <TabPanel header="Per Kebijakan">
              <PTableLapTotalIdePolicy></PTableLapTotalIdePolicy>
            </TabPanel>
            <TabPanel header="Per Kriteria">
              <PTableLapTotalIdeKriteria></PTableLapTotalIdeKriteria>
            </TabPanel>
            <TabPanel header="Per Rupiah">
              <PTableLapTotalIdeRupiah></PTableLapTotalIdeRupiah>
            </TabPanel>
            <TabPanel header="Per Pengembangan QCC">
              <PTableLapTotalIdeQCC></PTableLapTotalIdeQCC>
            </TabPanel>
            <TabPanel header="Per Standarisasi">
              <PTableLapTotalIdeStandar></PTableLapTotalIdeStandar>
            </TabPanel>
            <TabPanel header="Per Dibatalkan">
              <PTableLapTotalIdeBatal></PTableLapTotalIdeBatal>
            </TabPanel>
          </TabView>
        </>
      )}
    </div>
  );
};

export default LapTotalIde;
