import Api from "../api/api";

// const config = {
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${token}`,
//   },
// };

class NilaiServices {
  getAllNilai = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/nilai", config);
  };

  getNilaiById = (id) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.get("/nilai/getnilaiid/" + id, config);
  };

  getNilaiByUser = (nik, level) => {
    return Api.get("/nilai/getnilaiuser/" + nik + "/" + level);
  };

  createNilai = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.post("/nilai", data, config);
  };

  updateNilai = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.put("/nilai", data, config);
  };

  deleteNilai = (id) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.delete("/nilai/" + id, config);
  };

  rejectNilaiLvl2 = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.put("/nilai/rejectnilailvl2", data, config);
  };

  rejectNilaiLvl3 = (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Api.put("/nilai/rejectnilailvl3", data, config);
  };
}

export default new NilaiServices();
