import React from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const Maintenance = () => {
  return (
    <MainContainer>
      <img
        style={{ maxHeight: "50vw", width: "auto" }}
        src={require("../../assets/comingsoon.png")}
        alt="Maintenance"
      ></img>
    </MainContainer>
  );
};

export default Maintenance;
