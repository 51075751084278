import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SelectButton } from "primereact/selectbutton";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import PaymentServices from "../../services/PaymentServices";
import "./PTableIdea.css";
import styled from "styled-components";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

const Label = styled.label`
  font-size: 0.8rem;
  font-weight: 600;
  padding: 5px 0px;
`;

const ColContent = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 661px) {
    align-items: start;
    flex-direction: column;
  }
`;

const PTableLapPayRewardPGA = () => {
  const [ideas, setIdeas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [errors, setErrors] = useState(null);
  const dt = useRef(null);
  const [periode, setPeriode] = useState(new Date());
  const [selectedIde, setSelectedIde] = useState(null);
  const [selectedFactory, setSelectedFactory] = useState({
    name: "All",
    code: 0,
  });
  const [payProses, setPayProses] = useState("Buka");
  const options = ["Buka", "Tutup"];

  const toast = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (periode) {
      setPayProses("Buka");
      periode.setDate(1);
      async function getAllIdeas() {
        setLoading(true);
        try {
          const res = await PaymentServices.getPayIdeaPGA(
            periode.toDateString(),
            selectedFactory.code
          );
          setIdeas(res.data.data);
        } catch (err) {
          // console.log(err);
          setErrors(err);
        } finally {
          setLoading(false);
        }
      }
      getAllIdeas();
    }
  }, [periode, selectedFactory]);

  useEffect(() => {
    if (ideas.length > 0) {
      const payProses = ideas.filter(function (ideas) {
        return ideas.payProses === true;
      });
      if (payProses.length > 0) {
        setPayProses("Tutup");
      }
    }
  }, [ideas]);

  useEffect(() => {
    if (response) {
      if (response.data.status) {
        toast.current.show({
          severity: "success",
          summary: "Berhasil",
          detail: response.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Gagal",
          detail: response.data.message,
          life: 3000,
        });
      }
    }
  }, [response]);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        navigate("/login");
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  const rewardTemplate = (rowData) => {
    return <span>{rowData.rupiah.toLocaleString("en-US")}</span>;
  };

  const rewardFooter = () => {
    let total = 0;
    for (let item of ideas) {
      total += item.rupiah;
    }
    return <span>{total.toLocaleString("en-US")}</span>;
  };

  // const exportCSV = () => {
  //   dt.current.exportCSV();
  // };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      let dataExport = [];
      ideas.map((idea) => {
        const data = {
          NIK: idea.nik,
          Nama: idea.nama,
          Dept: idea.dept,
          Rupiah: idea.rupiah,
        };
        return dataExport.push(data);
      });
      const worksheet = xlsx.utils.json_to_sheet(dataExport);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Payment Reward");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
    setPayProses("Tutup");
    updatePayIdea(1);
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, ideas);
        doc.save("Payment Reward.pdf");
      });
    });
  };

  const cols = [
    { field: "nik", header: "NIK" },
    { field: "nama", header: "Nama" },
    { field: "dept", header: "Dept" },
    { field: "rupiah", header: "Rupiah" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const factories = [
    { name: "All", code: 0 },
    { name: "TNG", code: 1 },
    { name: "CKR", code: 3 },
  ];

  const onFactoryChange = (e) => {
    setSelectedFactory(e.value);
  };

  const onPayProsesChange = (e) => {
    if (e.value === "Tutup") {
      confirmDialog({
        message: "Apakah yakin ingin tutup payment reward?",
        header: "Konfirmasi tutup",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Yakin",
        rejectLabel: "Tidak",
        accept: () => {
          setPayProses(e.value);
          updatePayIdea(1);
        },
      });
    } else {
      confirmDialog({
        message: "Apakah yakin ingin buka kembali payment reward?",
        header: "Konfirmasi buka",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        acceptLabel: "Yakin",
        rejectLabel: "Tidak",
        accept: () => {
          setPayProses(e.value);
          updatePayIdea(0);
        },
      });
    }
  };

  const updatePayIdea = async (proses) => {
    try {
      const res = await PaymentServices.updatePayIdeaProses(
        periode.toDateString(),
        proses
      );
      setResponse(res);
    } catch (err) {
      console.log(err);
    } finally {
      // setLoading(false);
    }
  };

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={options.filterClearCallback}
        className="p-button-secondary"
      ></Button>
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={options.filterApplyCallback}
        className="p-button-success"
      ></Button>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "5px",
        }}
      >
        <ColContent>
          <Label>Periode</Label>
          <Calendar
            id="monthpicker"
            value={periode}
            onChange={(e) => setPeriode(e.value)}
            view="month"
            dateFormat="mm-yy"
            inputStyle={{
              fontSize: "0.8rem",
            }}
            style={{
              margin: "0px 5px",
            }}
          />
          {/* <Label>Factory</Label>
          <Dropdown
            style={{
              margin: "0px 5px",
            }}
            panelStyle={{ fontSize: "0.8rem" }}
            optionLabel="name"
            value={selectedFactory}
            options={factories}
            onChange={onFactoryChange}
            placeholder="Pilih factory"
          /> */}
          <Label>Payment Proses</Label>
          <SelectButton
            value={payProses}
            options={options}
            onChange={(e) => onPayProsesChange(e)}
            style={{ marginLeft: "5px" }}
          />
        </ColContent>
        <div style={{ display: "flex" }}>
          <Button
            style={{
              padding: "5px 15px",
              fontSize: "0.8rem",
              marginRight: "10px",
            }}
            type="button"
            icon="pi pi-file-excel"
            onClick={exportExcel}
            className="p-button-success mr-2"
            tooltip="XLS"
            tooltipOptions={{ position: "bottom" }}
          />
          <Button
            style={{
              padding: "5px 15px",
              fontSize: "0.8rem",
              marginRight: "10px",
            }}
            type="button"
            icon="pi pi-file-pdf"
            onClick={exportPdf}
            className="p-button-warning mr-2"
            tooltip="PDF"
            tooltipOptions={{ position: "bottom" }}
          />
        </div>
      </div>
      <DataTable
        value={ideas}
        scrollable
        loading={loading}
        scrollDirection="both"
        className="header-class"
        rowClassName="row-class-report"
        ref={dt}
        removableSort
        selectionMode="single"
        selection={selectedIde}
        onSelectionChange={(e) => setSelectedIde(e.value)}
        emptyMessage="Data tidak ditemukan"
      >
        <Column
          header="No"
          headerStyle={{ width: "3em" }}
          style={{ width: "3em" }}
          body={(data, options) => options.rowIndex + 1}
        ></Column>
        <Column
          field="nik"
          header="NIK"
          style={{ flexGrow: 1, flexBasis: "100px" }}
          sortable
          filter
          filterPlaceholder="Cari NIK"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="nama"
          header="Nama"
          style={{ flexGrow: 1, flexBasis: "150px" }}
          sortable
          filter
          filterPlaceholder="Cari nama"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="dept"
          header="Dept"
          style={{ flexGrow: 1, flexBasis: "100px" }}
          sortable
          filter
          filterPlaceholder="Cari dept"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="rupiah"
          header="Rupiah"
          body={rewardTemplate}
          style={{ flexGrow: 1, flexBasis: "80px" }}
          align="right"
          footer={rewardFooter}
          footerStyle={{ padding: "5px 7px" }}
        ></Column>
      </DataTable>
    </div>
  );
};

export default PTableLapPayRewardPGA;
