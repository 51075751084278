import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TDeleteButton, TEditButton } from "../element/GlobalStyle";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import UserServices from "../../services/UserServices";
import RoleServices from "../../services/RoleServices";
import "./PTableIdea.css";
import UserModal from "../modal/UserModal";
import ConfirmModal from "../modal/ConfirmModal";
import MessageModal from "../modal/MessageModal";
import jwt from "jwt-decode";

const menuCode = "user";

const PTableUser = ({ setIsInsert, isUserModal }) => {
  const token = localStorage.getItem("token");
  const user = jwt(token);
  const username = user.username;

  const [id, setId] = useState(null);
  // const [response, setResponse] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [msgModal, setMsgModal] = useState("");
  const [errors, setErrors] = useState(null);
  const [akses, setAkses] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getRoleAkses();
    getAllUsers();
  }, []);

  useEffect(() => {
    if (akses) {
      setIsInsert(akses.isInsert);
      setIsUpdate(akses.isUpdate);
      setIsDelete(akses.isDelete);
    }
  }, [akses]);

  // useEffect(() => {
  //   if (response) {
  //     setUsers(response.data);
  //   }
  // }, [response]);

  useEffect(() => {
    if (errors) {
      if (errors.response !== undefined && errors.response.status === 401) {
        navigate("/login", { replace: true });
      } else {
        navigate("/error");
      }
    }
  }, [errors, navigate]);

  const getRoleAkses = async () => {
    try {
      const res = await RoleServices.getUserRoleAkses(username, menuCode);
      setAkses(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      getAllUsers();
    }
  };

  const getAllUsers = async () => {
    setLoading(true);
    try {
      const res = await UserServices.getAllUsers();
      setUsers(res.data.data);
    } catch (err) {
      setErrors(err);
    } finally {
      setLoading(false);
    }
  };

  const pilihanTemplate = (rowData) => {
    return (
      <div>
        {isUpdate && (
          <TEditButton
            onClick={(e) => {
              setId(rowData.id);
              setUserModal(true);
            }}
          ></TEditButton>
        )}
        {isDelete && (
          <TDeleteButton
            onClick={(e) => {
              setId(rowData.id);
              setConfirmModal(true);
            }}
          ></TDeleteButton>
        )}
      </div>
    );
  };

  const aktifTemplate = (rowData) => {
    return (
      <div>
        <input
          style={{ width: "auto" }}
          type="checkbox"
          value={rowData.aktif}
          checked={rowData.aktif}
          readOnly
        />
      </div>
    );
  };

  const handleDelete = () => {
    deleteUser();
  };

  const deleteUser = async () => {
    setLoading(true);
    try {
      await UserServices.deleteUser(id);
    } catch (err) {
      setErrors(err);
    } finally {
      setLoading(false);
      setMsgModal(true);
    }
    getAllUsers();
  };

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={options.filterClearCallback}
        className="p-button-secondary"
      ></Button>
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={options.filterApplyCallback}
        className="p-button-success"
      ></Button>
    );
  };

  const siteFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={["TSC1", "TSC3", "TSC3A", "TSC3B"]}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder="Pilih site"
        panelStyle={{ fontSize: "0.8rem" }}
        showClear
      />
    );
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const dataExport = [];
      users.map((user) => {
        const data = {
          Username: user.username,
          Nama: user.nama,
          Dept: user.dept,
          Sect: user.sect,
          Site: user.site,
          Level: user.level,
          Status: user.status,
          Aktif: user.aktif
        };
        return dataExport.push(data);
      });
      const worksheet = xlsx.utils.json_to_sheet(dataExport);
      // const worksheet = xlsx.utils.json_to_sheet(ideaDiterima);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Data User");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <div>
      {confirmModal && (
        <ConfirmModal
          title="Konfirmasi"
          message="Apakah yakin ingin hapus data?"
          setConfirmModal={setConfirmModal}
          handleConfirm={() => handleDelete()}
        />
      )}
      {userModal && (
        <UserModal
          setUserModal={setUserModal}
          id={id}
          getAllUsers={getAllUsers}
        />
      )}
      {msgModal && (
        <MessageModal
          status={true}
          message="Berhasil dihapus"
          setShow={setMsgModal}
        ></MessageModal>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          // justifyContent: "space-between",
          justifyContent:"flex-end",
          marginBottom: "5px",
        }}
      >
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success mr-2"
          tooltip="XLS"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
      <DataTable
        value={users}
        scrollable
        loading={loading}
        className="header-class"
        rowClassName="row-class-report"
        removableSort
        paginator
        rows={10}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[10, 25, 50]}
        dataKey="id"
        rowHover
        filterDisplay="menu"
        responsiveLayout="scroll"
        emptyMessage="Data tidak ditemukan"
        currentPageReportTemplate="Tampil {first} ke {last} dari {totalRecords} data"
        scrollHeight="calc(100vh - 206px)"
      >
        <Column
          field="username"
          header="Username"
          style={{ minWidth: "120px", maxWidth: "140px" }}
          sortable
          filter
          filterPlaceholder="Cari username"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="nama"
          header="Nama"
          style={{ minWidth: "200px" }}
          sortable
          filter
          filterPlaceholder="Cari nama"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="dept"
          header="Dept"
          style={{ minWidth: "120px", maxWidth: "150px" }}
          sortable
          filter
          filterPlaceholder="Cari dept"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="sect"
          header="Section"
          style={{ minWidth: "120px", maxWidth: "150px" }}
          sortable
          filter
          filterPlaceholder="Cari section"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="site"
          header="Site"
          style={{ minWidth: "100px", maxWidth: "110px" }}
          sortable
          filter
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
          filterElement={siteFilterTemplate}
        ></Column>
        <Column
          field="level"
          header="Level"
          style={{ minWidth: "90px", maxWidth: "100px" }}
          sortable
          filter
          filterPlaceholder="Cari level"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="status"
          header="Status"
          style={{ minWidth: "100px", maxWidth: "110px" }}
          sortable
          filter
          filterPlaceholder="Cari status"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          field="aktif"
          header="Aktif"
          style={{ minWidth: "90px", maxWidth: "100px" }}
          body={aktifTemplate}
          sortable
          filter
          filterPlaceholder="Cari aktif"
          filterMatchMode="contains"
          showFilterOperator={false}
          showFilterMatchModes={false}
          showAddButton={false}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
        ></Column>
        <Column
          header="Pilihan"
          style={{ minWidth: "90px", maxWidth: "100px" }}
          body={pilihanTemplate}
        ></Column>
      </DataTable>
    </div>
  );
};

export default PTableUser;
